import { SITE_APP_LINK,MART_APP_LINK }  from './Constant.js';
import {isAndroid } from 'react-device-detect';
import {secureLocalStorage} from '../common/ImportUserEssentials';
const AppLinks = (props) => {
	let app_link = (props.type === 'fynmart') ? MART_APP_LINK : SITE_APP_LINK;
	const hideAppLinks = () => {
		secureLocalStorage.setItem('fo_applinks',1);
	}
	if( !secureLocalStorage.getItem('fo_applinks') && isAndroid && !navigator.userAgent.includes ('wv')){
		return(
			<div className="bs-toast toast toast-placement-ex m-1 fade bg-dark bottom-0 start-50 translate-middle-x show" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
				<div className="toast-header py-1">
					<i className="bi bi-android2 me-2 bx-md"></i>
					<div className="me-auto fw-semibold">For better experience, download app</div>
					<button onClick={hideAppLinks} type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
				</div>
				<div className="toast-body text-center pb-2"><a href={app_link} target="_blank"><img height="35" src="assets/img/GetItOnGooglePlay_Badge_Web_color_English.png"/></a></div>
			</div>
		)
	}
}

export default AppLinks;