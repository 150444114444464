import React,{useState,useEffect} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import '../styles/text-editor.css';
import '../styles/sale-settings.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-bootstrap/Modal';
import { QRCode } from 'react-qrcode-logo';
import styled from 'styled-components';
const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function SaleSettings(props){
	const qrref = React.useRef(null);
	const [declaration, setDeclaration]             = useState('');
	const [tnc, setTnc]                             = useState('');
	const [licence, setLicence]                     = useState('');
	const [currentRadio,setCurrentRadio]			= useState('billing');
    const [contact_name, setContactName]            = useState('');
    const [contact_details, setContactDetails]      = useState('');
    const [invoice_prefix, setInvoicePrefix] 	    = useState('');
	const [sample_invoice,setSampleInvoice]         = useState('');
    const [invoicePrefixData, setInvoicePrefixData] = useState('');
    const [invoiceCounterVal, setInvoiceCounterVal] = useState(1);
    const [invoiceCountData, setInvoiceCountData]   = useState('');
    const [address, setAddress]          	        = useState('');
    const [checked, setIschecked]                   = useState(true);
	const [upiSettingSaved,setUpiSettingSaved]      = useState(false);
	const [upiHandles,setUpiHandles]				= useState([]);
	const [upiPrefix,setUpiPrefix]					= useState('');
	const [upiHandle,setUpiHandle]					= useState([]);
	const [pgEnabled,setPgEnabled]					= useState('0');
    const mainTabTypes                              = ['Income Settings','Accounting Settings'];
	const [mainTab, setMainTab]                     = React.useState(mainTabTypes[0]);
    const [chart_of_account, setChartOfAccount]     = useState(true);
    const [exportStatus, setExportStatus]           = useState(true);
    const [alertModal, setAlertModal]               = useState(false);
    	
	const [invoiceRemarks_w,setinvoiceRemarks_w] = React.useState(true);
	const navigate = Common.useNavigate();
    const { state } = Common.useLocation();
	useEffect(() => {
		document.title = 'Settings | '+props.site_name;
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			setinvoiceRemarks_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}

        if (localStorage.getItem('fo_subplan') === 'Basic' && mainTab === 'Accounting Settings') {
            setAlertModal(true)
        }

        if(state === null){
            setCurrentRadio('billing');
        }else{
            setCurrentRadio('invPrefix');
        }
		const setPrefixInput = (val) => {
			if( val.length <= 4 ){
				Common.axios({
					method: 'get',
					url: props.url+'ajax/sampleInvoice/'+val,
				}).then(function (response) {
					if(response.data.success){
						setSampleInvoice(response.data.data);
						return false;
					}
				}).catch(error => console.log(error));
			}
			setSampleInvoice('');
		}
        Common.axios({
            method: 'get',
            url: props.url+'ajax/getUpiHandle',
        }).then(function(response){
            if(response.data.success){
                setUpiHandles(response.data.data);
                setUpiHandle(response.data.data[0].name);
            }
        }).catch(error => console.log(error));
		fetchUpiDetails();
        Common.axios({
            method: 'post',
            url: props.url+'user/profileDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
        }).then(function (response) {
            if(response.data.success){
                setDeclaration(response.data.data.declaration);
                setTnc(response.data.data.tnc);
                setLicence(response.data.data.licence);
                setInvoicePrefix(response.data.data.prefix);
                setInvoicePrefixData(response.data.data.prefix);
                setInvoiceCounterVal(response.data.data.invoice_counter_value === '0' ? 1:response.data.data.invoice_counter_value);
                setInvoiceCountData(response.data.data.invoice_counter_value);
                setContactName(response.data.data.contact_name !== null ? response.data.data.contact_name : '');
                setContactDetails(response.data.data.contact_billing_details !== null ? response.data.data.contact_billing_details : '');
                setAddress(response.data.data.addr);
                if(response.data.data.prefix === ''){
                    setPrefixInput(Common.acceptOnlyCharsNumbersNoSpace(response.data.data.name).substring(0,3));
                }else{
                    setPrefixInput(response.data.data.prefix);
                }
                if (response.data.data.enable_auth_sign === '0') {
                    setIschecked(false);
                } else {
                    setIschecked(true);
                }
                setChartOfAccount(true);
                if (response.data.data.chart_of_account_status === '0') {
                    setChartOfAccount(false);
                }
                setExportStatus(true);
                if (response.data.data.export_status === '0') {
                    setExportStatus(false);
                }
				return false;
            }
			Common.toast.error(response.data.message);
			setTimeout(() => {
				navigate("/profiles");
			}, 2000);
        }).catch(error => console.log(error));
	}, [props,state,navigate,mainTab]);
	if( !invoiceRemarks_w ){
		navigate("/not-authorized");
	}
	const fetchUpiDetails = () => {
		Common.axios({
            method: 'post',
            url: props.url+'user/upiDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
        }).then(function (response) {
			if(response.data.success){
				setUpiSettingSaved(true);
				let parts = response.data.data.upi.split('@');
				setUpiPrefix(parts[0]);
				setUpiHandle('@'+parts[1]);
				setPgEnabled(response.data.data.payment_gateway);
			}
		}).catch(error => console.log(error));
	}
    const handleBillingContactSubmit = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'user/profile_update',
            data: {id:localStorage.getItem('fo_profileid'),contact_name:contact_name,contact_details:contact_details,enable_auth_sign:checked,uid:localStorage.getItem('fo_loggedin'),address:address},
        }).then(function (response) {
			if(response.data.success){
                Common.toast.success(response.data.message);
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
	const handleUpiSubmit = (e) => {
		e.preventDefault();
		let data = {};
        data['pid'] = localStorage.getItem('fo_profileid');
        data['createdby'] = localStorage.getItem('fo_loggedin');
		data['upi'] = upiPrefix+upiHandle;
		const canvas = document.getElementById('react-qrcode-logo');
		data['upi_qr'] = canvas.toDataURL();
		if (upiPrefix === '') {
            Common.toast.error('UPI cannot be left blank.');
            return false;
		}
		Common.axios({
            method: 'post',
            url:props.url+'user/updateUpi',
            data: data,
        }).then(function (response) {
			if(response.data.success){
				fetchUpiDetails();
                Common.toast.success(response.data.message);
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const downloadQRCode = () => {
		qrref.current?.download('png',contact_name.replace(/[^a-zA-Z ]/g, "")+'_QR')
	}
    const handleInvPreSubmit = (e)=>{
        e.preventDefault();
        let data = {};
        data['id'] = localStorage.getItem('fo_profileid');
        data['uid'] = localStorage.getItem('fo_loggedin');
        if(!invoicePrefixData){
            data['invoice_prefix'] = invoice_prefix;
        }
        data['enable_auth_sign'] = checked;
        data['invoice_counter_value'] = invoiceCounterVal;
        if(invoiceCounterVal === '' || invoiceCounterVal === '0'){
            Common.toast.error('Invoice counter value cannot be blank or 0.');
            return false;
        } else if (invoice_prefix.length === 2 && invoiceCounterVal.length > 6) {
            Common.toast.error('Invoice length cannot be more than 16 character');
            return false;
        } else if (invoice_prefix.length === 3 && invoiceCounterVal.length > 5) {
            Common.toast.error('Invoice length cannot be more than 16 character');
            return false;
        } else if (invoice_prefix.length === 4 && invoiceCounterVal.length > 4) {
            Common.toast.error('Invoice length cannot be more than 16 character');
            return false;
        }
		Common.axios({
            method: 'post',
            url:props.url+'user/profile_update',
            data: data,
        }).then(function (response) {
			if(response.data.success){
                setInvoicePrefixData(invoice_prefix);
                setInvoiceCountData(invoiceCounterVal);
                Common.toast.success(response.data.message);
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }

    const handleTermsConSubmit = (e)=>{
        e.preventDefault();
        Common.axios({
            method: 'post',
            url:props.url+'user/profile_update',
            data: {
                id:localStorage.getItem('fo_profileid'),declaration:declaration,tnc:tnc,licence:licence,enable_auth_sign:checked,uid:localStorage.getItem('fo_loggedin')
            },
        }).then(function (response) {
			if(response.data.success){
                Common.toast.success(response.data.message);
				return false;
			}
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const  modules  = {
        toolbar: [
            // [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["italic", "underline", "strike"],
            // [{ color: [] }, { background: [] }],
            // [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            // ["clean"],
        ],
    };
    function TabGroup() {
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{mainTabTypes.map(type => (
				<Tab
					key={type}
					active={mainTab === type}
					onClick={() => handleMainTab(type)}
				>
					{type}
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
	const handleMainTab = penColChecked => {
        setMainTab(penColChecked);
	};
	const chartOfAccountStatus = (is_checked) => {
        setChartOfAccount(is_checked);
		Common.axios({
			method: 'post',
			url: props.url+'user/profile_update',
			data: {'id':localStorage.getItem('fo_profileid'),'chart_of_account':is_checked}
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}

    const handleExportStatus = (is_checked) => {
        setExportStatus(is_checked);
		Common.axios({
			method: 'post',
			url: props.url+'user/profile_update',
			data: {'id':localStorage.getItem('fo_profileid'),'export_status':is_checked}
		}).then(function (response) {
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}

    const handleRedirection = () => {
        navigate('/pricing-plan');
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="sale-settings" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
						<h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span> Settings</h4>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <TabGroup/>
                                </div>
                                {mainTab === 'Income Settings' && (<>
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className="row" id="saleSettingDiv">
                                                    <div className="col">
                                                        {(currentRadio === 'billing') && <>
                                                        <h5>Billing contact details <small className="text-muted fs-6">To be shown in Sale Invoice pdf</small></h5>
                                                        <form id="formBankAccount" autoComplete="off" onSubmit={handleBillingContactSubmit}>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-6">
                                                                    <label htmlFor="contact_name" className="form-label">Name</label>
                                                                    <input autoFocus type="text" className="form-control" id="contact_name" name="contact_name" placeholder="Name" maxLength="100" value={contact_name} onChange={(e) => setContactName(e.target.value)}/>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-6">
                                                                    <label htmlFor="contact_telephone" className="form-label">Contact Details <Common.OverlayTrigger overlay={Common.popoverContent('','Additional contact information like Mobile number, fax, email, website etc can be provided here to be displayed in Sale invoice pdf as part of Bill From details.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger></label>
                                                                    <ReactQuill
                                                                        className='contact_details'
                                                                        modules={modules}
                                                                        theme='snow'
                                                                        value = {contact_details}
                                                                        onChange={setContactDetails}
                                                                        style={{minHeight: '150px'}}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-6">
                                                                    <label htmlFor="business-address" className="form-label">Address for E-Invoice/Eway Bill (max 100 char)</label>
                                                                    <input type='text' id="business-address" name="address" maxLength="100" className="form-control" placeholder="Address" aria-label="Address" aria-describedby="basic-icon-default-message2" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                                    {/* <ReactQuill
                                                                        className='contact_details'
                                                                        modules={modules}
                                                                        theme='snow'
                                                                        value = {contact_details}
                                                                        onChange={setContactDetails}
                                                                        style={{minHeight: '150px',width: '45.6%'}}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-12">
                                                                    <label htmlFor="contact_telephone" className="form-label">Show Authorized Signatory</label><br></br>
                                                                    <BootstrapSwitchButton
                                                                        checked={checked}
                                                                        disabled='0'
                                                                        onlabel='Active'
                                                                        offlabel='Inactive'
                                                                        onstyle="success"
                                                                        offstyle="danger"
                                                                        size="sm"
                                                                        width={100}
                                                                        onChange={(checked: boolean) => {
                                                                            setIschecked(checked)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row ">
                                                                <div className="d-flex gap-2">
                                                                    <Button variant="primary" size="sm" type="submit"><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        </>}
                                                        {(currentRadio === 'invPrefix') && <>
                                                        <h5>Invoice Prefix</h5>
                                                        <form id="formBankAccount" autoComplete="off" onSubmit={handleInvPreSubmit}>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="invoice-prefix">Invoice Prefix</label>
                                                                    <input type="text" id="invoice-prefix" name="invoice_prefix" className="form-control text-uppercase" maxLength="4" placeholder="Prefix (2-4) chars" aria-label="Prefix" aria-describedby="Prefix" value={invoice_prefix} onChange={(e) => setInvoicePrefix(Common.acceptOnlyCharsNumbersNoSpace(e.target.value))} required disabled={invoicePrefixData?true:false}/>
                                                                    <div className="form-text">Sample Invoice: {sample_invoice}</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="invoice-counter-value">Invoice Counter Value</label>
                                                                    <input type="text" id="invoice-counter-value" name="invoice_counter_value" className="form-control text-uppercase"  placeholder="Invoice Counter Value" aria-label="Counter Value" aria-describedby="Counter Value" value={invoiceCounterVal} onChange={(e) => setInvoiceCounterVal(Common.acceptOnlyDecimal(e.target.value))} required disabled={invoiceCountData !== '0'?true:false}/>
                                                                </div>
                                                            </div>
                                                            {(invoicePrefixData === '' || invoiceCountData === '0') && <>
                                                            <div className="row ">
                                                                <div className="d-flex gap-2">
                                                                    <Button variant="primary" size="sm" type="submit"><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
                                                                </div>
                                                            </div>
                                                            </>}
                                                        </form>
                                                        </>}
                                                        {(currentRadio === 'termsConditions') && <>
                                                        <h5>Invoice Terms</h5>
                                                        <form id="formBankAccount" autoComplete="off" onSubmit={handleTermsConSubmit}>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label" htmlFor="business-declaration">Declaration <Common.OverlayTrigger overlay={Common.popoverContent('','Declaration will be added to the left side of the Authorised Signatory section.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger></label>
                                                                    <ReactQuill
                                                                        modules={modules}
                                                                        theme='snow'
                                                                        value={declaration}
                                                                        onChange={setDeclaration}
                                                                        style={{minHeight: '150px'}}
                                                                    />
                                                                </div>
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label" htmlFor="business-tnc">Terms and Conditions <Common.OverlayTrigger overlay={Common.popoverContent('','Terms and condition will be added at the end of the invoice.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger></label>
                                                                    <ReactQuill
                                                                        modules={modules}
                                                                        theme='snow'
                                                                        value={tnc}
                                                                        onChange={setTnc}
                                                                        style={{minHeight: '150px'}}
                                                                    />
                                                                </div>
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label" htmlFor="business-licence">Additional Licence <Common.OverlayTrigger overlay={Common.popoverContent('','Additonal licence will be added in Bill From (Seller) details.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger></label>
                                                                    <ReactQuill
                                                                        modules={modules}
                                                                        theme='snow'
                                                                        value={licence}
                                                                        onChange={setLicence}
                                                                        style={{minHeight: '150px'}}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row ">
                                                                <div className="d-flex gap-2">
                                                                    <Button variant="primary" size="sm" type="submit"><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        </>}
                                                        {(currentRadio === 'export') && <>
															<div className="row" id="saleSettingDiv">
																<div className="col">
																	<div className="row">
																		<div className="mb-3 col-md-12">
																		<h5>Export</h5>
																			<label>Enable Exports section in Billing form on Sale side</label><br></br>
																			<BootstrapSwitchButton
																				checked={exportStatus}
																				disabled='0'
																				onlabel='Active'
																				offlabel='Inactive'
																				onstyle="success"
																				offstyle="danger"
																				size="sm"
																				width={100}
																				onChange={(checked: boolean) => {
																					handleExportStatus(checked)
																				}}
																			/>
																		</div>
																	</div>
																</div>
															</div>
                                                        </>}
														{(currentRadio === 'upi') && <>
                                                        <h5>Payment Settings</h5>
														<div className="row">
															<div className="mb-3 col-md-12">
                                                                <label htmlFor="enable_pg" className="form-label">Payment Gateway <Common.OverlayTrigger overlay={Common.popoverContent('','Reach out FynOne Team for enablement.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger></label><br></br>
                                                                <BootstrapSwitchButton
                                                                    checked={pgEnabled === '1' ? true : false}
                                                                    disabled={true}
                                                                    onlabel='Enabled'
                                                                    offlabel='Disabled'
                                                                    onstyle="success"
                                                                    offstyle="danger"
                                                                    size="sm"
                                                                    width={100}
                                                                />
																<div className="mt-2"><small className="text-danger">(2% flat commission on every transaction. Supports UPI, Credit and Debit cards.)</small></div>
                                                            </div>
														</div>
                                                        <form id="formUpi" autoComplete="off" onSubmit={handleUpiSubmit}>
                                                            <div className="row">
																<div className="col-md-8">
																	<label className="form-label" htmlFor="upi-prefix">UPI</label>
																	<div className="input-group mb-3">
																		<input type="text" id="upi-prefix" name="upi_prefix" className="form-control" maxLength="50" placeholder="Enter UPI" aria-label="UPI" aria-describedby="UPI" value={upiPrefix} onChange={(e) => setUpiPrefix(Common.acceptUPI(e.target.value))} required disabled={upiSettingSaved?true:false}/>
																		<button type="button" className="btn btn-outline-secondary" disabled={upiSettingSaved?true:false}>{upiHandle}</button>
																		<button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" disabled={upiSettingSaved?true:false}>
																			<span className="visually-hidden">Toggle Dropdown</span>
																		</button>
																		<ul className="dropdown-menu">
																			{upiHandles.map((handle,index)=> {
																				return <li key={"upi_li_"+index}><button type="button" className="dropdown-item" onClick={()=>setUpiHandle(handle.name)}>{handle.name}</button></li>
																			})}
																		</ul>
																	</div>
																</div>
                                                            </div>
															{upiPrefix !== '' && <>
															<div className="row">
																<div className="text-center text-sm-start mb-2" style={{ background:"white",height: "auto", margin: "0 auto" }}>
																  <QRCode ref={qrref}
																	enableCORS={true}
																	size={256}
																	logoPadding={2}
																	removeQrCodeBehindLogo={true}
																	logoImage={props.fynone_logo}
																	value={"upi://pay?pa="+upiPrefix+upiHandle}
																	viewBox={`0 0 256 256`}
																  />
																</div>
															</div>
															</>}
                                                            <div className="row ">
                                                                <div className="d-flex gap-2">
																	{ (upiPrefix !== '') && <>
																		{upiSettingSaved && <>
																		<Button variant="info" size="sm" onClick={downloadQRCode} className="me-1"><i className='bx bx-download'></i> Download QR</Button>
																		</>}
																		{!upiSettingSaved && <>
																		<Button variant="primary" size="sm" type="submit"><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
																		</>}
																	</>}
                                                                </div>
                                                            </div>
                                                        </form>
                                                        </>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="list-group">
                                                            <label className="form-check-label list-group-item" htmlFor="billing_contact">
                                                                <input id="billing_contact" name="setting_type" className="form-check-input" type="radio" value="billing" checked={currentRadio === 'billing'} onChange={(e) => setCurrentRadio(e.target.value)} /> Billing contact details
                                                            </label>
                                                            <label className="form-check-label list-group-item" htmlFor="invoice_prefix">
                                                                <input id="invoice_prefix" name="setting_type" className="form-check-input" type="radio" value="invPrefix" checked={currentRadio === 'invPrefix'} onChange={(e) => setCurrentRadio(e.target.value)} /> Invoice Prefix
                                                            </label>
                                                            <label className="form-check-label list-group-item" htmlFor="terms_conditions">
                                                                <input id="terms_conditions" name="setting_type" className="form-check-input" type="radio" value="termsConditions" checked={currentRadio === 'termsConditions'} onChange={(e) => setCurrentRadio(e.target.value)} /> Invoice Terms
                                                            </label>
                                                            <label className="form-check-label list-group-item" htmlFor="export">
                                                                <input id="export" name="setting_type" className="form-check-input" type="radio" value="export" checked={currentRadio === 'export'} onChange={(e) => setCurrentRadio(e.target.value)} /> Export
                                                            </label>
															<label className="form-check-label list-group-item" htmlFor="upi">
                                                                <input id="upi" name="setting_type" className="form-check-input" type="radio" value="upi" checked={currentRadio === 'upi'} onChange={(e) => setCurrentRadio(e.target.value)} /> Payment Link
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                                {mainTab === 'Accounting Settings' && (<>
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <div className="row" id="saleSettingDiv">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="mb-3 col-md-12">
                                                                <label htmlFor="contact_telephone" className="form-label">Automatically Post to Chart of Accounts <Common.OverlayTrigger overlay={Common.popoverContent('','Unposted transactions will be posted to Journal every one minute.')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger></label><br></br>
                                                                <BootstrapSwitchButton
                                                                    checked={chart_of_account}
                                                                    disabled='0'
                                                                    onlabel='Active'
                                                                    offlabel='Inactive'
                                                                    onstyle="success"
                                                                    offstyle="danger"
                                                                    size="sm"
                                                                    width={100}
                                                                    onChange={(checked: boolean) => {
                                                                        chartOfAccountStatus(checked)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        <Modal show={alertModal} size='sm' onHide={()=>setAlertModal(false)} aria-labelledby="alert-modal" backdrop="static" keyboard={false} centered>
			<Modal.Body>
				<div className="my-1">
					{/* Select options below to add more details to your report */}
					{/* This plan supports only single Owner. For unlimited users, please upgrade the subscription plan */}
					This plan supports only {localStorage.getItem('fo_subplan')} features. For access to more modules, please upgrade the subscription plan
					<div className="my-3">
						<div className="row justify-content-md-center">
							<div className="offset-sm-1 col-sm-6">
								<button type='button' className='btn btn-primary btn-sm' onClick={handleRedirection}>Pricing Plan</button>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
        </>
    );
}