export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const MART_URL = process.env.REACT_APP_MART_URL;
export const BASE_LINK = process.env.REACT_APP_BASE_LINK
export const MART_LINK = process.env.REACT_APP_MART_LINK
export const SITE_NAME = "FYN ONE";
export const MART_NAME = "FYN MART";
export const FYNONE_LOGO = 'assets/img/logo.png';
export const SITE_LOGO = 'assets/img/fynone.png';
export const MART_LOGO = 'assets/img/mart_logo.png';
export const PRICING_LOGO = 'assets/img/pricing_plan.png';
export const MART_MERCHANT_LOGO = 'assets/img/fyn_mart_merchant.png';
export const SITE_KEY = 'fYn0ne@mr23';
export const RECAPTCHA_KEY = '6LcSwggpAAAAAF2kxZ214Qit3fl7iszSX7_4zXQk';
export const RECAPTCHA_SECRET = '6LcSwggpAAAAAHRvAPU83b4mF8C1TTqXeepGiLwb';
export const SITE_APP_LINK = 'https://play.google.com/store/apps/details?id=com.fynamics.fynone'
export const MART_APP_LINK = 'https://play.google.com/store/apps/details?id=com.fynamics.fynmart'