import React  from "react";
import Layout from './PartnerLayout';
import NavBar from './PartnerNavBar';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
export default function PartnerClients(props){
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [sortPartner,setSortPartner] = React.useState('');
	const [filterText, setFilterText] = React.useState('');
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [partnerClient, setPartnerClient]    = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [activeProfile, setActiveProfile] = React.useState(0);
	const [expiredProfile, setExpiredProfile] = React.useState(0);
	const [trialProfile, setTrialProfile] = React.useState(0);
    React.useEffect(() => {
        document.title = 'Partner Clients | '+props.site_name;
        loadDataTable(1,perPage,filterText,sortPartner);
	}, [props,perPage,dt_load,filterText]);

	const loadDataTable = (page,perPage,filterText,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'partner-client/listingPartnerClient/',
			data: {'page':page,'perPage':perPage,'sort':sort,'search':filterText,'account_type':localStorage.getItem('fo_partnerType')}
        }).then(function (response) {
            if(response.data.success){
				setPartnerClient(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
                setActiveProfile(response.data.activeCount);
                setExpiredProfile(response.data.expiredCount);
                setTrialProfile(response.data.trialCount);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,sortPartner);
	};
	const handlePerRowsChange = async (newPerPage) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortPartner(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const columns = [        
        {
            name: 'Partner Name',
            selector: row => row.partner_name,
            sortable: true,
			sortField: 'partner_name'
        },
        {
            name: 'District',
            selector: row => row.district,
            sortable: true,
			sortField: 'district'
        },
        {
            name: 'State',
            selector: row => row.state_name,
            sortable: true,
			sortField: 'state_name'
        },
        {
            name: 'Pincode',
            selector: row => row.pincode,
            sortable: true,
			sortField: 'pincode'
        },
        {
            name: 'Plan type',
            selector: row => row.sub_plan,
            sortable: true,
			sortField: 'sub_plan',
            cell: (row) => (
				(row.sub_plan)?row.sub_plan.toUpperCase():''
			)
        },
        {
            name: 'Expiry date',
            selector: row => row.sub_validity,
            sortable: true,
			sortField: 'sub_validity'
        },
        {
            name: 'Status',
            selector: row => row.plan_status,
            sortable: false,
			sortField: 'plan_status',
            conditionalCellStyles : [
				{
					when: row => row.plan_status === 'Expired',
					style: {
						backgroundColor: 'inherit',
						color: 'red'
					},
				},
				{
					when: row => row.plan_status === 'Active',
                    style: {
						backgroundColor: 'inherit',
						color: 'green'
					},
				},
				{
					when: row => row.plan_status === 'Trial',
                    style: {
						backgroundColor: 'inherit',
						color: 'orange'
					},
				},
			]
        },
        {
            name: 'Product',
            selector: row => row.sub_plan,
            sortable: false,
			sortField: 'sub_plan',
            cell: (row) => (
				(row.sub_plan === 'Free')?'FynMart':'FynOne'
			)
        },
        {
            name: 'Amount',
            selector: row => row.annual_price,
            sortable: true,
			sortField: 'annual_price'
        },
        {
            name: 'Credit balance',
            selector: row => row.wallet,
            sortable: true,
			sortField: 'wallet'
        },
	];
    return (
	<>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="partner-clients" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Partner Clients</h4>
							<div className="row">
                                <div className="col-md-2 col-6 mb-1 text-center text-md-start">
                                    Total Profile: {totalDataCnt}</div>
								<div className="col-md-2 col-6 text-center mb-1" >
                                    Active Profile: {activeProfile}
								</div>
								<div className="col-md-2 col-6 text-center mb-1" >
                                    Trial Profile: {trialProfile}
								</div>
                                <div className="col-md-2 col-6 text-center mb-1  text-danger" >
                                    Expired Profile: {expiredProfile}
								</div>
								<div className="col-md-4 col-12 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','District, State')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <DTE.DataTable className="dataTables_wrapper rdt_AdjDrp" columns={columns} data={partnerClient} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load} persistTableHead/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.PartnerNeedHelp url={props.url}/>
                </div>
            </div>
        </div>
	</>
    );
}