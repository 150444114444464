import React,{useState} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import { Typeahead,AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import '../styles/sales.css';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import * as DTE from '../common/ImportDataTableEssentials';

export default function PoContract(props){
	const [form_po,setFormPo] = useState();
	const [state_master, setStateMaster] = useState([]);
	const [cid, setCid]        = useState('');
	const [update_cust,setUpdateCust] = useState(0);
	const [cust_name, setCustName]        = useState('');
	const [cust_phone, setCustPhone]      = useState('');
	const [cust_phone_disabled, setCustPhoneDisabled]      = useState(false);
	const [custAddiDiv,setCustAddiDiv]    = useState(false);
	const [cust_gstin,setCustGstin]    = useState('');
	const [cust_legal_name,setCustLegalName]   = useState('');
	const [cust_trade_name,setCustTradeName]   = useState('');
	const [cust_pincode, setCustPincode]       = useState('');
	const [cust_addr,setCustAddr]              = useState('');
	const [cust_district,setCustDistrict]      = useState('');
	const [cust_state,setCustState]            = useState([]);
	const [cust_email, setCustEmail] = useState('');
	const [set_duplicate, setDuplicateModal] = useState(false);
	const handleViewClose = () => setDuplicateModal(false);
	const [incomeColl_w,setincomeColl_w] = React.useState(true);
	const [isCustLoading, setIsCustLoading] = useState(false);
	const [custListModalShow, setCustListModalShow] = useState(false);
	const [filterText, setFilterText] = React.useState('');
	const [customers_list_modal, setCustomersListModal] = useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [DTpending, setDTPending] = React.useState(true);
	const navigate = Common.useNavigate();
	const [customers, setCustomers] = useState([]);
	
	const [po_no,setPoNo] = useState('');
	const [po_amt,setPoAmt] = useState('');
	const [po_date, setPoDate] = useState(new Date());
	const [po_end_date, setPoEndDate] = useState();
	const [po_min_end_date, setPoMinEndDate] = useState();
	const [custSel,setCustSel] = useState([]);
	const [poDocument,setPoDocument] = useState([]);
	const paginationComponentOptions = {
		noRowsPerPage: true
	};
				
	// Bypass client-side filtering by returning `true`. Results are already
	const filterBy = () => true;

	React.useEffect(() => {
		document.title = 'Add PO/Contract | '+props.site_name;
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}

		// Common.axios({
		// 	method: 'post',
		// 	url:props.url+'user/profileDetails',
		// 	data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
		// }).then(function (response) {
		// 	if(response.data.success){
		// 		setProfileDetails(response.data.data);
		// 		return false;
		// 	}
		// 	Common.toast.error(response.data.message);
		// }).catch(error => console.log(error));
		setFormPo();

		// State Master
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));
		if(po_date){
			setPoMinEndDate(new Date(new Date(po_date).setDate(po_date.getDate() + 1)));
		}
	}, [props]);
	if( !incomeColl_w ){
		navigate("/po-contract");
	}
	const customerListModal = (param) => {
		if( param === 'show' ){
			setCustListModalShow(true);
			loadCustTableModal(1,filterText);
		}
		if( param === 'hide' ){
			setCustListModalShow(false );
			setCustomersListModal([]);
		}
	}
	const handlePageChange = page => {
		loadCustTableModal(page,filterText);
	};
	const handleSort = (column, sortDirection) => {
		loadCustTableModal(1,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const loadCustTableModal = (page,filterText,sort='') => {
		Common.axios({
			method: 'post',
			url: props.url+'customer/listingInSale/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'sort':sort,'search':filterText}
		}).then(function (response) {
			if(response.data.success){
				setCustomersListModal(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const columns = [
        {
            name: 'Party Name',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name',
			cell: (row) => (
				<Button variant="" className="text-break p-0 text-decoration-underline" onClick={e=>{setCustomerDetails([row]);setCustListModalShow(false);}}>{row.party_name.substring(0,15)}</Button>
			)
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
			sortField: 'mobile'
        },
        {
            name: 'Gstin',
            selector: row => row.gstin,
            sortable: true,
			sortField: 'gstin'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
			sortField: 'email'
        }
    ];

	const populatePinData = (param,pincode) => {
		switch(param) {
			case 'customer':
				setCustPincode(pincode);
				if( pincode.length=== 6 ){
					Common.axios({
						method: 'post',
						url:props.url+'ajax/getDataByPin',
						data: {pincode:pincode},
					}).then(function (response) {
						if(response.data.success){
							if( response !== undefined && response.data.stateid !== undefined && response.data.stateid !== '' ){
								setCustState([{'value':response.data.stateid,'label':'','digits':''}]);
							}
							setCustDistrict(response.data.district);
							return false;
						}
						Common.toast.dismiss();
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
				break;
			default:
				break;
		}
	}
	const setCustomerDetails = (e) => {
		if(e[0] === undefined) {
			setCustSel([]);
			setCustName('');
			setCustPhone('');
			setCustPhoneDisabled(false);
			setCid('');
			setCustGstin('');
			setCustLegalName('');
			setCustTradeName('');
			setCustPincode('');
			setCustAddr('');
			setCustDistrict('');
			setCustState([]);
			setCustEmail('');
			return;
		}
		setCustSel(e);
		setCustName((e[0]) ? e[0].party_name : '');
		let phone = (e[0].mobile) ? e[0].mobile : '';
		setCustPhone(phone);
		setCid(e[0].id.startsWith('new') ? '':e[0].id);
		let gstin = (e[0]) ? e[0].gstin : '';
		if( gstin !== undefined ){
			setCustGstin(gstin);
		}
		setCustLegalName(e[0]?.legal_name);
		setCustTradeName(e[0]?.trade_name);
		setCustPincode(e[0]?.pincode);
		setCustAddr(e[0]?.addr);
		setCustDistrict(e[0]?.district);
		setCustEmail(e[0]?.email);
		let custState = e[0]?.state;
		if( custState !== undefined && custState !== '' ){
			setCustState([{'value':custState,'label':'','digits':''}]);
		}
		setCustPhoneDisabled(true);
	}
	const allowDuplicate = () => {
		handleSubmit(form_po,1);
		setDuplicateModal(false);
	}
	const handleSubmit =(e,adup) => {
		e.preventDefault();
		Common.showLoader(true);
		let errors = [];
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('cid', cid);
		formData.append("file", poDocument);
		formData.append("pid", localStorage.getItem('fo_profileid'));
		formData.append('cust_name', cust_name);
		formData.append('cust_state', ((cust_state[0]) ? cust_state[0].value : ''));
		formData.append('update_cust', update_cust);
		formData.append('allow_duplicate', adup);
		
		let data = {};

		// convert the key/value pairs
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}

		if( data.cust_name === '' ){
			errors.push('Customer name cannot be blank.');
		}
		if( (data.cust_gstin !== '' && data.cust_gstin !== undefined) && !Common.validGstin(data.cust_gstin)){
			errors.push('Customer Details: GSTIN is invalid.');
		}
		if( data.po_no === '' ){
			errors.push('PO/Contract Details: PO Number cannot be blank.');
		}
		if( data.po_amt === '' ){
			errors.push('PO/Contract Details: PO Amount cannot be blank.');
		}
		// if( data.po_date === '' ){
		// 	errors.push('PO/Contract Details: PO Date cannot be blank.');
		// }
		// if( data.po_end_date === '' ){
		// 	errors.push('PO/Contract Details: PO End Date cannot be blank.');
		// }
		if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.dismiss();
			Common.toast.error(err_html);
			return false;
		}
		Common.axios.post(props.url+'po-contract/add', data, {
            mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
        }).then(function (response) {
            if(response.data.success){
                navigate("/po-contract");
                Common.showLoader(false);
                return false;
            }
        	Common.toast.dismiss();
        	Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
		/*Common.axios({
			method: 'post',
			url: props.url+'po-contract/add',
			data: data,
			mode: 'cors',
            headers: { 'Content-Type':  'multipart/form-data' }
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success === 1){
				navigate("/po-contract");
				return false;
			}
			if(response.data.success === 2){
				setDuplicateModal(true);
				setFormPo(e);
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));*/
	}
	const customerSearch = (query: string) => {
		setIsCustLoading(true);

		fetch(props.url+`customer/search/`+localStorage.getItem('fo_profileid')+`/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setCustomers(items);
			setIsCustLoading(false);
		});
	};
	
	const showCustAdditional = () => {
		setCustAddiDiv( (custAddiDiv) ? false : true );
	}
	const handleGstinData =(e) => {
		let value = e.target.value
		if (value.length === 15) {
			Common.showLoader(true);			
			let url = props.url+'ajax/fetch_gstin_details';
			Common.axios({
				method: 'post',
				url:url,
				data: {'gstin':value},
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.status){
					if(response.data.data.status_cd === "0"){
						Common.toast.dismiss();
						Common.toast.error(response.data.data.error.message);
						return false;
					}
					
					setCustLegalName(response.data.data.lgnm);
					if( response.data.data.tradeNam !== undefined ){
						setCustTradeName(response.data.data.tradeNam);
					}
					if( response.data.data.pradr !== undefined ){
						if( response.data.data.pradr.addr.pncd !== undefined ){
							setCustPincode(response.data.data.pradr.addr.pncd);
							populatePinData(response.data.data.pradr.addr.pncd);
						}
						let tempaddr = '';
						if( response.data.data.pradr.addr.bno !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ' ';}
							tempaddr += response.data.data.pradr.addr.bno;
						}
						if( response.data.data.pradr.addr.flno !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ' ';}
							tempaddr += response.data.data.pradr.addr.flno;
						}
						if( response.data.data.pradr.addr.bnm !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ' ';}
							tempaddr += response.data.data.pradr.addr.bnm;
						}
						if( response.data.data.pradr.addr.st !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ', ';}
							tempaddr += response.data.data.pradr.addr.st;
						}
						if( response.data.data.pradr.addr.loc !== undefined ){
							if( tempaddr !== '' ){ tempaddr += ', ';}
							tempaddr += response.data.data.pradr.addr.loc;
						}
						setCustAddr(tempaddr);
					}
					return false;
				}
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}
	const getPoMinEndDate = (e) => {
		if(e){
			setPoMinEndDate(new Date(new Date(e).setDate(e.getDate() + 1)));
		}else{
			setPoMinEndDate('');
		}
	}
	const handleDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
		if (e.target.files[0] !== undefined) {
			if (!fileTypes.includes(e.target.files[0].type)) {
				Common.toast.error('File format not supported');
				e.target.value = null;
				return false;
			} else {
				if (e.target.files[0].size > (5 * 1048576)) {
					Common.toast.error('File size should be less than 5MB');
					e.target.value = null;
					return false;
				} else {
					setPoDocument(e.target.files[0]);
				}
			}
		}
        Common.toast.dismiss();
    };
    return(
        <>
		<Modal show={set_duplicate} onHide={handleViewClose}>
			<Modal.Header closeButton>
				<Modal.Title>Duplicate PO/Contract</Modal.Title>
			</Modal.Header>
			<Modal.Body>PO is created with same PO number and active. Do you wish to continue?</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={allowDuplicate}>
					Continue
				</Button>
			</Modal.Footer>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="po-contract" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span>Add PO/Contract
								<span className="dropdown">
									<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
									<i className='text-danger bx bxl-youtube bx-md'></i>
									</button>
									<div className="dropdown-menu" >
										<a href="https://youtu.be/T4D9mCBEa8Q" className="dropdown-item" target="_blank" rel="noreferrer"><span>Add customer PO</span></a>
									</div>
								</span>
							</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
											<form onSubmit={(e)=>handleSubmit(e,0)} autoComplete="off">
												<div className="row" id="saledAddDiv">
													<div className="col">
														<div className="accordion" id="salesAccordion">
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingOne">
																	<button type="button" className="accordion-button" data-bs-target="#accordionCustomer" aria-expanded="false" aria-controls="accordionCustomer" disabled>
																		Customer Details
																	</button>
																</h2>
																<div id="accordionCustomer" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row mb-2">
																			<Button className="text-end" variant="" size="sm" onClick={()=>customerListModal('show')}><Common.OverlayTrigger overlay={Common.popoverContent('Customer List','Click to search Customer by Name, GSTIN, Phone, Email.')}><i className='bx bxs-right-top-arrow-circle'></i></Common.OverlayTrigger></Button>
																			<div className="col-md-6 col-sm-6">
																				<div className="form-floating">
																					<AsyncTypeahead selected={custSel} clearButton allowNew newSelectionPrefix="Add: " filterBy={filterBy} id="sale-customer" className="floatingTypeahead" isLoading={isCustLoading} labelKey="party_name" minLength={3} onSearch={customerSearch} options={customers} placeholder="Search (min 3 chars)" onChange={(e) => setCustomerDetails(e)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.party_name}
																								</Highlighter>
																								<div>
																									<small>{(option.gstin !== 'URP' && option.gstin !== '') && <>Gstin: {option.gstin}, </>}
																									Mobile: {option.mobile}
																									<span className="d-block text-truncate" style={{"maxWidth":"250px"}}>Addr:  {option.addr}</span>
																									</small>
																								</div>
																							</div>
																						)}
																					/>
																					<label htmlFor="frmPOCName">Customer Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6">
																				<div className="form-floating">
																					<input name="cust_phone" type="text" maxLength="10" className="form-control" id="frmPOCPhone" placeholder="Enter Phone" value={cust_phone} onChange={(e) => setCustPhone(Common.acceptOnlyNumbers(e.target.value))} disabled={cust_phone_disabled}/>
																					{cust_phone_disabled && <span id="custPhoneEdit"
																						onClick = {function(e){
																							setCustPhoneDisabled(false);
																							setUpdateCust(1);
																						}}><Common.OverlayTrigger overlay={Common.popoverContent('Click to Edit Phone','On submit, phone number will be updated for the customer.')}><i className="bx bx-edit"></i></Common.OverlayTrigger></span>}
																					<label htmlFor="frmPOCPhone">Phone</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (custAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showCustAdditional}>{custAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (custAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{custAddiDiv && <>
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_gstin" type="text" className="form-control" id="frmPOCGstin" placeholder="Enter Gstin" value={cust_gstin} maxLength="15" onChange={(e) => {handleGstinData(e);setCustGstin(Common.acceptOnlyCharsNumbers(e.target.value.toUpperCase()))}}/>
																					<label htmlFor="frmPOCGstin">Gstin</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_legal_name" type="text" className="form-control" id="frmPOCPhone" placeholder="Enter Legal Name" value={cust_legal_name} maxLength="100" onChange={(e) => setCustLegalName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPOCPhone">Legal Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_trade_name" type="text" className="form-control" id="frmPOCPhone" placeholder="Enter Trade Name" value={cust_trade_name} maxLength="100" onChange={(e) => setCustTradeName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPOCPhone">Trade Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_pincode" type="text" className="form-control" id="frmPOCPincode" placeholder="Enter Pin Code" value={cust_pincode} maxLength="6" onChange={(e) => populatePinData('customer',Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmPOCPincode">Pin Code</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_addr" type="text" className="form-control" id="frmPOCAddr" placeholder="Enter Address" value={cust_addr} maxLength="100" onChange={(e) => setCustAddr(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmPOCAddr">Address</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_district" type="text" className="form-control" id="frmPOCDist" placeholder="Enter District" value={cust_district} maxLength="100" onChange={(e) => setCustDistrict(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPOCDist">District</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmPOCState" labelKey="label" key="value" className="floatingTypeahead" placeholder="Select State"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={cust_state.length > 0 ? [state_master.find(obj=>{return obj.value === cust_state[0].value})] : []}
																						onChange={(e)=>{setCustState(e)}}
																					/>
																					<label htmlFor="frmPOCState">State</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="cust_email" type="text" className="form-control" id="frmPOCEmail" placeholder="Enter Customer Email" value={cust_email} maxLength="100" onChange={(e) => setCustEmail(e.target.value)}/>
																					<label htmlFor="frmPOCEmail">Email</label>
																				</div>
																			</div>
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionPO" aria-expanded="false" aria-controls="accordionPO" disabled>
																		PO/Contract details
																	</button>
																</h2>
																<div id="accordionPO" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="po_no" type="text" className="form-control" id="frmPONo" placeholder="Enter PO Number" value={po_no} onChange={(e) => setPoNo(e.target.value)}/>
																					<label htmlFor="frmPONo">PO Number<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="po_amt" type="text" className="form-control" id="frmPOAmt" placeholder="Enter PO Amout" value={po_amt} onChange={(e) => setPoAmt(Common.acceptOnlyDecimal(e.target.value))}/>
																					<label htmlFor="frmPOAmt">PO Amount<span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="po_date" data-testid="frmPODate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={(e)=>{setPoDate(e);getPoMinEndDate(e);}} value={po_date} />
																					<label htmlFor="frmPODate">PO Date</label>
																				</div>
																			</div>
																			<div className="col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="po_end_date" data-testid="frmPOEndDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" minDate={(po_min_end_date)?new Date(po_min_end_date):''} className="form-control" onChange={setPoEndDate} value={po_end_date} />
																					<label htmlFor="frmPOEndDate">PO End Date</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-sm-6 mb-3">
																					<label htmlFor="formFile" className="form-label" >PO/Contract Upload</label>
                                                        							<input type="file" className="form-control mb-2" id="poFile" name="poFile" onChange={handleDocument} />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row mt-5">
													<div className="text-center">
														<a href="/po-contract" role="button" className="me-2 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														<button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
													</div>
												</div>
											</form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
        <Modal show={custListModalShow} size="lg" onHide={()=>{setCustListModalShow(false);setFilterText('')}} aria-labelledby="customer-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="text-end mobile-view">
					<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Email')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
					<DTE.FilterComponent placeholder="Search" onFilter={e => {setFilterText(e.target.value);loadCustTableModal(1,e.target.value);}} onClear={e=>{setFilterText('');loadCustTableModal(1,'');}} filterText={filterText} />
					</div>
				</div>
				<div className="row">
					{customers_list_modal !== undefined && customers_list_modal.length === 0 && <div className="text-center py-1">No Customer</div>}
					{customers_list_modal !== undefined && customers_list_modal.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<DTE.DataTable className="rdt_AdjDrp" title={"Customer ("+totalDataCnt+")"} columns={columns} data={customers_list_modal} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} paginationComponentOptions={paginationComponentOptions} onChangePage={handlePageChange} onSort={handleSort} sortServer/>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
        </>
      );
}