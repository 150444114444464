import * as myConstClass  from './common/Constant.js';
import NoInternetConnection from './common/NoInternetConnection'
//import Localstorage from './mart/Localstorage';
import Login from './mart/Login';
import SignUp from './mart/SignUp';
import VerifyMobile from './mart/VerifyMobile';
import ForgotPassword from './mart/ForgotPassword';
import ResetPassword from './mart/ResetPassword';
import PageNotFound from './mart/PageNotFound';
import Home from './mart/Home';
import Profile from './mart/Profile';
import Approve from './mart/Approve';
import Store from './mart/Store';
import Orders from './mart/Orders';
import Landing from './mart/Landing';
import React, { Component } from "react";
import PostLogin from './mart/PostLogin';
import {BrowserRouter  as Router,Routes,Route  } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

class Mart extends Component { 
  render() {
    return (
        <div className="App">
			<NoInternetConnection>
            <Router>
              <Routes>				
				{/*<Route path="/localstorage" exact  element={<Localstorage recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.SITE_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} />} />*/}
                <Route path="/register" exact  element={<SignUp recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} />} />
				<Route path="/verify-mobile" exact  element={<VerifyMobile site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} />} />
                <Route path="/forgot_password" exact  element={<ForgotPassword site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />
                <Route path="/reset/:hash" element={<ResetPassword site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />} />                
				<Route path="/post-login" element={<PostLogin url={myConstClass.BASE_URL} />} />
		        <Route path="/login" exact element={<Login recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} />} />
		        <Route path="/home" exact element={<Home site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} link={myConstClass.MART_LINK} />} />
		        <Route path="/profile" exact element={<Profile site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} />} />
		        <Route path="/approve/:hash" exact element={<Approve site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} link={myConstClass.MART_LINK} />}/>
		        <Route path="/store/:hash" exact element={<Store recaptcha_key={myConstClass.RECAPTCHA_KEY} site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} link={myConstClass.MART_LINK}/>} />
		        <Route path="/orders/" exact element={<Orders site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} link={myConstClass.MART_LINK}/>} />
		        <Route path="/" exact element={<Landing site_key = {myConstClass.SITE_KEY} site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.MART_NAME} url={myConstClass.MART_URL} link={myConstClass.BASE_LINK} />} />
				<Route path="*" element={<PageNotFound site_logo = {myConstClass.MART_LOGO} site_name={myConstClass.SITE_NAME} url={myConstClass.BASE_URL} />}/>
              </Routes>
            </Router>
			<Toaster />
			</NoInternetConnection>			
        </div>
    );
  }
}

export default Mart;