import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { confirmAlert } from 'react-confirm-alert';
import {isMobile} from 'react-device-detect';
export default function Orders(props){
	const [DTpending, setDTPending] = React.useState(true);
	const [dt_load,setDtLoad] = React.useState(true);
	const [sortOrder,setSortOrder] = React.useState('');
	const [filterText, setFilterText] = React.useState('');
	
    //const orderTypes = ['Pending','Confirmed', 'Completed', 'Cancelled'];
    //const [orderType, setOrderType] = React.useState(orderTypes[0]);
	const [orders, setOrdersList]    = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [viewUPIModal, setViewUPIModal] = React.useState(false);
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
	const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	const setDatePicker = (start, end) => {
		setDates({ start, end });
		setDtLoad(!dt_load);
	}

	const [showCart, setShowCart] = React.useState(false);
	const [invoiceDetails,setInvoiceDetails] = React.useState('');
	const [itemDetails,setItemDetails] = React.useState([]);
    React.useEffect(() => {
        document.title = 'Orders | '+props.site_name;
	}, [props]);
	React.useEffect(() => {
		loadDataTable(1,perPage,filterText,dates,sortOrder);
	}, [dt_load,perPage,filterText,/*orderType*/]);
	const loadDataTable = (page,perPage,filterText,dates,sort='') => {
		Common.axios({
            method: 'post',
            url:props.url+'martuser/orders',
			data: {'page':page,'perPage':perPage,'sort':sort,'search':filterText,'dates':dates,/*'order_type':orderType*/}
        }).then(function (response) {
            if(response.data.success){
				setOrdersList(response.data.data);
				setTotalRows(response.data.total);
				setDTPending(false);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection));
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
	};
	const viewOrders = (id) => {
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'martorders/orderDetails',
			data: {sid:id}
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setInvoiceDetails(response.data.data);
				setItemDetails(response.data.itemData);
				setShowCart(true);
				return false;
			}
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
	const cancelOrders = (id) => {
		confirmAlert({
            title: 'Cancel Order',
            message: 'Are you sure you want to cancel this order?',
			closeOnClickOutside: false,
            buttons: [{
                label: 'Yes',
                onClick: () => {
                    Common.showLoader(true);
					Common.axios({
						method: 'post',
						url:props.url+'sale_orders/cancelSaleOrders',
						data: {saleOrdersId:id,pid:localStorage.getItem('fo_profileid'),'cancel_reason':'','cancel_remarks':'Cancelled by User'},
					}).then(function (response) {
						Common.showLoader(false);
						if(response.data.success){
							Common.toast.success(response.data.message);
							loadDataTable(1,perPage,filterText,dates,sortOrder);
							return false;
						}
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
            },
            {
                label: 'No',
                onClick: () => {}
            }]
        });
	}

	const columns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => viewOrders(row.id)}><i className='bx bx-book-open'></i> View Order </button>
						{row.doc_status === 'P' &&
						<button className="dropdown-item" onClick={(e) => cancelOrders(row.id)}><i className='bx bxs-x-square'></i> Cancel Order</button>
						}
					</div>
				  </div>
			)
		},
        {
            name: 'Order No.',
            selector: row => row.doc_status === 'P' ? row.pre_inv_no : row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Order Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
		{
            name: 'Store Name',
            selector: row => row.store_name,
            sortable: true,
			sortField: 'store_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
		{
            name: 'Status',
            selector: row => (row.doc_status === 'C' ? 'Cancelled' : (row.doc_status === 'P' ? 'Pending' : (row.convert_to_sale === '0' ? 'Confirmed' : 'Completed'))),
            sortable: true,
			sortField: 'doc_status',
			conditionalCellStyles : [
				{
					when: row => row.doc_status === 'C',
					style: {
						backgroundColor: 'inherit',
						color: 'red'
					},
				},
				{
					when: row => row.doc_status === 'P',
					style: row => ({ backgroundColor: 'inherit',color:'orange' }),
				},
				{
					when: row => row.doc_status === 'A' && row.convert_to_sale === '0',
					style: row => ({ backgroundColor: 'inherit',color:'blue' }),
				},
				{
					when: row => row.doc_status === 'A' && row.convert_to_sale === '1',
					style: row => ({ backgroundColor: 'inherit',color:'green' }),
				},
			]
        },
        {
            name: 'Payments',
            selector: row => row.upi,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.payment_gateway === '1' && row.doc_status === 'A' && (row.convert_to_sale === '0' || (row.convert_to_sale === '1' && row.sale_due > 0))) ? <Button variant="primary" className="btn-sm me-1" onClick={()=>{ handlePayment(row.total_amt,row.inv_no) }}>Pay</Button> : 'X'
			)
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
		{
			name: 'Cancel Reason',
			selector: row => row.cancel_reason,
			sortable: true,
			sortField: 'cancel_reason'
		}
	];

	const handlePayment = (totAmt,orderId) => {
		setViewUPIModal(true);
	}
    return (
	<>
		<Offcanvas placement={'end'} show={showCart} onHide={()=>{setShowCart(false)}}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Order Details : {invoiceDetails.inv_no}</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<div className="table-responsive">
					<table id="cartTable" className="table table-sm table-hover table-borderless align-middle">
						<thead>
							<tr><th style={{'width':'10%'}}></th><th style={{'width':'70%'}}>Item</th><th style={{'width':'20%'}} className="text-end">Qty</th></tr>
						</thead>
						<tbody>
						{itemDetails.map((item,index) => {
							return (
								<tr key={"iCart"+index}>
									<td>
									{item.media_name !=='' ? <>
										{ (item.media_type === 'image/png' || item.media_type === 'image/jpeg') ?
											<img className="border rounded" src={item.media_name} width="50" height="auto" alt={"Items Cart"+index}/>
										:
											<video className="w-100" controls>
												<source src={item.media_name} type={item.media_type}/>
												Your browser does not support the video tag.
											</video>
										}
										</>
									: <img className="border rounded" src={window.location.origin+"/assets/img/no-media.png"} height="auto" width="50" alt={"Items Cart"+index}/>
									}
									</td>
									<td>{item.name}<br/><small className="text-success">{Common.displayPrice(item.amt)}</small></td>
									<td className="text-end" width="30%">
										{item.qty}
									</td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</div>
				<hr/>
				<div className="list-group small_font">
					<div className="list-group-item list-group-item-action text-nowrap">Subtotal: <span className="float-end">{Common.displayPrice(invoiceDetails.total_taxable_amt)}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">GST: <span className="float-end">{Common.displayPrice(parseFloat(invoiceDetails.igst_amt)+parseFloat(invoiceDetails.cgst_amt)+parseFloat(invoiceDetails.sgst_amt))}</span></div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Additional discount:</span>
							</div>
							<div className="col-4 text-end">
								{Common.displayPrice(invoiceDetails.total_doc_discount)}
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Other charges:</span>
							</div>
							<div className="col-4 text-end">
								{Common.displayPrice(invoiceDetails.other_charges)}
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap">
						<div className="row">
							<div className="col-8">
								<span>Round off:</span>
							</div>
							<div className="col-4 text-end">
								{Common.displayPrice(invoiceDetails.round_off)}
							</div>
						</div>
					</div>
					<div className="list-group-item list-group-item-action text-nowrap active">Total: <span className="float-end">{Common.displayPrice(invoiceDetails.total_amt)}</span></div>
				</div>
			</Offcanvas.Body>
		</Offcanvas>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="orders" {...props}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">Orders</h4>
							<div className="row">
								<div className="col-sm-6 col-md-2 mb-1 text-center text-lg-start">
									<Common.OverlayTrigger placement={'top'} overlay={Common.tooltipContent('Refresh')}>
                                        <Button variant="primary" className="btn-sm me-1" onClick={()=>{setDtLoad(!dt_load);setDTPending(true)}}><i className='bx bx-refresh'></i></Button>
                                    </Common.OverlayTrigger>
									{/*<div className="btn-group btn-group-sm" role="group" aria-label="">
										{orderTypes.map( (type,index) => {
											return (
												<button onClick={()=>setOrderType(type)}key={"btnOT"+index} type="button" className={"btn btn-outline-primary "+(orderType === type ? "active":"")}>{type}</button>
											)
										})}
									</div>*/}
								</div>
								<div className="col-sm-6 col-md-5 text-center mb-1" >
									<DateRangePicker
										initialSettings={DTE.DatePickerPredefined(moment,start,end)}
										onCallback={setDatePicker}>
										<div className="input-group input-group-sm">
											<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
											<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
												<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
											</div>
										</div>
									</DateRangePicker>
									<small>Date range based on Order Date</small>
								</div>
								<div className="col-sm-12 col-md-5 text-center text-md-end mb-1">
									<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Order Number, Store Name')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
									<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
								</div>
							</div>
                            <div className="row">
								<div className="col-md-12">
                                    <DTE.DataTable className="rdt_AdjDrp dataTables_wrapper" title={"Orders ("+totalRows+")"} columns={columns} data={orders} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Common.FYNTicket url={props.url}/> */}
                </div>
            </div>
        </div>
		<Modal show={viewUPIModal} size='md' onHide={()=>setViewUPIModal(false)} aria-labelledby="UPI-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Pay</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<ul className="px-10">
							<li>Pl pay the merchant directly via UPI/Cash/Card etc.</li>
							<li>The paylink will be visible even after you make the payment and will disappear only when the merchant updates the payment details on their app.</li>
							<li>For any disputes on the payment, pl contact the store merchant</li>
						</ul>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" onClick={()=>setViewUPIModal(false)}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	</>
    );
}