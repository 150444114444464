import React,{useState}  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
// import 'react-date-picker/dist/DatePicker.css';
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import Modal from 'react-bootstrap/Modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as DPE from '../common/ImportDatePickerEssentials';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export default function Gst(props) {
    // const [showProModal, setShowProModal] = useState(false);
    const [dates, setDates] = useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
    const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
    const setDatePicker = (start, end) => {
        setDates({ start, end });
    }
    const [editModal,setEditModal] = useState(false);
    const [editPurchaseModal,setEditPurchaseModal] = useState(false);
    const handleModalClose = () => {setEditModal(false);}
    const handlePurchaseModalClose = () => {setEditPurchaseModal(false);}
    const navigate = Common.useNavigate();
    const [complianceModal, setComplianceModal] = useState(false);
    const [isSubTabEnabled, setIsSubTabEnabled] = useState(false);
    const [einvPlan, setEinvPlan] = useState(false);
    const [ewayPlan, setEwayPlan] = useState(false);
    const [gstPlan, setGstPlan] = useState(false);
    const [einvoice_status, setEinvoiceStatus] = useState(0);
    const [saveBtnDisabled,setSaveBtnDisabled] = useState('');
    const [ewayStatus, setEwayStatus] = useState(0);
    const [gstStatus, setGstStatus] = useState(0);
    const [einvPass, setEinvPass] = useState('');
    const [einvUser, setEinvUser] = useState('');
    const [gstUser, setGstUser] = useState('');
    const [einvValidateBlock,setEinvValidateBlock]      = useState(false);
    const [ewayValidateBlock,setEwayValidateBlock]      = useState(false);
    const [gstinValidateBlock,setGstinValidateBlock]      = useState(false);
    const [einvNicUser,setEinvNicUser]      = useState('');
    const [einvNicPassword,setEinvNicPassword]      = useState('');
    const [ewayNicUser,setEwayNicUser]      = useState('');
    const [ewayNicPassword,setEwayNicPassword]      = useState('');
    const [gstNicUser,setGstNicUser]      = useState('');

    React.useEffect(() => {
        document.title = 'GST | '+props.site_name;
        if (localStorage.getItem('fo_subplan') === 'Basic' || localStorage.getItem('fo_subplan') === 'Standard') {
			navigate("/pricing-plan");
		}
        Common.axios({
            method: 'post',
            url:props.url+'gst/getGstStatus',
            data: {
                'pid':localStorage.getItem('fo_profileid')
            }
        }).then(function (response) {
            if(response.data.success){
                // setGstStatus(response.data.data.gst_status);
                if (response.data.data.gst_status === '1') {
                    setComplianceModal(false);
                    return false;
                } else {
                    if (localStorage.getItem('fo_subplan') === 'PRO' || localStorage.getItem('fo_subplan') === 'Enterprise') {
                        setComplianceModal(true);
                        return false;
                    }

                }
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
        Common.axios({
            method: 'get',
            url:props.url+'subscription/details/'+localStorage.getItem('fo_profileid'),
        }).then(function (response) {
            if(response.data.success){
				setEinvoiceStatus(response.data.data.einvoice_status);
                setSaveBtnDisabled(response.data.data.disable_btn);
                setEwayStatus(response.data.data.ewb_status);
                setGstStatus(response.data.data.gst_status);
                
                // username
                setEinvUser(response.data.data.nic_user);
                setGstUser(response.data.data.gst_user);

                // password
                setEinvPass(response.data.data.nic_pass);

                response.data.data.is_sub_tab_enabled === '1' ? setIsSubTabEnabled(true) : setIsSubTabEnabled(false);
                if (response.data.data.sub_plan === 'PRO' || response.data.data.sub_plan === 'Enterprise') {
                    setEinvPlan(true);
                    setEwayPlan(true);
                    setGstPlan(true);
                }
            }
        }).catch(error => console.log(error));
	}, [props]);
    const handleValidationGst = (e) => {
        e.preventDefault();
        if (gstNicUser === '') {
            Common.toast.error('GST credentials cannot be blank');
            return false;
        } else {
            Common.axios({
                method: 'post',
                url: props.url+'subscription/validateNicCredentials',
                // data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
                data: {
                    pid:localStorage.getItem('fo_profileid'),
                    validateFlag:'gst',
                    user:gstNicUser,
                    password: ''
                }
            }).then(function (response) {
                if(response.data.success){
                    Common.toast.success(response.data.message);
                    // setGstUpdated(true);
                    window.location.reload();
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error));
        }
    }
    const subscriptionStatus = (checked) => {
		Common.axios({
			method: 'post',
			url: props.url+'subscription/subChangeStatus',
			data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
		}).then(function (response) {
            console.log(response);
            if(response.data.success){
				Common.toast.success(response.data.message);
				return false;
            }
			Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
	}
    const updateEinvStatus = (checked) => {
        checked === true ? setEinvoiceStatus('1') : setEinvoiceStatus('0');
        Common.axios({
            method: 'post',
            url: props.url+'subscription/updateStatus',
            data: {
                pid:localStorage.getItem('fo_profileid'),
                validateFlag:'einv',
                status: checked,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                // window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const updateEwbStatus = (checked) => {
        checked === true ? setEwayStatus('1') : setEwayStatus('0');
        Common.axios({
            method: 'post',
            url: props.url+'subscription/updateStatus',
            data: {
                pid:localStorage.getItem('fo_profileid'),
                validateFlag:'eway',
                status: checked,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                // setEwbUpdated(true);
                // window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const updateGstStatus = (checked) => {
        checked === true ? setGstStatus('1') : setGstStatus('0');
        Common.axios({
            method: 'post',
            url: props.url+'subscription/updateStatus',
            data: {
                pid:localStorage.getItem('fo_profileid'),
                validateFlag:'gst',
                status: checked,
            }
        }).then(function (response) {
            if(response.data.success){
                Common.toast.success(response.data.message);
                // setEwbUpdated(true);
                // window.location.reload();
                return false;
            }
            Common.toast.error(response.data.message);
        }).catch(error => console.log(error));
    }
    const handleValidationEinv = (e) => {
        e.preventDefault();
        if (einvNicUser === '' || einvNicPassword === '') {
            Common.toast.error('E-Invoice credentials cannot be blank');
            return false;
        } else {
            Common.axios({
                method: 'post',
                url: props.url+'subscription/validateNicCredentials',
                // data: {pid:localStorage.getItem('fo_profileid'),einvoice_status:checked}
                data: {
                    pid:localStorage.getItem('fo_profileid'),
                    validateFlag:'einv',
                    user:einvNicUser,
                    password:einvNicPassword
                }
            }).then(function (response) {
                if(response.data.success){
                    Common.toast.success(response.data.message);
                    // setEinvUpdated(true);
                    window.location.reload();
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error));
        }
    }
    const handleValidationEway = (e) => {
        e.preventDefault();
        if (ewayNicUser === '' || ewayNicPassword === '') {
            Common.toast.error('Eway Bill credentials cannot be blank');
            return false;
        } else {
            Common.axios({
                method: 'post',
                url: props.url+'subscription/validateNicCredentials',
                data: {
                    pid:localStorage.getItem('fo_profileid'),
                    validateFlag:'eway',
                    user:ewayNicUser,
                    password:ewayNicPassword
                }
            }).then(function (response) {
                if(response.data.success){
                    Common.toast.success(response.data.message);
                    // setEwbUpdated(true);
                    window.location.reload();
                    return false;
                }
                Common.toast.error(response.data.message);
            }).catch(error => console.log(error));
        }
        
    }
    const popUpIncomeDataRange = () => {
        setEditModal(true);
    }
    const popUpPurchaseDataRange = () => {
        setEditPurchaseModal(true);
    }
    const subscriptionRedirect =() => {
        navigate('/pricing-plan');
    }
    
    const handleIncomeCSVExport = (e) => {
        e.preventDefault();
        Common.axios({
            method: 'post',
            url: props.url+'gst/downloadIncomeCollectionRecords/',
            data: {
            pid:localStorage.getItem('fo_profileid'),
            dates:dates
        },
        // responseType: 'blob'
        }).then(function (response) {
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Income-Report-${Date.now()}.csv`);
                document.body.appendChild(link); */
                setEditModal(false);
                // link.click();
            } else{
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
    }

    const handleExpenseCSVExport = (e) => {
        e.preventDefault();
        Common.axios({
            method: 'post',
            url: props.url+'gst/downloadPurchaseExpensesRecords/',
            data: {
            pid:localStorage.getItem('fo_profileid'),
            dates:dates
        },
        // responseType: 'blob',
        }).then(function (response) {
            if(response.data.success){
                window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Expenses-Report-${Date.now()}.csv`);
                document.body.appendChild(link); */
                window.location.href = response.data.message;
                setEditPurchaseModal(false);
                // link.click();
            } else{
                Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
    }
    
    return (
        <>
            <div className="layout-wrapper layout-menu-fixed layout-content-navbar">
                <div className="layout-container">
                <Layout site_logo={props.site_logo} label="tax_compliance" site={props.site_name}/>
                    <div className="layout-page">
                        <NavBar site={props.site_name} url={props.url}/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Tax Compliance /</span> GST</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="nav-align-top mb-4">
                                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                                <li className="nav-item">
                                                    <button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="true">
                                                        GST returns
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-profile" aria-controls="navs-justified-profile" aria-selected="false">
                                                        GSTR 2B
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="navs-justified-home" role="tabpanel">
                                                    <h3 className="display-6">GSTR1</h3>
                                                    <ol className="mb-4 p-2">
                                                        <li style={{'padding':'0.25rem'}}><button type="button" className="btn btn-primary btn-sm" onClick={popUpIncomeDataRange}>Download</button> the sales register with document date within above selected date range</li>
                                                        <li style={{'padding':'0.25rem'}}><u><a href="https://www.fynamicsgst.in/fynone/" rel="noreferrer" target="_blank">Click Here</a></u> to navigate to Fynamics GST compliance module</li>
                                                        <li style={{'padding':'0.25rem'}}>Upload the file. Fynamics GST automatically segregates data into relevant GSTN buckets and across individual GSTINs</li>
                                                        <li style={{'padding':'0.25rem'}}>Access reports and dashboard for MIS review and file returns</li>
                                                    </ol>
                                                    <h3 className="display-6 mt-2">GSTR 3B, 6, ITC04 etc</h3>
                                                    <ol className="mb-4 p-2">
                                                        <li style={{'padding':'0.25rem'}}><a href="https://www.fynamicsgst.in/fynone/" rel="noreferrer" target="_blank">Click Here</a> to navigate to Fynamics GST compliance module and perform the activities directly</li>
                                                    </ol>
                                                    <hr/>
                                                    <p className="p-lead text-center">Fynamics GST, FYN Gateway and FYN ONE are all products of Fynamics - an approved GST Suvidha Provider.</p>
                                                </div>
                                                <div className="tab-pane fade" id="navs-justified-profile" role="tabpanel">
                                                    <h3 className="display-6 mt-2">GSTR 2B Reconciliation</h3>
                                                    <ol className="mb-4 p-2">
                                                        <li style={{'padding':'0.25rem'}}><button type="button" className="btn btn-primary btn-sm" onClick={popUpPurchaseDataRange}>Download</button> the purchase register with document date within above selected date range</li>
                                                        <li style={{'padding':'0.25rem'}}><u><a href="https://www.fynamicsgst.in/fynone/" rel="noreferrer" target="_blank">Click Here</a></u> to navigate to Fynamics GST compliance module</li>
                                                        <li style={{'padding':'0.25rem'}}>Upload the file. Fynamics GST automatically identifies duplicate invocies and amendment transactions</li>
                                                        <li style={{'padding':'0.25rem'}}>Perform reconciliation with GSTR2B pulled from GSTN automatically via APIs</li>
                                                        <li style={{'padding':'0.25rem'}}>Fynamics reconciles the data using AI engines and segregates them into Exact Match, Partial Match and No Match</li>
                                                        <li style={{'padding':'0.25rem'}}>Take relevant actions on ITC claims to impact 3B suggestion</li>
                                                    </ol>
                                                    <hr/>
                                                    <p className="p-lead text-center">Fynamics GST, FYN Gateway and FYN ONE are all products of Fynamics - an approved GST Suvidha Provider.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Common.FYNTicket url={props.url}/>
                    </div>
                </div>
            </div>
            {/* income */}
            <Modal {...props} size="sm" show={editModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose Date Range For Sale Records</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <DateRangePicker initialSettings={DPE.DatePickerPredefined(moment,start,end)} onCallback={setDatePicker}>
                <div className="input-group">
                    <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                    <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                        <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                    </div>
                </div>
            </DateRangePicker>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleIncomeCSVExport}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
        {/* purchase */}
        <Modal {...props} size="sm" show={editPurchaseModal} onHide={handlePurchaseModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Choose Date Range For Purchase Records</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <DateRangePicker initialSettings={DPE.DatePickerPredefined(moment,start,end)} onCallback={setDatePicker}>
                <div className="input-group">
                    <span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
                    <div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
                        <i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
                    </div>
                </div>
            </DateRangePicker>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleExpenseCSVExport}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
        {/* <Modal {...props} size="sm" show={showProModal}  aria-labelledby="gst-compliance-modal" backdrop="static" keyboard={false} centered>
            <Modal.Header>
                <div className="col-6 modal-mobile-view">
                    <Modal.Title>Renewal/Upgrade</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
            
                <b >GST compliance module can be enabled under PRO plan . Please <button className='border-0 bg-white p-0 text-primary cursor-pointer' onClick={(e)=>subscriptionRedirect()}>Click Here</button> to enable</b>
            </Modal.Body>
        </Modal> */}
        <Modal size='lg' show={complianceModal} onHide={()=>setComplianceModal(false)} aria-labelledby="contact-modal" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
            <Modal.Title>Compliance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className="col-sm-4">
                        <h3 style={{'fontWeight': '300', 'color': '#566a7f'}}>e-Invoice</h3>
                        {isSubTabEnabled === true ? <p className="price">How to add Fynamics GSP: <a target='_blank' rel='noreferrer' href={props.url+ 'public/subscription_files/Fynamics GSP for Einvoice.pdf'}>Read More</a></p> : <></>}
                        
                        { isSubTabEnabled === true ? saveBtnDisabled === true ? (<BootstrapSwitchButton disabled checked={(einvoice_status === '1' )} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => { subscriptionStatus(checked); }} />) : '' : <></>}
                        
                        {einvPlan ? <>
                            {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton checked={einvValidateBlock || (einvoice_status === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('einv'); */setEinvValidateBlock(checked);updateEinvStatus(checked)}} />) : '' : <></>}
                        </> : <>
                            {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton checked={einvValidateBlock || (einvoice_status === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" disabled offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('einv'); */setEinvValidateBlock(checked);updateEinvStatus(checked)}} />) : '' : <></>}
                        </>}
                        {}

                    </div>
                    <div className='col-sm-2'></div>
                    <div className={einvValidateBlock === true || einvoice_status === '1' ? "col-sm-3 " : "d-none"}>
                        <form id="form-einv" onSubmit={handleValidationEinv} autoComplete="off">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bs-validation-name">API Username</label>
                                {einvUser === '' ?
                                <input type="text" className="form-control form-control-sm" name='bs-validation-name' id="bs-validation-name" value={einvNicUser || einvUser} onChange={(e) => setEinvNicUser(e.target.value)}/>
                                :
                                <input type="text" className="form-control form-control-sm" name='bs-validation-name' readOnly={ (einvoice_status === '1')} id="bs-validation-name" value={einvNicUser || einvUser} onChange={(e) => setEinvNicUser(e.target.value)}/>
                                }
                            </div>
                            <div className="mb-3 form-password-toggle">
                                <label className="form-label" htmlFor="bs-validation-password">API Password</label>
                                <div className="input-group input-group-merge">
                                    {einvPass === '' ?
                                    <input type="text" id="bs-validation-password" name='bs-validation-password' className="form-control form-control-sm" value={einvNicPassword || einvPass} onChange={(e) => setEinvNicPassword(e.target.value)}/>
                                    :    
                                    <input type="text" id="bs-validation-password" name='bs-validation-password' readOnly={ (einvoice_status === '1')} className="form-control form-control-sm" value={einvNicPassword || einvPass} onChange={(e) => setEinvNicPassword(e.target.value)}/>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                {einvUser === '' && einvPass === '' ?
                                <button type="submit" className="btn btn-primary btn-sm">Save</button>
                                :
                                <button type="submit" disabled={ (einvoice_status === '1')} className="btn btn-primary btn-sm">Save</button>
                                    }
                                </div>
                            </div>
                        </form> 
                    </div>
                </div>
                <hr/>
                <div className='row'>
                    <div className="col-sm-4">
                        <h3 style={{'fontWeight': '300', 'color': '#566a7f'}}>Eway Bill</h3>
                        {isSubTabEnabled === true ? <p className="price">Same e-Invoice credentials can be used here</p> : <></>}
                        {isSubTabEnabled === true ? <p className="price">For non-einvoice enabled profiles <a target='_blank' rel="noreferrer" href={props.url + 'public/subscription_files/Fynamics GSP for EWay bill.pdf'}>Read More</a></p>: <></>}
                        { isSubTabEnabled === true ? saveBtnDisabled === true ? (<BootstrapSwitchButton disabled checked={(ewayStatus === '1' )} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => { subscriptionStatus(checked); }} />) : '' : <></>}
                        
                        {ewayPlan ? <>
                            { isSubTabEnabled === true ? saveBtnDisabled === false ?  (<BootstrapSwitchButton  checked={ewayValidateBlock || (ewayStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('eway'); */setEwayValidateBlock(checked); updateEwbStatus(checked)}} />) : '' : <></>}
                        
                        </> : <>
                            { isSubTabEnabled === true ? saveBtnDisabled === false ?  (<BootstrapSwitchButton  checked={ewayValidateBlock || (ewayStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" disabled offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('eway'); */setEwayValidateBlock(checked); updateEwbStatus(checked)}} />) : '' : <></>}
                        </>}
                    </div>
                    <div className='col-sm-2'></div>
                    <div className={ewayValidateBlock === true || ewayStatus === '1' ? "col-sm-3 " : "d-none"}>
                        {/*  */}
                    <form id="form-eway" onSubmit={handleValidationEway}  autoComplete="off">
                        <div className="mb-3">
                            <label className="form-label" htmlFor="bs-validation-name-eway">API Username</label>
                            {einvUser === '' && einvPass === '' ? 
                            <input type="text" className="form-control form-control-sm" name='bs-validation-name-eway' id="bs-validation-name-eway" value={ewayNicUser} onChange={(e) => {setEwayNicUser(e.target.value)}}/>
                            :
                            <input type="text" className="form-control form-control-sm" readOnly={(einvoice_status === '1') || (ewayStatus === '1')} name='bs-validation-name-eway' id="bs-validation-name-eway" value={ewayNicUser || einvUser} onChange={(e) => {setEwayNicUser(e.target.value)}}/>
                        }
                        </div>
                        <div className="mb-3 form-password-toggle">
                            <label className="form-label" htmlFor="bs-validation-password-eway">API Password</label>
                            <div className="input-group input-group-merge">
                                {einvUser === '' && einvPass === '' ?
                                <input type="text" id="bs-validation-password-eway" name='bs-validation-password-eway' className="form-control form-control-sm" value={ewayNicPassword} onChange={(e) => setEwayNicPassword(e.target.value)}/>
                                :
                                <input type="text" id="bs-validation-password-eway" readOnly={(einvoice_status === '1') || (ewayStatus === '1')} name='bs-validation-password-eway' className="form-control form-control-sm" value={einvPass} onChange={(e) => setEwayNicPassword(e.target.value)}/>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            {einvUser === '' && einvPass === '' ?
                            <button type="submit" className="btn btn-primary btn-sm">Save</button>
                            :
                            <button type="submit" disabled={true} className="btn btn-primary btn-sm">Save</button>
                            }
                            </div>
                        </div>
                    </form> 
                    </div>
                </div>
                <hr/>
                <div className='row'>
                    <div className="col-sm-4">
                        <h3 style={{'fontWeight': '300', 'color': '#566a7f'}}>GST returns filing</h3>
                        {isSubTabEnabled === true ? <p className="price">Enable API on GSTN for GSP</p> : <></>}
                        {isSubTabEnabled === true ? saveBtnDisabled === true ? (<BootstrapSwitchButton disabled checked={(gstStatus === '1' )} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => { subscriptionStatus(checked); }} />) : '' : <></>}
                        {gstPlan ? <>
                            {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton  checked={gstinValidateBlock || (gstStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('gst'); */setGstinValidateBlock(checked);updateGstStatus(checked)}} />) : '' : <></>}
                        </> : <>
                            {isSubTabEnabled === true ? saveBtnDisabled === false ? (<BootstrapSwitchButton  checked={gstinValidateBlock || (gstStatus === '1')} onlabel='Active' offlabel='Inactive' onstyle="success" disabled offstyle="danger" size="sm" width={100}  onChange={(checked: boolean) => {/* setValidateFlag('gst'); */setGstinValidateBlock(checked);updateGstStatus(checked)}} />) : '' : <></>}
                        </>}
                    </div>
                    <div className='col-sm-2'></div>
                    <div className={gstinValidateBlock === true || gstStatus === '1' ? "col-sm-3 " : "d-none"}>
                    <form id="form-gst" onSubmit={handleValidationGst} autoComplete='off'>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="bs-validation-name-gst">API Username</label>
                            {gstUser === '' ?
                            <input type="text" className="form-control form-control-sm" name='bs-validation-name-gst' id="bs-validation-name-gst" value={gstNicUser || gstUser} onChange={(e) => setGstNicUser(e.target.value)}/>
                            :
                            <input type="text" className="form-control form-control-sm" readOnly={(gstStatus === '1')} name='bs-validation-name-gst' id="bs-validation-name-gst" value={gstNicUser || gstUser} onChange={(e) => setGstNicUser(e.target.value)}/>
                            }
                        </div>
                        <div className="row">
                            <div className="col-12">
                            {gstUser === '' ?
                            <button type="submit" className="btn btn-primary btn-sm">Save</button>
                            :
                            <button disabled={true} type="submit" className="btn btn-primary btn-sm">Save</button>
                        }
                            </div>
                        </div>
                    </form> 
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}
