import React,{useState,useEffect} from 'react';
import * as Common from '../common/ImportUserEssentials';
import AppLinks from '../common/AppLinks.js'
export default function NavBar(props){
	const [udetails,setUdetails] = useState([]);
	const navigate     = Common.useNavigate();
	const [phoneMenu,setPhoneMenu] = useState('');
	useEffect(() => {
		if( props.guest === undefined && !Common.secureLocalStorage.getItem('fm_loggedin') ){
			Common.destroySession();
			navigate("/login");
		} else {
			if( props.storeDetails ){
				setPhoneMenu('d-none');
			}
			Common.axios({
				method: 'get',
				url:props.url+'user/martuserInfo',
			}).then(function (response) {
				if( response.data.success ){
					setUdetails(response.data.user);
					return false;
				}
				if( props.guest === undefined && response.data.action !== undefined && response.data.action === 'session'){
					logout();
					return false;
				}
				Common.toast.dismiss();
				if( props.guest === undefined ){
					Common.toast.error(response.data.message);
				}
			}).catch(error => console.log(error));
		}

	}, [props.url]);
	const logout = () => {
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'logout',
		}).then(function (response) {
			if( response.data.success ){
				Common.showLoader(false);
				Common.destroySession();
				navigate("/login");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	};
    return (
		<>
		<AppLinks type="fynmart"/>
		<nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className={"layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none "+phoneMenu}>
              <button className="btn btn-link nav-item nav-link px-0 me-xl-4">
                <i className="bx bx-menu bx-sm"></i>
              </button>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
				{props.storeDetails && <>
				<div className="">
					{(props.storeDetails.logo !== null && props.storeDetails.logo !== undefined) ? 
					<img className="rounded" width="45" src={props.url+"public/assets/img/profile_logo/"+props.storeDetails.logo} alt={props.storeDetails.name+" Logo"}/>
					:
					<img className="rounded" width="45" src={props.link+"/assets/img/default_store.png"} alt={props.storeDetails.name+" Logo"}/>
					}
				</div>
				<div className="mx-2 d-grid" id="storeNameNavbar">
					<div className="text-truncate"><b>{props.storeDetails.name}</b></div>
					<small>{props.storeDetails.parent !== undefined ? props.storeDetails.parent +' - '+props.storeDetails.sub : ''}</small>
				</div>
				</>}
				{udetails.uname !== undefined && <>
				<ul className="navbar-nav flex-row align-items-center ms-auto">				
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <button className="btn btn-link nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
						<p data-letters={Common.userInitial(udetails.uname)}></p>
                    </div>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <button className="dropdown-item btn btn-link">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-2">
                            <div className="avatar">
                              <p data-letters={Common.userInitial(udetails.uname)}></p>
							  {/*<img alt="Default User" src="assets/img/default-user-img.jpg"  className="w-px-40 h-auto rounded-circle" />*/}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block">{udetails.uname}</span>
                            <small className="text-muted">{udetails.role}</small>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/profile">
                        <i className="bx bx-cog me-2"></i>
                        <span className="align-middle">My Details</span>
                      </a>
                    </li>
                    <li>
                      <button className="dropdown-item" onClickCapture={logout}>
                        <i className="bx bx-power-off me-2"></i>
                        <span className="align-middle">Log Out</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
				</>}
            </div>
        </nav>
		</>
    );
}