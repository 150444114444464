import { useState } from "react";
import axios from "axios";

const SiteValue = (props) => {
	const [value,setValue] = useState('');
	axios({
		method: 'get',
		url:props.url+'settings/param/'+props.param,
	}).then(function (response) {
		if(response.data.success){
			setValue(response.data.data);
		}
	});
	return value;
};

export default SiteValue;