import React,{useState,useEffect,useRef} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import AccounHeader from './AccountHeader';
import { AES } from 'crypto-js';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/page-auth.css';
export default function Login(props){
	const [mobile, setMobile] = useState('');
	const [mobileDisabled, setMobileDisabled] = useState('');
	const [email, setEmail] = useState('');
	const [emailDisabled, setEmailDisabled] = useState('');
	const [password, setPassword] = useState('');
	const [clicked, setClicked] = useState('');
	const [errorMessage,setError] = useState('');
	const navigate = useNavigate();
	const captchaRef = useRef(null);

	useEffect(() => {
		document.title = 'Login | '+props.site_name;
		if( localStorage.getItem('fo_loggedin') ){
			navigate("/post-login");
		}
	}, [props.site_name]);
	const switchLogin=(e,v)=>{
		if( v === 'm' ){
			e = e.replace(/[^0-9\d]/ig, "");
			setMobile(e);
			if( e !== '' ){
				setEmailDisabled('collapse fade');
			} else {
				setEmailDisabled('');
			}
		}
		if( v === 'e' ){
			setEmail(e);
			if( e !== '' ){
				setMobileDisabled('collapse fade');
			} else {
				setMobileDisabled('');
			}
		}
	}
	const handleClick=(e)=>{
		e.preventDefault();
		if(document.getElementById('password').getAttribute('type')==='text'){
			document.getElementById('password').setAttribute('type', 'password');
			setClicked('bx bx-hide');
		}else if(document.getElementById('password').getAttribute('type')==='password'){
			document.getElementById('password').setAttribute('type', 'text');
			setClicked('bx bx-show');
		}
	}

	const handleSubmit =(e) => {
		e.preventDefault();
		const token = captchaRef.current.getValue();
		axios({
			method: 'post',
			url: props.url+'login/submit',
			data: {
				token:token,mobile:AES.encrypt(mobile.toString(), props.site_key).toString(),email: AES.encrypt(email, props.site_key).toString(),password:AES.encrypt(password, props.site_key).toString()
			},
		}).then(function (response) {
			if(response.data.success){
				if( mobile !== '' ){
					navigate('/verify-mobile',
					{
						state: {
							uid: response.data.id,
							type: 'login',
							last4: mobile.slice(-4)
						}
					});
					return false;
				}
				localStorage.setItem('fo_loggedin', response.data.id);
				localStorage.setItem('fo_profileid',response.data.profileid);
				localStorage.setItem('fo_subplan',response.data.sub_plan);
				localStorage.setItem('fo_subvalidity',response.data.sub_validity);
				localStorage.setItem('fo_plantype',response.data.plan_type);
				localStorage.setItem('fo_collaboration',response.data.is_sub_tab_enabled);
				localStorage.setItem('fo_mart_url','0');
				localStorage.removeItem('fo_freeModal')
				localStorage.removeItem('fo_subModal')
				localStorage.removeItem('fo_subFinalModal')
				localStorage.removeItem('fo_collaboration');
				localStorage.removeItem('fo_pbplanexpbasic')
				localStorage.removeItem('fo_pbplanexppro')
				navigate("/post-login");
			}else if(response.data.register_data){
				navigate('/register',
				{
					state: {
						mobileNo: mobile,
						emailId: email
					}
				});
			}
			setError(response.data.message);
			setTimeout(() => {
				setError('');
			}, 5000);
		}).catch(error => console.log(error));
		captchaRef.current.reset();
	}
    return (
        <div className="container-xxl">
			<div className="authentication-wrapper authentication-basic container-p-y">
				<div className="authentication-inner">
					<div className="card">
						<div className="card-body">
							<AccounHeader site_logo={props.site_logo} site_name={props.site_name} />
							<h4 className="mb-2">Welcome to {props.site_name}!</h4>
							<p className="mb-4">Please sign-in to your account</p>
							{errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">{errorMessage}</div>)}
							<form id="formAuthentication" className="mb-3" autoComplete="off" onSubmit={handleSubmit}>
								<div className={"mb-3 "+mobileDisabled}>
									<label htmlFor="mobile" className="form-label">Mobile</label>
									<input tabIndex="1" type="text" className="form-control" id="mobile" name="mobile" value={mobile} placeholder="Enter mobile" maxLength="10" autoFocus onChange={(e) => switchLogin(e.target.value,'m')} inputMode="numeric"/>
								</div>
								<div className={"divider "+mobileDisabled+' '+emailDisabled}><div className="divider-text">OR</div></div>
								<div className={"mb-3 "+emailDisabled}>
									<label htmlFor="email" className="form-label">Email</label>
									<input tabIndex="2" type="text" className="form-control" id="email" name="email" value={email} placeholder="Enter your email"  onChange={(e) => switchLogin(e.target.value,'e')}/>
								</div>
								<div className={"mb-3 "+emailDisabled}>
									<div className="d-flex justify-content-between">
										<label className="form-label" htmlFor="password">Password</label>
										<Link to="/forgot_password">
											<small>Forgot Password?</small>
										</Link>
									</div>
									<div className="input-group input-group-merge">
										<input tabIndex="3" type="password" id="password" className="form-control" name="password"  value={password} placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" onChange={(e) => setPassword(e.target.value)}/>
										<span className="input-group-text cursor-pointer"><i className={clicked || 'bx bx-hide' }  onClick={handleClick}></i></span>
									</div>
									<div className="my-2">
										<ReCAPTCHA className="g-recaptcha" ref={captchaRef} sitekey={props.recaptcha_key} />
									</div>
								</div>
								  {/*
									<div className="mb-3">
									<div className="form-check">
									  <input className="form-check-input" type="checkbox" id="remember-me" />
									  <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
									</div>
								  </div>
								  */}
								<div style={{width:'100%',display:'table',margin:'0 auto'}}>
									<button tabIndex="5" className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
								</div>
							</form>
							<p className="text-center">
								<span>New on our platform? </span>
								<Link to="/register">
									<span>Create an account</span>
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}
