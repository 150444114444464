import React,{useState,useEffect} from 'react';
import * as Common from '../common/ImportUserEssentials';
import NotificationMartOrder from '../common/NotificationMartOrder.js';

export default function NavBar(props){
	const [udetails,setUdetails] = useState([]);
	const [mart_details,setMartDetails] = useState([]);
	const [walletAmount,setWalletAmount] = useState(<i className="bx bx-loader-alt bx-spin"></i>);
	const [phoneMenu,setPhoneMenu] = useState('');
	const navigate     = Common.useNavigate();
	const site_url = props.url;
	useEffect(() => {
		if( !localStorage.getItem('fo_loggedin') ){
			navigate("/login");
		} else {
			if( document.getElementById('mm_orderadd') ){
				setPhoneMenu('d-none');
			}
			Common.axios({
				method: 'get',
				url:site_url+'user/userInfo',
			}).then(function (response) {
				if( response.data.success ){
					setUdetails(response.data.user);
					return false;
				}
				if( response.data.action !== undefined && response.data.action === 'session'){
					logout();
					return false;
				}
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
			Common.axios({
				method: 'post',
				url:props.url+'mart/details',
				data: {'pid':Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()},
			}).then(function (response) {
				if( response.data.success && (response.data.data === null || response.data.data.id === undefined) ){
					navigate('/mart-add');
					return false;
				}
				setMartDetails(response.data.data);
				Common.secureLocalStorage.setItem('fo_martid', response.data.data.id);
				Common.secureLocalStorage.setItem('fo_martpos', JSON.stringify({'value':response.data.data.pos}));
			}).catch(error => console.log(error));
			
			// Fetch wallet Balance
			Common.axios({
				method: 'get',
				url:props.url+'wallet/balance/'+localStorage.getItem('fo_profileid'),
			}).then(function (response) {
				if(response.data.success){
					setWalletAmount(Common.displayPrice(response.data.data));
				}
			});
		}
	}, [props.refresh,site_url]);
	const logout = () => {
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'logout',
		}).then(function (response) {
			if( response.data.success ){
				Common.showLoader(false);
				Common.destroySession();
				navigate("/login");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	};

    return (
		<>
		<NotificationMartOrder url={props.url} pid={localStorage.getItem('fo_profileid')}/>
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className={"layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none "+phoneMenu}>
              <button className="btn btn-link nav-item nav-link px-0 me-xl-4">
                <i className="bx bx-menu bx-sm"></i>
              </button>
            </div>
            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
				<div className="d-grid">
					<div className="text-truncate fw-bold">{mart_details.name !== undefined ? mart_details.name : ''}</div>
					<small>{mart_details.parent !== undefined ? mart_details.parent +' - '+mart_details.sub : ''}</small>
				</div>
				<ul className="navbar-nav flex-row align-items-center ms-auto">				
				<a href="/pricing-plan" className="btn btn-success me-1 text-nowrap px-2"><i className='bx bx-wallet hidden-phone'></i><small>{walletAmount}</small></a>
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <button className="btn btn-link nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
						<p data-letters={Common.userInitial(udetails.uname)}></p>
                    </div>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <button className="dropdown-item btn btn-link">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-2">
                            <div className="avatar">
                              <p data-letters={Common.userInitial(udetails.uname)}></p>
							  {/*<img alt="Default User" src="assets/img/default-user-img.jpg"  className="w-px-40 h-auto rounded-circle" />*/}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block">{udetails.uname}</span>
                            <small className="text-muted">{udetails.role}</small>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/mart-settings">
                        <i className="bx bx-cog me-2"></i>
                        <span className="align-middle">Mart Settings</span>
                      </a>
                    </li>
                    <li>
                      <button className="dropdown-item" onClickCapture={logout}>
                        <i className="bx bx-power-off me-2"></i>
                        <span className="align-middle">Log Out</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
        </nav>
		</>
    );
}