import React  from "react";
import Layout from './Layout';
import Button from 'react-bootstrap/Button';
import NavBar from './NavBar';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import styled from 'styled-components';
import * as Common from '../common/ImportUserEssentials';
import * as DTE from '../common/ImportDataTableEssentials';
import MultiDownloadCustomFields from './MultiDownloadCustomFields';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import TagManager from 'react-gtm-module';

const Tab = styled.button`
	font-size: 16px;
	padding: 10px 60px;
	cursor: pointer;
	opacity: 0.6;
	background: white;
	border: 0;
	outline: 0;
	background-color: #ecf0f1f2;
	${({ active }) =>
		active &&
		`
		border-bottom: 2px solid black;
		opacity: 1;
	`}
`;
const ButtonGroup = styled.div`
	display: flex;
`;

export default function SalesListing(props){
	const checkboxes = ['primary','item','terms','dispatch','shipto','export','custom'];
	const [checkAll,setCheckAll] = React.useState(false);
	const [totalDataCnt,setTotalDataCount] = React.useState({'Active':'','Cancelled':'','Draft':''});
	const [filterText, setFilterText] = React.useState('');
	const [dt_load,setDtLoad] = React.useState(true);
	const [DTpending, setDTPending] = React.useState(true);
    const [sales, setSalesList]    = React.useState([]);
	const [einvoice_status,setEinvoiceStatus] = React.useState(0);
	const [export_status,setExportStatus] = React.useState(0);
	const [irn_details,setIrnDetails] = React.useState({ackno:'',ackdt:'',irn:'',signedinvoice:'',signedqrcode:'',status:'',remarks:''});
	const [viewModal, setViewModal] = React.useState(false);
	const handleViewClose = () => setViewModal(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [stateClear, setStateClear] = React.useState({ toggledClearRows: false });
	const [totalRows, setTotalRows] = React.useState(0);
	const [perPage, setPerPage] = React.useState(10);
	const [exportBtnClass,setExportBtnClass]    = React.useState('d-none');
	const [exportModal,setExportModal] = React.useState(false);
	const [einvCancelShow,setEinvCancelShow] = React.useState(false);
	const [einvCancelRemarks,setEinvCancelRemarks] = React.useState('');
	const [einvCancelError,setEinvCancelError] = React.useState('');
	const [saleId,setSaleId] = React.useState('');
	const [irnNo,setIrnNo] = React.useState('');
	const [irnCancelDate,setIrnCancelDate] = React.useState('');
	const [can_type,setCanType] = React.useState('0');
    const invoiceTabTypes = ['Active', 'Cancelled', 'Draft'];
    const [activeTab, setActCan] = React.useState(invoiceTabTypes[0]);
	const [cancelCheckShow, setCancelCheckShow] = React.useState(false);
	const [cancelCheckMessage, setCancelCheckMessage] = React.useState('');
	const [invNo,setInvNo] = React.useState('');
	const [sortOrder,setSortOrder] = React.useState('');
	const [exportDataModal,setExportDataModal] = React.useState(false);
	const [expShipbNo,setExpShipbNo] = React.useState('');
	const [expShipbDate,setExpShipbDate] = React.useState(new Date());
	const [exportFlag,setExportFlag] = React.useState(0);
	const [alertModal, setAlertModal] = React.useState(false);
	const [multiDownloadPdfShow, setMultiDownloadPdfShow] = React.useState(false);
	const [customInputList, setCustomInputList]	  = React.useState([]);

	const [totCustomer,setTotCustomer] = React.useState({'Active':'-','Cancelled':'-','Draft':'-'});
	const [totInvAmt,setTotInvAmt] = React.useState({'Active':'-','Cancelled':'-','Draft':'-'});
	const [dlParams,setDlParams] = React.useState([]);
	const [dates, setDates] = React.useState({
		start: moment().startOf('month'),
		end: moment().endOf('month'),
    });
    const { start, end } = dates;
	const label = start.format('DD-MM-YYYY') + ' ~ ' + end.format('DD-MM-YYYY');
	const navigate = Common.useNavigate();
	const [incomeColl_r,setincomeColl_r] = React.useState(true);
	const { state } = Common.useLocation();

	const [incomeColl_w,setincomeColl_w] = React.useState(true);
    React.useEffect(() => {
		// User permissions
		let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Read permission
			setincomeColl_r((userPerm[2]['view'] !== undefined && userPerm[2]['view'] === '0') ? false : true);
		}
        document.title = 'Sale Listing | '+props.site_name;
		const hostname = window.location.hostname;
		if( hostname === 'mart.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}

			TagManager.initialize(tagManagerArgs);
		}
		let passedOnTab = '';
		if (state !== null) {
			if (state.tab_type === 2) {
				setActCan(invoiceTabTypes[state.tab_type])
				passedOnTab = invoiceTabTypes[state.tab_type]
				setShowActiveDiv(false);
				setShowCancelledDiv(false);
				setShowDraftDiv(true);
			}else{
				passedOnTab = activeTab;
			}
		} else {
			passedOnTab = activeTab;
		}
		if( Object.keys(userPerm).length > 0 ){
			setincomeColl_w((userPerm[2]['write'] !== undefined && userPerm[2]['write'] === '0') ? false : true);
		}
        loadDataTable(1,perPage,filterText,dates,'',passedOnTab);
    },[props,dt_load,filterText,perPage,dates]);
	if( !incomeColl_r ){
		navigate("/not-authorized");
	}
    const setDatePicker = (start, end) => {
		setDates({ start, end });
		//setDtLoad(!dt_load);
    }
	const handleClear = () => {
		if (filterText) {
			setDtLoad(!dt_load);
			setFilterText('');
		}
	};
	const handlePageChange = page => {
		loadDataTable(page,perPage,filterText,dates,sortOrder);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		//loadDataTable(page,newPerPage,filterText,dates);
		setPerPage(newPerPage);
	};
	const handleSort = (column, sortDirection) => {
		setSortOrder(btoa(column.sortField+'####'+sortDirection))
		loadDataTable(1,perPage,filterText,dates,btoa(column.sortField+'####'+sortDirection));
	};
    const loadDataTable = (page,perPage,filterText,dates,sort='',active_tab=activeTab) => {
		let pid = 0
		if (state !== null) {
			pid = state.pid
		} else {
			pid = localStorage.getItem('fo_profileid');
		}
		if( pid !== null ){
			Common.axios({
				method: 'post',
				url:props.url+'sales/listing/',
				data: {'pid':pid,'page':page,'perPage':perPage,'sort':sort,'search':filterText,'dates':dates,'active_tab':active_tab}
			}).then(function (response) {
				if(response.data.success){
					setExportBtnClass('d-none');
					setSalesList(response.data.data);
					setTotalRows(response.data.total);
					setTotalDataCount(prevState => ({
					...prevState,
						[active_tab]: response.data.total
					}));
					setEinvoiceStatus(response.data.einvoice_status);
					setExportStatus(response.data.export_status);
					setDTPending(false);
					if(response.data.total > 0){
						setExportBtnClass('d-inline-block');
					}
					setTotCustomer(prevState => ({
					...prevState,
						[active_tab]: response.data.tabInfo.customer
					}));
					setTotInvAmt(prevState => ({
					...prevState,
						[active_tab]: response.data.tabInfo.amount
					}));
				}
				if( response.data.action !== undefined && response.data.action === 'session'){
					Common.destroySession();
					navigate("/login");
					return false;
				}
			}).catch(error => console.log(error));
		}
    }
	const checkAllItems = event => {
		if(event.target.checked){
			setDlParams(checkboxes);
			setCheckAll(true);
		} else {
			setDlParams([]);
			setCheckAll(false);
		}
	}
	const setDownloadParams = event => {
        if(event.target.checked){
            setDlParams([...dlParams, event.currentTarget.value]);
        } else {
            setDlParams(dlParams.filter(item=>item !== event.currentTarget.value));
        }
    }
	const handleExportCSV = (e)=>{
		e.preventDefault();
		let pid = localStorage.getItem('fo_profileid');
        Common.axios({
            method: 'post',
            url:props.url+'sale/download_csv',
			data: {'pid':pid,'search':filterText,'dates':dates,'active_tab':activeTab,'dlParams':dlParams},
            // responseType: 'blob',
        }).then(function (response) {
			if(response.data.success){
				window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
				const contentDisposition = response.headers['content-disposition'];
				let fileName = 'unknown';
				if (contentDisposition) {
					const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
					if (fileNameMatch.length === 2)
						fileName = fileNameMatch[1];
				}
				Common.saveAs(url, fileName); */
				setExportModal(false);
				setCheckAll(false);
				setDlParams([]);
			} else{
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
            }
        }).catch(error => console.log(error));
	}
    const columns = [
		{
            name: 'Action',
            button: true,
            cell: (row) => (
                <div className="dropdown">
					<button type="button" className="btn me-2 p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id,1,row.inv_no)}><i className="bx bxs-download"></i> Download Single Copy</button>						
						<button className="dropdown-item" onClick={(e) => handleMultipleDownloadPdf(row)}><i className="bx bxs-download"></i> Download Multiple Copy</button>
						<button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id,'copy',0)}><i className='bx bxs-copy'></i> Copy Invoice</button>
						<button className="dropdown-item" onClick={(e) => handleCancelEinvoice(row)}><i className='bx bx-message-square-x'></i> Cancel Invoice</button>
					</div>
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					<i className="bx bx-share-alt"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,2)}><i className="bx bxl-whatsapp"></i> Share via Whatsapp </button>
						<button className="dropdown-item" onClick={(e) => handleShareContent(row.id,3)}><i className="bx bx-envelope"></i> Share via Email </button>
					</div>
              	</div>
            )
        },
        {
            name: 'Invoice No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 's.inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 's.inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: false,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt (₹)',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 's.total_amt'
        },
        {
            name: 'Dispatch Stock',
            selector: row => row.dispatch_stock_flag,
            sortable: false,
			center: true,
			cell: (row) => (
				( row.dispatch_stock_flag === '1' ) ? <Common.OverlayTrigger overlay={Common.popoverContent('','Moved to dispatch stock')}><i className='bx bxs-institution'></i></Common.OverlayTrigger> : ''
			)
        }
	];
    if( parseInt(einvoice_status) ) {
		columns.push({
            name: 'IRN',
            selector: row => row.irn,
            sortable: false,
			sortField: 'irn',
			center: true,
			cell: (row) => (
				(row.invoice_type !== 'B2C') ? ( row.irn === null ) ? <Button variant="primary" size="sm" onClick={(e)=>genIrn(row)}>Generate IRN</Button> : ( row.cancel_date === null) ? <Button variant="success" size="sm" onClick={(e)=>viewIrn(row.id)}>View IRN</Button> : <Button variant="danger" size="sm">Cancelled</Button> : ''
			)
        });
	}
	if( parseInt(export_status) ) {
		columns.push({
            name: 'Ship Det',
            selector: row => row.id,
            sortable: false,
			sortField: 'id',
			center: true,
			cell: (row) => (
				(row.ship_bill_no === null && row.exchange_rate !== null && row.exp_port_no !== '') ? <Button variant="warning" size="sm" onClick={(e)=>getExportDataModal(row,0)}>Export</Button> : (row.ship_bill_no !== null && row.exchange_rate !== null && row.exp_port_no !== '') ? <Button variant="success" size="sm" onClick={(e)=>getExportDataModal(row,1)}>View</Button>:''
			)
        });
	}
	columns.push(
		{
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
			sortField: 'created_by'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 's.created_on'
        },
		{
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: true,
			sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 's.updated_on'
        }
	);
	// incomeColl_r
	const cancelColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => ( incomeColl_r === true ? <>
					<div className="dropdown">
					<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
					  <i className="bx bx-dots-vertical-rounded"></i>
					</button>
					<div className="dropdown-menu" >
						<button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id,'copy',1,row.doc_status)}><i className='bx bxs-copy'></i> Copy With Inv No.</button>
					  	<button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id,'copy',0,row.doc_status)}><i className='bx bxs-copy'></i> Copy Without Inv No</button>
					</div>
				  </div>
				</>:<></>
			)
		},
        {
            name: 'Invoice No.',
            selector: row => row.inv_no,
            sortable: true,
			sortField: 'inv_no'
        },
        {
            name: 'Invoice Date',
            selector: row => row.inv_date,
            sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
        },
        {
            name: 'Customer',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name'
        },
        {
            name: 'Total Amt',
            selector: row => row.total_amt,
            sortable: true,
			sortField: 'total_amt'
        },
        {
            name: 'Doc Status',
            selector: row => row.inv_no,
            sortable: false,
			sortField: 'doc_status',
			cell: (row) => (
				(row.doc_status === 'C')?'Cancel':'Old'
			)
        },
        {
            name: 'Cancel Reason',
            selector: row => row.cancel_reason,
            sortable: false,
        },
		{
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
			sortField: 'created_by'
        },
        {
            name: 'Created On',
            selector: row => row.created_on,
            sortable: true,
			sortField: 'created_on'
        },
        {
            name: 'Updated By',
            selector: row => row.updated_by,
            sortable: true,
			sortField: 'updated_by'
        },
        {
            name: 'Updated On',
            selector: row => row.updated_on,
            sortable: true,
			sortField: 'updated_on'
        },
	];

	const draftColumns = [
		{
			name: 'Action',
			button: true,
			cell: (row) => (
				<div className="dropdown">
				<button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				<i className="bx bx-dots-vertical-rounded"></i>
				</button>
				<div className="dropdown-menu" >
					<button className="dropdown-item" onClick={(e) => handleDownloadPdf(row.id,1,row.inv_no)}><i className="bx bxs-download"></i> Download</button>
					<button className="dropdown-item" onClick={(e) => handleCopyInvoice(row.id,'edit',((row.inv_no)?1:0))}><i className='bx bxs-edit'></i> Edit Draft</button>
				</div>
			</div>
			)
		},
		{
			name: 'Invoice No.',
			selector: row => row.inv_no,
			sortable: true,
			sortField: 'inv_no'
		},
		{
			name: 'Invoice Date',
			selector: row => row.inv_date,
			sortable: true,
			sortField: 'inv_date',
			cell: (row) => (
				Common.displayDate(row.inv_date)
			)
		},
		{
			name: 'Customer',
			selector: row => row.party_name,
			sortable: true,
			sortField: 'party_name'
		},
		{
			name: 'Total Amt',
			selector: row => row.total_amt,
			sortable: true,
			sortField: 'total_amt'
		},
		{
			name: 'Created By',
			selector: row => row.created_by,
			sortable: true,
			sortField: 'created_by'
		},
		{
			name: 'Created On',
			selector: row => row.created_on,
			sortable: true,
			sortField: 'created_on'
		},
		{
			name: 'Updated By',
			selector: row => row.updated_by,
			sortable: true,
			sortField: 'updated_by'
		},
		{
			name: 'Updated On',
			selector: row => row.updated_on,
			sortable: true,
			sortField: 'updated_on'
		}
	];

	const genIrn = (row) => {
		if(parseInt(export_status) === 1 && (row.ship_bill_no === null && row.exchange_rate !== null && row.exp_port_no !== '') ){
			Common.toast.error('Update the shipping details to generate IRN');
			return false;
		}
		// if(parseInt(export_status) === 1 && (row.invoice_type === 'EXPWP' || row.invoice_type === 'EXPWOP' || row.invoice_type === 'DEXP') ){

		// }
		Common.showLoader(true);
		Common.axios({
            method: 'post',
            url:props.url+'ajax/gen_irn',
			data: {'id':row.id}
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
				Common.toast.dismiss();
				Common.toast.success(response.data.message);
				window.location.reload(true);
				return false;
            }
			const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.dismiss();
			Common.toast.error(err_html);
        }).catch(error => console.log(error));
	}
	const viewIrn = (id) => {
		Common.showLoader(true);
		Common.axios({
            method: 'get',
            url:props.url+'sales/irn_details/'+localStorage.getItem('fo_profileid')+'/'+id,
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
                setIrnDetails(response.data.data);
				setViewModal(true);
            }
        }).catch(error => console.log(error));
	}
	const getExportDataModal = (row,flag) => {
		setSaleId(row.id);
		setExportFlag(flag);
		if(flag === 1){
			setExpShipbNo(row.ship_bill_no);
			setExpShipbDate(row.ship_bill_date);
		}
		setExportDataModal(true);
	}
//listing option work start here

   /*const handleEmailContent =(val) => {
 let url = props.url+'sales/getData/'+val;
        Common.axios({
            method: 'get',
            url:url,
        }).then(function (response) {
            if(response.data.success){
                var elementHTML =response.data.data;
             //   ReactPDF.renderToStream(<MyDocument />);
                handlePdfGenerate(elementHTML,'email');

            }
        }).catch(error => console.log(error));
   }*/

	const handleDownloadPdf =(val,status,invNum) => {
		Common.showLoader(true);
		let url = props.url+'sales/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
        Common.axios({
            method: 'get',
            url:url,
            // responseType: 'blob',
        }).then(function (response) {
			Common.showLoader(false);
            if(response.data.success){
				window.location.href = response.data.message;
                /* const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
				let currentdate = new Date();
				let timesec = currentdate.getTime().toString().substring(2, 6);
				let fileName = currentdate.getHours() + "_" + currentdate.getMinutes() + "_" + currentdate.getSeconds() + "_" + timesec;
                link.setAttribute('download', invNum+'_'+fileName+'.pdf');
                document.body.appendChild(link);
                link.click(); */
            }
            else{
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
            }
        }).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleMultipleDownloadPdf = (details)=>{
        setSaleId(details.id);
		setMultiDownloadPdfShow(true);
		customInputList.length = 0;
		handleCustomPDFFields();
    }
	const handleCustomPDFFields=()=>{
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		if(allWithClass.length < 5){
        	setCustomInputList(customInputList.concat(<MultiDownloadCustomFields key={customInputList.length} len={customInputList.length}/>));
		}
		else{
			Common.toast.error('Max Custom Fields Reached');
		}
    }
	const handleMultiDownloadPDF = (e)=>{
        e.preventDefault();
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		if(allWithClass.length === 0){
			Common.toast.error('Minimum one custom field is required');
			return false;
		}
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['saleid']      = saleId;
		data['pid']         = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'sales/downloadMultiPDF',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setMultiDownloadPdfShow(false);
                if(response.data.success === 1){
                    window.location.href = response.data.message;
					Common.toast.dismiss();
					return false;
                }else{
					Common.toast.dismiss();
					Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
	const handleClearRows = () => {
		setStateClear({ toggledClearRows: !stateClear.toggledClearRows})
	}
	const handleShareContent =(val,status) => {
		let url = props.url+'sales/getData/'+encodeURIComponent(btoa(val))+'/'+encodeURIComponent(btoa(status));
		Common.axios({
			method: 'get',
			url:url,
		}).then(function (response) {
			if(response.data.success){
				if(parseInt(status) === 2){
					window.open("https://api.whatsapp.com/send?text="+response.data.data.body,"_blank");
				}else{
					window.open("mailto:?subject="+response.data.data.subject+"&body="+response.data.data.body,"_blank");
				}
			}
		}).catch(error => console.log(error));
	}
	const handleChange = (state) => {
		let datas = state.selectedRows;
		let res = [];
		datas.map((val,index) => (
			res[index] = val.id
		))
		setSelectedRows(res);
	};
	const handleBulkDownload = () => {
		Common.showLoader(true);
		let url = props.url+'sales/downloadBulkPDF';
		Common.axios({
			method: 'post',
			url:url,
			data:{id:selectedRows},
			// responseType: 'blob',
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				window.location.href = response.data.message;
				/* const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${'sale_bulkprintpdf_'+Date.now()}.zip`);
				document.body.appendChild(link);
				link.click(); */
				handleClearRows();
			}
			else{
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}
		}).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleDispatchStock = () => {
		Common.showLoader(true);
		let url = props.url+'sales/moveDispatchStock';
		Common.axios({
			method: 'post',
			url:url,
			data:{id:selectedRows},
		}).then(function (response) {
			Common.showLoader(false);
			Common.toast.dismiss();
			if(response.data.success){
				Common.toast.success(response.data.message, {duration: 6000});
				handleClearRows();
				loadDataTable(1,perPage,filterText,dates,sortOrder);
			}
			else{
				Common.toast.error(response.data.message);
			}
		}).catch(error => {console.log(error);Common.showLoader(false);});
	}
	const handleCopyInvoice =(val,action,type,dstatus='C') => {
		if(type === 1 && dstatus === 'O'){
			Common.toast.error('Active Invoice with same number already exist.');
			return false;
		}
		let urlPath = (action === 'copy')?'copy-sale':'edit-draft';
        navigate('/'+ urlPath,
        {
            state: {
                sid: val,
				action: action,
				copy_type: type
            }
        });
    }
	const handleBulkUpload =() => {
		if (localStorage.getItem('fo_subplan') !== 'Enterprise') {
			setAlertModal(true);
			return false;
		}
        navigate('/add-sale-file');
    }
	const handleCancelEinvoice = (details)=>{
        setSaleId(details.id);
		setIrnNo(details.irn);
		setInvNo(details.inv_no);
        setIrnCancelDate(details.cancel_date);
        setEinvCancelRemarks('');
        setEinvCancelError('');
        if(details.cancel_error_message){
            setEinvCancelError(JSON.parse(details.cancel_error_message));
        }
		let cancelIrn = 0;
		if((details.irn === null && details.cancel_date === null) || (details.irn !== null && details.cancel_date !== null)){
			cancelIrn = 1;
		}
		Common.axios({
			method: 'get',
			url:props.url+'sales/checkCanceldetails/'+details.id+'/'+cancelIrn,
		}).then(function (response) {
			if(response.data.success){
				setEinvCancelShow(true);
			}else{
				setCancelCheckMessage(response.data.message);
				setCancelCheckShow(true);
			}
		}).catch(error => console.log(error));
    }
	const handleCancelEINV = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['saleid']      = saleId;
		data['pid']         = localStorage.getItem('fo_profileid');
		data['can_type']    = can_type;

		Common.axios({
            method: 'post',
            url:props.url+'ajax/cancelEinvoice',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setEinvCancelShow(false);
				Common.toast.dismiss();
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					window.location.reload(true);
					return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
	const handleExportData = (e)=>{
        e.preventDefault();
        Common.showLoader(true);
        let formData = new FormData(e.target);
        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
        data['saleid']      = saleId;

		Common.axios({
            method: 'post',
            url:props.url+'sale/update_export_data',
            data: data,
        }).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setExportDataModal(false);
				Common.toast.dismiss();
                if(response.data.success === 1){
                    Common.toast.success(response.data.message);
					loadDataTable(1,perPage,filterText,dates,sortOrder);
					return false;
                }else{
                    Common.toast.error(response.data.message);
                }
                return false;
			}
            const err_html = () => (
				Common.parse(response.data.message)
			);
			Common.toast.error(err_html);
            Common.showLoader(false);
        }).catch(error => console.log(error));
    }
	const handleRedirection = () => {
		if (props.label === 'pricing_plan') {
			window.location.reload();
		} else {
			navigate("/pricing-plan");
			return false;
		}
	}
	const contextActions = React.useMemo(() => {
		return (
			<div>
				<Button variant="info" size="sm" key="Dispatch Stock" onClick={handleDispatchStock}>
					Dispatch Stock
				</Button>&nbsp;&nbsp;
				<Button variant="primary" size="sm" key="Bulk-download" onClick={handleBulkDownload}>
					Bulk download
				</Button>
			</div>
		);
	}, [selectedRows]);

    const [showActiveDiv,setShowActiveDiv]     = React.useState(true);
    const [showCancelledDiv,setShowCancelledDiv] = React.useState(false);
    const [showDraftDiv,setShowDraftDiv] = React.useState(false);
	function TabGroup() {
		const handleTab = penColChecked => {
			setActCan(penColChecked);
			loadDataTable(1,perPage,filterText,dates,sortOrder,penColChecked);
			if(penColChecked === "Cancelled"){
				setShowActiveDiv(false);
				setShowCancelledDiv(true);
				setShowDraftDiv(false);
			}
			else if(penColChecked === "Draft"){
				setShowActiveDiv(false);
				setShowCancelledDiv(false);
				setShowDraftDiv(true);
			}
			else{
				setShowActiveDiv(true);
				setShowCancelledDiv(false);
				setShowDraftDiv(false);
			}
		};
		return (
			<>
			<ButtonGroup className="tabWithInfo">
				{invoiceTabTypes.map(type => (
				<Tab
					key={type}
					active={activeTab === type}
					onClick={() => handleTab(type)}
				>
					{(totalDataCnt[type] !== '') ? type + ' ('+totalDataCnt[type]+')' : type}
					<div className="tabAdditionalInfo">
						Cust.# {totCustomer[type]} | Tot. Amt.(₹) {Common.numToWords(totInvAmt[type])}
					</div>
				</Tab>
				))}
			</ButtonGroup>
			</>
		);
	}
    return (
    <>
		<Modal size={"lg"} show={viewModal} onHide={handleViewClose} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>IRN Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Common.Row>
					<Common.Col><strong>AckNo : </strong> {irn_details.ackno}</Common.Col>
					<Common.Col><strong>AckDt : </strong> {irn_details.ackdt}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col className="text-break"><strong>Irn : </strong> {irn_details.irn}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col className="text-break"><strong>SignedInvoice: </strong> {irn_details.signedinvoice}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col className="text-break"><strong>SignedQrCode: </strong> {irn_details.signedqrcode}</Common.Col>
				</Common.Row>
				<Common.Row className="mt-1">
					<Common.Col className="text-break"><strong>Status: </strong> {irn_details.status}</Common.Col>
				</Common.Row>
			</Modal.Body>
		</Modal>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout label="sales-listing" site_logo={props.site_logo} site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Income & Collections /</span> Created Bills
                                <span className="dropdown">
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='text-danger bx bxl-youtube bx-md'></i>
                                    </button>
                                    <div className="dropdown-menu" >
                                        <a href="https://youtu.be/aFC_dAnPKvs" className="dropdown-item" target="_blank" rel="noreferrer"><span>Invoice creation via form</span></a>
										<a href="https://youtu.be/mrRST0f2wRA" className="dropdown-item" target="_blank" rel="noreferrer"><span>IRN/Einvoice generation</span></a>
										<a href="https://youtu.be/enOCR8pBVdY" className="dropdown-item" target="_blank" rel="noreferrer"><span>Edit/Amend invoice</span></a>
										<a href="https://youtu.be/aqeMs57AGFg" className="dropdown-item" target="_blank" rel="noreferrer"><span>Export invoice creation</span></a>
										<a href="https://youtu.be/MQm7uec5Nec" className="dropdown-item" target="_blank" rel="noreferrer"><span>Bulk upload invoices</span></a>
                                    </div>
								</span>
                            </h4>
                            <div className="row">
								<div className="pb-3">
									<div className="col-md-12">
										<TabGroup/>
									</div>
								</div>
                                <div id="sdt_cont" className="col-sm-12">
									{showActiveDiv && (<>
										<div className="row">
											<div className="col-md-4 col-lg-5 col-12 mb-1 text-center text-md-start">
												<button className={"btn btn-sm btn-info me-1 "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i><span className="hidden-phone">Export CSV</span></button>
												{incomeColl_w && (<>
													<div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
														<button type="button" className="btn btn-primary" disabled><i className="bx bx-plus"></i></button>
														<Common.Link to="/add-sale" className="btn btn-primary"> <i className='bx bxs-keyboard'></i><span className="">Form</span></Common.Link>
														{/* <Common.Link to="/add-sale-file" className="btn btn-primary"> <i className='bx bxs-file'></i><span className="">Bulk Upload</span></Common.Link> */}
														<button className="btn btn-primary" onClick={(e) => handleBulkUpload()}> <i className='bx bxs-file'></i><span className="">Bulk Upload</span></button>
													</div>													
												</>)}
											</div>
											<div className="col-md-4 col-lg-3 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Invoice Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
										</div>
										<DTE.DataTable title="Bills" selectableRows selectableRowsHighlight selectableRowsVisibleOnly onSelectedRowsChange={handleChange} className="sale_dataTables rdt_AdjDrp" columns={columns} data={sales} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} contextActions={contextActions} clearSelectedRows={stateClear.toggledClearRows} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
									{showCancelledDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 listing-tabs mb-1">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											</div>
											<div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Invoice Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
										</div>
										<DTE.DataTable title="Bills" className="dataTables_wrapper rdt_AdjDrp" columns={cancelColumns} data={sales} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
									{showDraftDiv && (<>
										<div className="row">
											<div className="col-md-3 col-12 listing-tabs mb-1">
												<button className={"btn btn-sm btn-info "+exportBtnClass} onClick={(e)=>setExportModal(true)}><i className="bx bx-download"></i> <span className="hidden-phone">Export CSV</span></button>
											</div>
											<div className="col-md-5 col-12 text-center mb-1">
												<DateRangePicker
													initialSettings={DTE.DatePickerPredefined(moment,start,end)}
													onCallback={setDatePicker}>
													<div className="input-group input-group-sm">
														<span title="Date Range" className="input-group-text"><i className='bx bxs-calendar'></i></span>
														<div id="reportrange" className="form-control form-control-sm" style={{ cursor: 'pointer',padding:'5px 0px',fontSize:'small'}}>
															<i className="fa fa-calendar"></i>&nbsp;<span>{label}</span> <i className="fa fa-caret-down"></i>
														</div>
													</div>
												</DateRangePicker>
												<small>Date range based on Invoice Date</small>
											</div>
											<div className="col-md-4 col-12 text-center text-md-end mb-1">
												<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Invoice Number, Customer')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
												<DTE.FilterComponent placeholder="Search" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
											</div>
										</div>
										<DTE.DataTable title="Bills" className="dataTables_wrapper rdt_AdjDrp" columns={draftColumns} data={sales} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} onSort={handleSort} sortServer paginationResetDefaultPage={dt_load}/>
									</>)}
                                </div>
                            </div>

                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size='md' show={einvCancelShow} onHide={()=>setEinvCancelShow(false)} aria-labelledby="Invoice-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Invoice-Cancel-modal">Cancel Invoice ({invNo})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addEwbCancelFrm" onSubmit={handleCancelEINV} autoComplete="off">
					<div className="row">
                        {einvCancelError && <>
                            <div className="col-sm-12">
                                <table className="table table-sm table-bordered dispatch_eway_error">
                                    <thead>
                                        <tr>
                                            <th>Error Code</th>
											<th>Error Message</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                    {einvCancelError.map((val,index) => (
                                        <tr key={'error'+index}>
                                            <td>{val.ErrorCode}</td>
											<td>{val.ErrorMessage}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>}
						<div className="col-sm-12">
                            <label htmlFor="cancel-reason" className="form-label">Cancel Reason <span className="text-danger">*</span></label>
                            <Select id="cancel-reason" placeholder="Select Cancel Reason" name='cancel_reason' options={Common.ewayBillCancelReason} required/>
						</div>
                        <div className="col-sm-12">
                            <label htmlFor="cancel-remarks" className="form-label">Cancel Remarks <span className="text-danger">*</span></label>
                            <div className="input-group input-group-merge">
                                <textarea rows="2" id="cancel-remarks" name="cancel_remarks" className="form-control" placeholder="Cancel Remarks" aria-label="CancelRemarks" aria-describedby="Remarks" value={einvCancelRemarks} onChange={(e) => setEinvCancelRemarks(e.target.value)} required></textarea>
                            </div>
						</div>
					</div>
					<div className="row ">
                        <div className="col-sm-12 mt-2">
							<p className="smaller_font text-danger">Note: Any PO deduction or Inventory deduction done during invoice generation will be reversed.</p>
						</div>
					</div>
					<div className="row ">
						<div className="col-sm-12 text-center mt-1">
							<button type='submit' className="btn btn-sm btn-primary" disabled={(irnNo === null && irnCancelDate === null) || (irnNo !== null && irnCancelDate !== null)?true:false} onClick = {function(e){ setCanType(0);}}><i className='bx bx-message-square-x' ></i>&nbsp;Cancel IRN</button>&nbsp;&nbsp;
							<button type='submit' className="btn btn-sm btn-danger" disabled={(irnNo !== null && irnCancelDate === null)?true:false} onClick = {function(e){ setCanType(1);}}><i className='bx bx-message-square-x' ></i>&nbsp;Cancel Invoice</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={cancelCheckShow} size='sm' onHide={()=>setCancelCheckShow(false)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Cancel Invoice</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>{cancelCheckMessage}</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setCancelCheckShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal show={exportModal} onHide={()=>setExportModal(false)} aria-labelledby="export-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="export-modal">Basic Export</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="my-1">
					Select options below to add more details to your report
					<div className="my-3">
						<div className="row justify-content-md-center">
							<div className="col-sm-4">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" id="itemAll" onChange={checkAllItems}/>
									<label className="form-check-label" htmlFor="itemAll"> Select All </label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="primary" id="itemPrimary" checked={dlParams.includes('primary')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemPrimary"> All primary invoice details </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="item" id="itemdataCheck" checked={dlParams.includes('item')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataCheck"> Item Data </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="terms" id="itemdataInvTerms" checked={dlParams.includes('terms')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataInvTerms"> Invoice Terms </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="dispatch" id="itemdataDispatch" checked={dlParams.includes('dispatch')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataDispatch"> Dispatch From Details </label>
								</div>
							</div>
							<div className="col-md">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="shipto" id="itemdataShip" checked={dlParams.includes('shipto')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataShip"> Ship To Details </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="export" id="itemdataExport" checked={dlParams.includes('export')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataExport"> Export Details </label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="custom" id="itemdataCustom" checked={dlParams.includes('custom')} disabled={checkAll} onChange={setDownloadParams}/>
									<label className="form-check-label" htmlFor="itemdataCustom"> Custom Invoice Fields </label>
								</div>
							</div>
						</div>
					</div>
					<div className="alert alert-info" role="alert">
						<i className='bx bxs-info-square'></i> Segments having multiple entries for a single transaction record will get displayed in multiple rows
					</div>
					<div className="text-center">
						<button onClick={handleExportCSV} className="btn btn-sm btn-primary">Submit</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		<Modal size='md' show={exportDataModal} onHide={()=>setExportDataModal(false)} aria-labelledby="Export-data-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="Export-data-modal">Export Data</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addExportData" onSubmit={handleExportData} autoComplete="off">
					{exportFlag === 0 &&<>
						<div className="row">
							<div className="col-sm-12">
								<label htmlFor="frmExpShippingBillNumber" className="form-label">Shipping Bill Number <span className="text-danger">*</span></label>
								<input name="expShipbNo" type="text" className="form-control" id="frmExpShippingBillNumber" placeholder="Enter Shipping Bill Number" value={expShipbNo} onChange={(e)=>setExpShipbNo(e.target.value)}/>
							</div>
							<div className="col-sm-12">
								<label htmlFor="frmExpShipbDate" className="form-label">Shipping Bill Date <span className="text-danger">*</span></label>
								<div className="input-group input-group-merge">
									<DatePicker name="expShipbDate" data-testid="frmExpShipbDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setExpShipbDate} value={expShipbDate} />
								</div>
							</div>
						</div>						
						<div className="row ">
							<div className="col-sm-12 text-center mt-1">
								<button type='submit' className="btn btn-sm btn-success"><i className='bx bx-save' ></i>&nbsp;Save</button>
							</div>
						</div>
					</>}
					{exportFlag === 1 &&<>
						<div className="row">
							<div className="col-sm-12">
								<p>Shipping Bill Number : {expShipbNo}</p>
							</div>
							<div className="col-sm-12">
								<p>Shipping Bill Date : {expShipbDate}</p>
							</div>
						</div>
					</>}
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={alertModal} size='sm' onHide={()=>setAlertModal(false)} aria-labelledby="alert-modal" backdrop="static" keyboard={false} centered>
			<Modal.Body>
				<div className="my-1">
					<b >This option is available only in Enterprise plan. Pl visit the <button className='border-0 bg-white p-0 text-primary cursor-pointer' onClick={handleRedirection}>Pricing Page</button> for details</b>
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={multiDownloadPdfShow} size='md' onHide={()=>setMultiDownloadPdfShow(false)} aria-labelledby="Multiple-Pdf-Download-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Multiple Download PDF</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="generateMultiDownload" onSubmit={handleMultiDownloadPDF} autoComplete="off">
					<div className='row'>
						<div className='col-md-1'></div>
						<div className='col-md-10'>
							<div id="customInvFields">
								{customInputList}
								<div className="mb-3 text-center">
									<button type="button" className="btn btn-sm btn-primary me-2" onClick={handleCustomPDFFields}><i className='bx bx-plus'></i> Add Fields</button>
								</div>
							</div>
						</div>
						<div className='col-md-1'></div>
					</div>
					<div className="row">
						<div className="col-md-12 text-right">
							<button type="submit" className="btn btn-sm btn-success"><i className="bx bxs-download"></i> Generate PDF</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
    </>
    );
}