import React  from "react";
import {useParams} from "react-router-dom";
import * as Common from '../common/ImportUserEssentials';
export default function Approve(props){
	const { hash } = useParams();
	window.location.href =  props.link+'store/'+hash;
	return false;
	const [storeDetails,setStoreDetails] = React.useState({'name':'','logo':'','pstatus':'','parent':'','sub':''});
	const navigate = Common.useNavigate();
    React.useEffect(() => {
		Common.secureLocalStorage.removeItem('fm_redirect');
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'martuser/approveQR',
			data: {hash:hash}
		}).then(function (response) {
			Common.showLoader(false);
			if( response.data.success ){
				Common.toast.success(response.data.message);
				navigate('/home');
				return false;
			}
			martDetails(hash);
		}).catch(error => console.log(error));
		const martDetails = (hash) => {
			Common.axios({
				method: 'post',
				url:props.url+'mart/detailsForGuest',
				data: {hash:hash}
			}).then(function (response) {
				if( response.data.success ){
					if( response.data.data.pstatus === "0" ){
						Common.toast.dismiss();
						Common.toast.error("Merchant isn't online. Contact merchant directly.");
						navigate("/home");
						return false;
					}
					document.title = response.data.data.name+' | '+props.site_name
					setStoreDetails(response.data.data);
					return false;
				}
				Common.toast.error(response.data.message);
				navigate('/login');
			}).catch(error => console.log(error));
		}
	}, [props,hash]);
	const goToPage = (page) => {
		Common.destroySession();
		Common.secureLocalStorage.setItem('fm_redirect','/approve/'+hash);
		if( page === 'signin' ){
			navigate("/login");
			return false;
		}
		if( page === 'signup' ){
			navigate("/register");
			return false;
		}
	}
	return (
		<div className="container-xxl">
			<div className="authentication-wrapper authentication-basic container-p-y">
				<div className="authentication-inner">
					<div className="card">
						<div className="card-body">
							<div className="text-center pb-5">
								{storeDetails.logo !== null ?
								<img height="150" width="auto" className="rounded-circle p-1" src={props.url+"public/assets/img/profile_logo/"+storeDetails.logo} alt={storeDetails.name+" Logo"}/>
								:
								<img height="150" width="auto" className="p-1" src={props.link+"/assets/img/default_store.png"} alt={storeDetails.name+" Logo"}/>
								}
								<div>
									<div className="">{storeDetails.name}</div>
									<small className="text-primary">{storeDetails.parent +' - '+storeDetails.sub}</small>
								</div>
							</div>
							<h4 className="mb-2">Don’t have an account yet? 🔒</h4>
							<p className="mb-5">No worries!<br/><br/>Registering is quick and easy. Join our platform today!<br/><br/>Already a member? Simply sign in to continue.</p>
							<div className="text-center">
								<button className="btn btn-primary me-1" onClick={()=>goToPage('signin')}><i className='bx bx-log-in-circle'></i> Sign In</button>
								<button className="btn btn-info ms-1" onClick={()=>goToPage('signup')}><i className='bx bx-user-plus'></i> Sign Up</button>
							</div>
						</div>
					</div>
				</div>
			</div>
      </div>
	);
}