import React,{useState,useEffect,useRef} from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import * as Common from '../common/ImportUserEssentials';
import '../styles/page-misc.css';
// import '../styles/icai.css';
import Modal from 'react-bootstrap/Modal';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import PureCounter from "@srexi/purecounterjs";
import TagManager from 'react-gtm-module'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReCAPTCHA from "react-google-recaptcha";
import OtpInput from 'react-otp-input';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function LandingPartner(props){
	const [loginModal,setLoginModal] 		  = useState(false);
	const [mobile, setMobile] 				  = useState('');
	const [mobileDisabled, setMobileDisabled] = useState('');
	const [email, setEmail] 				  = useState('');
	const [emailDisabled, setEmailDisabled]   = useState('');
	const [password, setPassword] 			  = useState('');
	const [clicked, setClicked] 			  = useState('');
	const [errorMessage,setError] 			  = useState('');
	const captchaRef 						  = useRef(null);
	const [otp, setOtp] 					  = useState('');
	const [seconds, setSeconds] 			  = useState(0);
	const [slideDown, setSlideDown] 		  = useState('d-block');
	const [slideUp, setSlideUp] 			  = useState('d-none');
	const [uid, setUid] 					  = useState('');
	const [mobileData, setMobileData] 		  = useState('');
	const [loginType, setLoginType] 		  = useState('');
	const [orgName, setOrgName] 			  = useState('');
	const [personName, setPersonName] 		  = useState('');
	const [orgMobile, setOrgMobile] 		  = useState('');
	const [requestModel, setRequestModel] 	  = useState(false);

    useEffect(() => {
		document.title = 'Partners';
		AOS.init();
		Common.axios({
			method: 'get',
			url: props.url+'partners/getPartner',
		}).then(function (response) {
			if( response.data.success ){
				if(response.data.data){
					let account_type = response.data.data.type;
					localStorage.setItem('fo_partnerType',account_type);
					window.location.href = '/partners';
					return false;
				}
			}else{
				return false;
			}
		}).catch(error => console.log(error));
		
		new PureCounter();
		GLightbox({
			selector: '.glightbox'
		});
		const hostname = window.location.hostname;
		if( hostname === 'www.fynone.com' ){
			const tagManagerArgs = {
				gtmId: 'G-RF6E5H5MTZ'
			}
	 
			TagManager.initialize(tagManagerArgs);
		}
		const script = document.createElement('script');

        script.src = 'landing/js/main.js';
        script.async = true;

        document.body.appendChild(script);
		const sec_interval = setInterval(() => {
			if (seconds > 0) {
			  setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				clearInterval(sec_interval);
			}
		}, 1000);
		return () => {
            document.body.removeChild(script);
			clearInterval(sec_interval);
        };
	}, [props,seconds]);

	const switchLogin=(e,v)=>{
		if( v === 'm' ){
			e = e.replace(/[^0-9\d]/ig, "");
			setMobile(e);
			if( e !== '' ){
				setEmailDisabled('collapse fade');
			} else {
				setEmailDisabled('');
			}
		}
		if( v === 'e' ){
			setEmail(e);
			if( e !== '' ){
				setMobileDisabled('collapse fade');
			} else {
				setMobileDisabled('');
			}
		}
	}
	const handleClick=(e)=>{
		e.preventDefault();
		if(document.getElementById('password').getAttribute('type')==='text'){
			document.getElementById('password').setAttribute('type', 'password');
			setClicked('bx bx-hide');
		}else if(document.getElementById('password').getAttribute('type')==='password'){
			document.getElementById('password').setAttribute('type', 'text');
			setClicked('bx bx-show');
		}
	}

	const handleSubmit =(e) => {
		e.preventDefault();
		const token = captchaRef.current.getValue();
		Common.axios({
			method: 'post',
			url: props.url+'login/submit',
			data: {
				token:token,mobile:Common.AES.encrypt(mobile.toString(), props.site_key).toString(),email: Common.AES.encrypt(email, props.site_key).toString(),password:Common.AES.encrypt(password, props.site_key).toString(),login_type:Common.AES.encrypt('partnerlogin', props.site_key).toString()
			},
		}).then(function (response) {
			if(response.data.success){
				if( mobile !== '' ){
					setSlideDown('d-none');
					setSlideUp('d-block');
					setUid(response.data.id);
					setMobileData(mobile.slice(-4));
					setLoginType('partnerlogin');
					setSeconds(30);
					return false;
				}
				localStorage.setItem('fo_loggedin', response.data.id);
				getAccountType();
			}else{
				setError(response.data.message);
				setTimeout(() => {
					setError('');
				}, 5000);
			}
		}).catch(error => console.log(error));
		captchaRef.current.reset();
	}
	const handleFormSubmit = (e) => {
		e.preventDefault();
		const token = captchaRef.current.getValue();
        let formData = new FormData(e.target);
		let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}
		data['token'] = token;
		Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'partners/requestEmail',
			data: data,
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success === 1){
				setRequestModel(true);
				return false;
			}else{
				Common.toast.error(response.data.message);
				return false;
			}
		}).catch(error => console.log(error));
	}
	const getAccountType = () => {
		Common.axios({
			method: 'get',
			url: props.url+'partners/getPartner',
		}).then(function (response) {
			if( response.data.success ){
                if(response.data.data){
                    let account_type = response.data.data.type;
                    localStorage.setItem('fo_partnerType',account_type);
					window.location.href = "partners";
                    return false;
                }else{
                    Common.destroySession();
					setError('Credentials not found. Pl fill the Request Callback form if you are a new partner');
					setTimeout(() => {
						setError('');
					}, 5000);
                    return false;
                }
			}else{
                Common.toast.error(response.data.message);
            }
		}).catch(error => console.log(error));
	}
	const setOtpValue = (val) => {
		setOtp(Common.acceptOnlyNumbers(val))
	}
	const submitOTP = () => {
		if( otp.length !== 6 ){
			setError('Invalid OTP entered.');
			return false;
		}
        Common.axios({
            method: 'post',
            url:props.url+'login/mobile_otp',
            data: {
				otp: Common.AES.encrypt(otp.toString(), props.site_key).toString(),uid:Common.AES.encrypt(uid.toString(), props.site_key).toString(),type:Common.AES.encrypt(loginType.toString(), props.site_key).toString()
			},
        }).then(function (response) {
			if(response.data.success){
				localStorage.setItem('fo_loggedin', uid);
				getAccountType();
				// window.location.href = "partners";
				return false;
			}else{
				setError(response.data.message);
				setOtpValue('');
				setTimeout(() => {
					setError('');
				}, 5000);
			}
        }).catch(error => console.log(error));
	}
	const handleOTPSubmit =(e) => {
        e.preventDefault();
		submitOTP();
    }
	const slide =(param) => {
    	if (param === 'down') {
	      	setSlideDown('d-none');
	      	setSlideUp('d-block');
    	} else {
	      	setSlideDown('d-block');
	      	setSlideUp('d-none');
    	}
    }
	if(otp.length === 6){
		submitOTP();
	}
	return (
		<React.Fragment>
			<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Dosis:300,400,500,,600,700,700i|Lato:300,300i,400,400i,700,700i" rel="stylesheet" />
			{/* <div className="container-xxl container-p-y"> */}
				<header id="header" className="fixed-top">
					<div className="container d-flex align-items-center justify-content-between">
						<a href="/" className="logo"><img alt="FYNONE Logo" height="65" width="auto" src={props.site_logo}></img></a>
						<nav id="navbar" className="navbar">
							<ul>
								<li><a className="nav-link scrollto active" href="#hero" style={{'overflowAnchor': 'none'}}>Home</a></li>
								<li><a className="nav-link scrollto" href="#about" style={{'overflowAnchor': 'none'}}>Benefits</a></li>
								<li><a className="nav-link scrollto" href="#services" style={{'overflowAnchor': 'none'}}>Modules</a></li>
								<li><a className="nav-link scrollto " href="#counts" style={{'overflowAnchor': 'none'}}>About Us</a></li>
								<li><a className="nav-link scrollto" href="#gallery" style={{'overflowAnchor': 'none'}}>Mobile App</a></li>
								<li><a className="nav-link scrollto" href="#contact" style={{'overflowAnchor': 'none'}}>Contact</a></li>
								<li><a className="nav-link scrollto" href="#hero" onClick={(e)=>setLoginModal(true)} style={{'overflowAnchor': 'none'}}>Partner Login</a></li>
							</ul>
							<i className="bi bi-list mobile-nav-toggle"></i>
						</nav>
					</div>
				</header>
				<section id="hero" className="d-flex">
					<div className="container">
						<div className="row pt-4">
							<div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 d-flex flex-column justify-content-center">
								<h1>Discover the Super App for Every Business</h1>
								<h2>FynOne is Simple, Collaborative & Compliance driven</h2>
							</div>
							<div className="col-lg-2 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"></div>
							<div className="col-lg-4 order-3 order-lg-2 hero-img">
								<div className="card">
									<div className="card-body">
									<p className="ribbon">
										<span className="text">
											<strong className="bold" style={{'fontSize': '20px','margin':'0'}}>Industry leading Partner Commissions</strong><br/>
											<span className="bold" style={{'fontSize':'14px','margin':'0','padding':'0'}}>Upto 70%</span>
										</span>
									</p>
										<form id="formAuthentication" className="mb-3" autoComplete="off" onSubmit={handleFormSubmit}>
											<div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bi bi-building' ></i></span>
													<input type="text" className="form-control" id="org_name" name="org_name" placeholder="* Organization Name" onChange={(e) => setOrgName(Common.acceptOnlyCharsNumbersNoSpace(e.target.value))} value={ orgName } required/>
												</div>
											</div>
											<div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bi bi-file-person' ></i></span>
													<input type="text" className="form-control" id="person_name" name="person_name" placeholder="* Contact Person Name" onChange={(e) => setPersonName(Common.acceptOnlyCharsNumbersNoSpace(e.target.value))} value={ personName } required/>
												</div>
											</div>
											<div className="mb-3">
												<div className="input-group input-group-merge">
													<span className="input-group-text"><i className='bx bx-hash' ></i></span>
													<input type="text" className="form-control" maxLength="10" id="org_mobile" name="org_mobile" placeholder="* Mobile Number" onChange={(e) => setOrgMobile(Common.acceptOnlyDecimal(e.target.value))} value={ orgMobile } required/>
												</div>
											</div>
											
											<div className="my-2">
												<ReCAPTCHA className="g-recaptcha" ref={captchaRef} sitekey={props.recaptcha_key} />
											</div>
											<div className="mb-3">
												<div className="form-check">
													<input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" required/>
													<label className="form-check-label" htmlFor="terms-conditions">
														I agree to
														<a href="https://www.fynone.com/tnc" target="_blank" rel="noreferrer"> privacy policy & terms</a>
													</label>
												</div>
											</div>
											<button className="btn btn-primary d-grid w-100" type="submit">Request Callback</button>
										</form>
										{/* <small style={{'textAlign': 'center','color': 'grey'}}>Details will be validated with the ICAI server</small> */}
									</div>
								</div>
								{/* <img src="/icai/img/hero-img.png" className="img-fluid" alt="" /> */}
							</div>
						</div>
					</div>
				</section>

				<main id="main">
					<section id="about" className="about">
						<div className="container">
							<div className="row">
								<div className="col-xl-5 col-lg-6 d-flex justify-content-center video-box align-items-stretch position-relative">
									{/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
								</div>

								<div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
									<h3>Why become a FynOne partner</h3>
									<p>FynOne is from the house of Fynamics, a leading full stack GST solutions provider and a regulator appoved GST Suvidha Provider (<strong>GSP</strong>) serving over 3000+ clients and generated over 80Mn+ Einvoices till date since 2018</p>

									<div className="icon-box">
										<div className="icon"><i className="bx bx-fingerprint"></i></div>
										<h4 className="title">Trusted Brand</h4>
										<p className="description">FynOne is empanelled with ICAI & ICSI for their members as part of member benefits</p>
									</div>

									<div className="icon-box">
										<div className="icon"><i className="bx bx-gift"></i></div>
										<h4 className="title">Highest Commission</h4>
										<p className="description">Unthinkable commission model upto 70% of net sales in 1st year makes it the most rewarding program you have ever seen</p>
									</div>

									<div className="icon-box">
										<div className="icon"><i className="bx bx-atom"></i></div>
										<h4 className="title">Easiest Software</h4>
										<p className="description">Driven by AI and easy UI and with prices starting at just 124 per month with unlimited users, you bet your customers will want to switch to FynOne immediately</p>
									</div>
								</div>
							</div>

						</div>
					</section>


					<section id="services" className="services section-bg">
						<div className="container">

							<div className="section-title">
								<h2>FynOne Modules</h2>
								<p>A complete Business Super App from Online store & eCommerce to CRM & Invoicing to E-invoicing & EWay bill to Accounting & GST fling, its all there!</p>
							</div>

							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-money" style={{'color': '#ff689b'}}></i></div>
										<h4 className="title"><p>eCommerce & Online Store</p></h4>
										<p className="description">Invite customers to Scan, Order, Pay & Track with no commissions or annual commitments</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-calendar" style={{'color': '#e9bf06'}}></i></div>
										<h4 className="title"><p>Billing & E-invoicing</p></h4>
										<p className="description">Create bills (via form or excel upload) with smart auto fills and generate e-invoices in 1 click</p>
									</div>
								</div>

								<div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-store" style={{'color': '#3fcdc7'}}></i></div>
										<h4 className="title"><p>Inventory & Eway bill</p></h4>
										<p className="description">Track stock movement, review item level P&L, generate & manage gatepass & Eway bills</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6" data-wow-delay="0.1s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-credit-card" style={{'color':'#41cf2e'}}></i></div>
										<h4 className="title"><p>Expense Management</p></h4>
										<p className="description">Add purchase invoice details, track advance payments, perform vendor level KYC</p>
									</div>
								</div>

								<div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-globe" style={{'color': '#d6ff22'}}></i></div>
										<h4 className="title"><p>GST Compliance</p></h4>
										<p className="description">Seamlessly file GST returns of R1, R6, ITC04, 3B etc and perform 2B reconciliation for max ITC</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6" data-wow-delay="0.2s">
									<div className="icon-box">
										<div className="icon"><i className="bx bx-time-five" style={{'color': '#4680ff'}}></i></div>
										<h4 className="title"><p>Unlimited Users</p></h4>
										<p className="description">Create Co-Owners & sub users, profile wise and module wise with permission based access</p>
									</div>
								</div>
							</div>

						</div>
					</section>

					<section id="counts" className="counts">
						<div className="container">

							<div className="text-center title">
								<h3>About Fynamics</h3>
								<p>A leading B2B SAAS company serving 3000+ clients across India, ME and SEA</p>
							</div>

							<div className="row counters position-relative">

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="3000" data-purecounter-duration="1" className="purecounter"></span>
									<p>Clients</p>
								</div>

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="3" data-purecounter-duration="1" className="purecounter"></span>
									<p>Countries</p>
								</div>

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="80" data-purecounter-duration="1" className="purecounter"></span>
									<p>E-invoices (in Mn)</p>
								</div>

								<div className="col-lg-3 col-6 text-center">
									<span data-purecounter-start="0" data-purecounter-end="6" data-purecounter-duration="1" className="purecounter"></span>
									<p>Yrs of Service</p>
								</div>

							</div>

						</div>
					</section>

					<section id="clients" className="clients">
						<div className="container">

							<div className="row no-gutters clients-wrap clearfix wow fadeInUp">

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client1.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client2.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client3.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client4.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client5.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client6.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client7.jpg" className="img-fluid" alt="" />
									</div>
								</div>

								<div className="col-lg-3 col-md-4 col-6">
									<div className="client-logo">
										<img src="icai/img/clients/client8.png" className="img-fluid" alt="" />
									</div>
								</div>

							</div>

						</div>
					</section>

					<section id="testimonials" className="testimonials">
						<div className="container position-relative" data-aos="fade-up">
							<div className="row justify-content-center">
								<div className="testimonial-item">
									<h3>Data Security at its Core</h3>
									<p>Infosec team & senior management with decades of hands-on experience and holding professional certifications like CISA, CISSP, CISM, ITIL etc. All our licenses have been awarded only after a thorough review and rigorous scrutiny of our systems and applications by the regulator.</p>
									<img src="icai/img/security.png" alt="" height="100" />
								</div>
							</div>

						</div>
					</section>


					<section id="gallery" className="gallery">
						<div className="container">

							<div className="section-title">
								<h2>Mobile App</h2>
								<p>FynOne Mobile App allows you to access the data anywhere, anytime and on the go. Its free and is auto synced with everything that happens on the web. Download the app from the <a href="https://play.google.com/store/apps/details?id=com.fynamics.fynone" rel="noreferrer" target="_blank">Playstore</a> today</p>
							</div>

							<div className="row no-gutters">

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery1.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery1.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery2.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery2.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery3.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery3.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery4.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery4.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery5.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery5.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery6.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery6.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery7.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery7.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

								<div className="col-lg-3 col-md-4">
									<div className="gallery-item">
										{/* <a href="icai/img/gallery/gallery8.png" className="galleery-lightbox" data-gallery="gallery-item"> */}
											<img src="icai/img/gallery/gallery8.png" alt="" className="img-fluid" />
										{/* </a> */}
									</div>
								</div>

							</div>

						</div>
					</section>

					<section id="contact" className="contact">
						<div className="container">

							<div className="section-title">
								<h2>Contact</h2>
								<p>Email, Call, WhatsApp, Message or feel free to even come over to our Office! Our coordinates are as below:</p>
							</div>
						</div>

						<div className="container">

							<div className="row mt-5">

								<div className="col-lg-4">
									<div className="info">
										<div className="address">
											<i className="ri-map-pin-line"></i>
											<h4>Location:</h4>
											<p>#302, 3rd Floor, Margosa Plaza
												<br />Malleswaram, Bangalore, India - 560055
											</p>
										</div>

										<div className="email">
											<i className="ri-mail-line"></i>
											<h4>Email:</h4>
											<p>support@fynone.com</p>
										</div>

										<div className="phone">
											<i className="ri-phone-line"></i>
											<h4>Call:</h4>
											<p>+91 96633 55662</p>
										</div>

									</div>

								</div>

								<div className="col-lg-8 mt-5 mt-lg-0">

									<iframe title="FynOne Map" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3887.451328850033!2d77.56653527390588!3d13.006905914105582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x3bae173cde1e370d%3A0x81ec7113090c296c!2sFynamics%20Techno%20Solutions%20Pvt.%20Ltd.%2C%20No.%20202%2C%20II%20Floor%2C%20Spearhead%20Towers%2C%20%2350%2C%2056%2C%20Margosa%20Rd%2C%20Malleshwaram%2C%20Bengaluru%2C%20Karnataka%20560055!3m2!1d13.0069007!2d77.5691102!5e0!3m2!1sen!2sin!4v1685015828328!5m2!1sen!2sin" style={{'border':'0', 'width': '100%', 'height': '350px'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

								</div>

							</div>

						</div>
					</section>

				</main>

				<footer id="footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">

								<div className="col-lg-3 col-md-6 footer-contact">
									<h3>FYN ONE</h3>
									<h4>The Super App for Every Business</h4><br />
									<p>
										FYN ONE is part of the FinTech suite at <a href="https://www.fynamics.tech/" target="_blank" rel="noreferrer">Fynamics</a>
									</p>
								</div>

								<div className="col-lg-3 col-md-6 footer-links">

								</div>

								<div className="col-lg-3 col-md-6 footer-links">
									<h4>Useful Links</h4>
									<ul>
										<li><a className="bx bx-chevron-right" href="#hero" style={{'overflowAnchor': 'none'}}>Home</a></li>
										<li><a className="bx bx-chevron-right" href="#about" style={{'overflowAnchor': 'none'}}>Benefits</a></li>
										<li><a className="bx bx-chevron-right" href="#services" style={{'overflowAnchor': 'none'}}>Modules</a></li>
										<li><a className="bx bx-chevron-right" href="#counts" style={{'overflowAnchor': 'none'}}>About Us</a></li>
										<li><a className="bx bx-chevron-right" href="#gallery" style={{'overflowAnchor': 'none'}}>Mobile App</a></li>
										<li><a className="bx bx-chevron-right" href="#contact" style={{'overflowAnchor': 'none'}}>Contact</a></li>
									</ul>
								</div>

								<div className="col-lg-3 col-md-6 footer-links">
									<h4>Stay Connected</h4>
									<p>Follow us for the latest updates</p>
									<div className="social-links mt-3">
										<a href="https://twitter.com/Fynone_official" rel="noreferrer" target="_blank" className="twitter"><i className="bx bxl-twitter"></i></a>
										<a href="https://www.facebook.com/profile.php?id=100094403341519" rel="noreferrer" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
										<a href="https://instagram.com/fynone_official?igshid=NGExMmI2YTkyZg==" rel="noreferrer" target="_blank" className="instagram"><i className="bx bxl-instagram"></i></a>
										<a href="https://www.youtube.com/channel/UCcgwyw_fdBIFBDArgbnEJjw" rel="noreferrer" target="_blank" className="youtube"><i className="bx bxl-youtube"></i></a>
										<a href="https://www.linkedin.com/in/fyn-one-14660a281/" rel="noreferrer" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
									</div>
								</div>

							</div>
						</div>
					</div>
				</footer>
			{/* </div> */}
			<Modal id="partner_login" show={loginModal} onHide={()=>setLoginModal(false)} aria-labelledby="login-modal" backdrop="static" keyboard={false} centered>
				<Modal.Header closeButton>
					<Modal.Title id="login-modal">Login</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="my-1">
						<div id="slide-div" className={slideDown.toString()+' mx-4'}>
							{errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
							{errorMessage}</div>)}
							<form id="formAuthentication" className="mb-3" autoComplete="off" onSubmit={handleSubmit}>
								<div className={"mb-3 "+mobileDisabled}>
									<label htmlFor="mobile" className="form-label">Mobile</label>
									<input tabIndex="1" type="text" className="form-control" id="mobile" name="mobile" value={mobile} placeholder="Enter mobile" maxLength="10" autoFocus onChange={(e) => switchLogin(e.target.value,'m')} inputMode="numeric"/>
								</div>
								<div className={"divider "+mobileDisabled+' '+emailDisabled}><div className="divider-text text-center">OR</div></div>
								<div className={"mb-3 "+emailDisabled}>
									<label htmlFor="email" className="form-label">Email</label>
									<input tabIndex="2" type="text" className="form-control" id="email" name="email" value={email} placeholder="Enter your email"  onChange={(e) => switchLogin(e.target.value,'e')}/>
								</div>
								<div className={"mb-3 "+emailDisabled}>
									<div className="d-flex justify-content-between">
										<label className="form-label" htmlFor="password">Password</label>
										{/* <Link to="/forgot_password">
											<small>Forgot Password?</small>
										</Link> */}
									</div>
									<div className="input-group input-group-merge">
										<input tabIndex="3" type="password" id="password" className="form-control" name="password"  value={password} placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" onChange={(e) => setPassword(e.target.value)}/>
										<span className="input-group-text cursor-pointer"><i className={clicked || 'bx bx-hide' }  onClick={handleClick}></i></span>
									</div>
									<div className="my-2">
										<ReCAPTCHA className="g-recaptcha" ref={captchaRef} sitekey={props.recaptcha_key} />
									</div>
								</div>
								<div style={{width:'35%',display:'table',margin:'0 auto'}}>
									<button tabIndex="5" className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
								</div>
							</form>
						</div>
						<div id="slide-div-down" className={slideUp.toString()}>
							<div className="text-center mb-3">
								<h6>Please enter the one time password <br/> to verify your account</h6>
								<div> <span>A code has been sent to</span> <small>******<span>{mobileData}</span></small> </div>
								{errorMessage && (<div className="alert alert-danger alert-dismissible" role="alert">
								{errorMessage}</div>)}
								<form autoComplete="off" onSubmit={handleOTPSubmit}>
									<div className="inputs d-flex flex-row justify-content-center mt-2">
									<OtpInput value={otp} onChange={setOtpValue} numInputs={6} shouldAutoFocus={true} inputStyle = {'otpInput m-2 text-center form-control rounded'}
										inputType="tel"
										renderSeparator={<span>-</span>}
										renderInput={(props) => <input {...props}/>}
									/>
									</div>
									<div className="mt-4"> <button type="submit" className="btn btn-primary px-4 validate">Validate</button> </div>
								</form>
							</div>
							{/* <p className="text-center">
								<span>Not your number? </span>
								<Common.Link to="/login">
								<span>Back</span>
								</Common.Link>
							</p> */}
							<p className="text-center">
								{seconds > 0 ? (
								<>Time Remaining: {seconds < 10 ? `0${seconds}` : seconds} secs</>
								) : (
								<>Didn't receive code? <Common.Link onClick={() => slide('up')}>Go back</Common.Link> and try again.</>
								)}
							</p>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={requestModel} size='sm' onHide={()=>setRequestModel(false)} aria-labelledby="Eway-Bill-Cancel-modal" backdrop="static" keyboard={false} centered>
				<Modal.Header>
				<Modal.Title>Request Callback</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-sm-12">
							<p><b>Your Request has been sent successfully</b></p>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<a href="/landing-partner" className='btn btn-success'>OK</a>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}