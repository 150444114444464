import React,{useState} from 'react';
import Layout from './Layout';
import NavBar from './NavBar';
import Select from "react-select";
import Button from 'react-bootstrap/Button';
import * as Common from '../common/ImportUserEssentials';
import supplyTypes from '../masters/SupplyTypesPurchase.js';
import po_doc_types from '../masters/PoDocTypes.js';
import paymentModes from '../masters/PaymentModes.js';
import invoiceType from '../masters/ExpenseInvoiceType.js';
import deductionList from '../masters/Deductions.js';
import { Typeahead,AsyncTypeahead,Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import '../styles/purchase.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-bootstrap/Modal';
import * as DTE from '../common/ImportDataTableEssentials';
import CustomInvFields from './CustomInvFields';
import ItemCustomFields from './ItemCustomFields';
import {Html5Qrcode} from "html5-qrcode";
import beepSound from '../sounds/scanner-beep.mp3';

export default function AddPo(props){
	// User Type
	let p1 = false;//,p2 = false,p3 = false,p4 = false;
	

	// User permissions
	let userPerm = JSON.parse(localStorage.getItem('fo_userperm'));
	const [state_master, setStateMaster] = useState([]);
	const [expense_type_master, setExpenseTypeMaster] = useState([]);
	const [profile_details,setProfileDetails] = useState({name:'',gstin:'',legal_name:'',trade_name:'',addr:'',district:'',pincode:'',state:''});

	const [sid, setSid]        = useState(0);
	const [update_seller,setUpdateSeller] = useState(0);
	const [seller_name, setSellerName]        = useState([]);
	const [seller_phone, setSellerPhone]      = useState('');
	const [seller_phone_disabled, setSellerPhoneDisabled]      = useState(false);
	const [sellerAddiDiv,setSellerAddiDiv]    = useState(false);
	const [invAddiDiv,setInvAddiDiv]    = useState(false);
	const [seller_gstin,setSellerGstin]    = useState('');
	const [seller_legal_name,setSellerLegalName]   = useState('');
	const [seller_trade_name,setSellerTradeName]   = useState('');
	const [seller_pincode, setSellerPincode]       = useState('');
	const [seller_addr,setSellerAddr]              = useState('');
	const [seller_district,setSellerDistrict]      = useState('');
	const [seller_state,setSellerState]            = useState([]);
	const [seller_pos,setSellerPOS]            = useState([]);
	const [doc_no,setDocNo]            = useState('');
	const [doc_type,setDocType]            = useState([{'value':'PO','label':'','digits':''}]);
	const [doc_date, setDocDate] = useState(new Date());

	const [supply_type,setSupplyType] = useState([{'value':'B2C','label':'','digits':''}]);
	const itemRefs = React.useRef([]);
	const cdnoteFields = false;//const [cdnoteFields,setCDNoteFields] = useState(false);
	const [ref_inv_no,setRefInvNo] = useState('');
	const [ref_inv_dt,setRefInvDt] = useState('');
	const [itemDiv,setItemDiv] = useState(false);
	const [impDetDiv,setimpDetDiv] = useState(false);

	const [sellerBankDiv,setSellerBankDiv] = useState(false);
	const [total_amt,setTotalAmt]  = useState('');
	const [total_amt_disabled,setTotalAmtDisabled]  = useState(false);
	const [total_payment,setTotalPayment]  = useState('');
	const [total_deduction,setTotalDeduction]  = useState('');
	const [payments,setPayments] = useState([]);
	const [received,setReceived]                        = useState('');
	const [payment_date,setPaymentDate]  = useState(new Date());
	const [payment_mode,setPaymentMode]  = useState('UPI/QR');
	const [payment_remarks,setPaymentRemarks]  = useState('');
	const [trans_ref,setTransRef]  = useState('');
	//const [due,setDue]                     = useState('');
	const [taxes,setTaxes]    = useState([]);
	const [rev_chrg,setRevChrg]  = useState('N');
	const [expense_type,setExpenseType]  = useState('');
	const [irn_no,setIrnNo]  = useState('');
	const [irn_dt,setIrnDt]  = useState('');
	const [deductions,setDeductions] = useState([]);
	const [deduction_amt,setDeductionAmt]  = useState('');
	const [deduction_per,setDeductionPer]  = useState('');
	const [deduction_type,setDeductionType]  = useState('');
	const frmPurDedType = React.createRef();
	const paymentReceivedRef = React.createRef();
	const [deduction_remarks,setDeductionRemarks]  = useState('');
	const [expensePay_w,setexpensePay_w] = React.useState(true);
	const [isCustLoading, setIsSellerLoading] = useState(false);
	const [isItemLoading, setIsItemLoading] = useState(false);
	const navigate = Common.useNavigate();
	const [sellers, setSellers] = useState([]);
	const [items, setItems] = useState([]);
    //const [advance_amount, setAdvanceAmount] = useState(0);
	const [payModalShow, setPayModalShow] = useState(false);
	const [dedModalShow,setDedModalShow] = useState(false);
	const [temp_deduction,setTempDeduction] = useState([]);
	const [deduction_div,setDeductionDiv] = useState(false);
	const [input_items, setInputItems] = useState([])
	const [sale_items_loop,setSaleItemsLoop] = useState([0]);
	const [allocation_centre_master,setAllocationCentreMaster] = useState([]);
	const [allocation_centre,setAllocationCentre] = useState('');
	const [expenseSubTypeSel,setExpenseSubTypeSel] = useState([]);
	const [isAccountHeadSelected,setIsAccountHeadSelected] = useState(false);
	const [expense_subtype_master, setExpenseSubTypeMaster] = useState([]);
	const [expense_subtype,setExpenseSubType]  = useState('');
	const [allowNegStock,setAllowNegStock] = useState(0);
	const [profileGstin, setProfileGSTIN] = useState('');

	// Import Details
	const [tradeName, setTradeName]      = useState('');
	const [portCode, setPortCode]        = useState('');
	const [ref_date, setRefDate]       	 = useState('');
	const [invoice_type, setInvoiceType] = useState([{'value':'R','label':'','digits':''}]);
	const [etin,setEtin] = useState('');

	// Seller Bank Details
	//const [payee, setPayee] = useState([]);
	const [payees, setPayeeMaster] = useState([]);
	const [payee_name, setPayeeName]       = useState('');
	const [account_no, setAccountNo]       = useState('');
	const [ifsc_code, setIfscCode]       = useState('');
	const [bank_name, setBankName]       = useState('');
	const [payment_terms,setPaymentTerms]             = useState('');
    const [payment_instructions,setPaymentInstructions]         = useState('');
    
	const [docTaxAddiDiv,setDocTaxAddiDiv]    = useState(false);
	const [doc_taxable_amt,setDoctaxableAmt]  	  = useState('0.00');
	const [doc_gst_amt,setDocGstAmt]  	 	  	  = useState('0.00');
	const [doc_total_amt,setDocTotalAmt]  	  	  = useState('0.00');
	const [docOtherCharge,setDocOtherCharge]  	  = useState('');
	const [docRoundoff,setDocRoundoff]  		  = useState('');
	const [docDiscount,setDocDiscount]  	  	  = useState('');
	const [docGstRate, setDocGstRate] 			  = useState([]);
	const [docIgst, setDocIgst]       = useState('0.00');
	const [docCgst, setDocCgst]       = useState('0.00');
	const [docSgst, setDocSgst]       = useState('0.00');
	
	const [totalItemCount, setTotalItemCount]        = useState([]);
	const [quickItemModalShow,setquickItemModalShow] = useState(false);

	const [itemName, setItemsName]         		  = useState('');
    const [item_unit, setItemUnit]         		  = useState('28');
    const [itemHSN, setItemHSN]       			  = useState('');
    const [opening_stock, setOpeningStock] 		  = useState('0');
	const [opening_stock_date, setOpeningStockDt] = useState(new Date());
    const [min_qty, setMinQty]         		 	  = useState('1');
    const [item_price, setItemPrice]       	 	  = useState('');
	const [discount_amt,setDiscountAmt]  	 	  = useState('');
	const [discount_per,setDiscountPer]  	 	  = useState('');
	const [disPerDisabled,setDisPerDisabled] 	  = useState(true);
	const [disAmtDisabled,setDisAmtDisabled] 	  = useState(true);
    const [gross_amt, setGrossAmt]       	 	  = useState('');
    const [item_total, setItemTotal]       	 	  = useState('');
    const [units,setUnits]                 	 	  = useState([]);
    const [gst_rate, setGstRate]       		 	  = useState('');
    const [gst_amt, setGstAmt]       		 	  = useState('');
	const [dupTimeout, setDupTimeout] 		 	  = useState(null);	
	const [itemServiceType, setItemServiceType]   = useState('');
    const [itemStockMovement, setItemStockMovement]       = useState('0');
	const [itemStockChecked,setItemStockChecked]  = useState(false);
	const [purchase_item_price, setPurchaseItemPrice] = useState('');
    const [purchase_gross_amt, setPurchaseGrossAmt] = useState('');
    const [purchase_item_total, setPurchaseItemTotal] = useState('');
	const [purchase_discount_amt,setPurchaseDiscountAmt]  = useState('');
	const [purchase_discount_per,setPurchaseDiscountPer]  = useState('');
	const [disPurchasePerDisabled,setDisPurchasePerDisabled]  = useState(true);
	const [disPurchaseAmtDisabled,setDisPurchaseAmtDisabled]  = useState(true);
    const [purchase_gst_amt, setPurchaseGstAmt]       = useState('');
    const [gst_rate_id, setGstRateId]       = useState('');
	
	const [custListModalShow, setCustListModalShow] = useState(false);
	const [filterText, setFilterText] = React.useState('');
	const [customers_list_modal, setCustomersListModal] = useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [totalDataCnt,setTotalDataCount] = React.useState(0);
	const [DTpending, setDTPending] = React.useState(true);
	const [customInvFields,setCustomInvFields] = React.useState(false);
	const [customInputList, setCustomInputList] = React.useState([]);
	const { state } = Common.useLocation();
	const [expenseTypeSel,setExpenseTypeSel] = useState([]);
	const [acentreSel,setAcentreSel] = useState([]);
	const [defaultPayee, setDefaultPayee] = useState([]);
	const [bankAccount,setBankAccount] = useState(0);
    const [bank_cash_account,setBankCashAccount] = useState(1);
	const [defaultBank, setDefaultBank] = useState([]);
	const [isNegativeBal, setIsNegativeBal] = useState(false);

	const [dividerDisplay, setDividerDisplay] = useState(true);
	const [additionalItemDiv, setAdditionalItemDiv] = useState(true);
	const [skuCode, setSkuCode] = useState('');
	const [barcode, setBarcode] = useState('');
	const [safetyStock, setSafetyStock] = useState('');
	const [reorderPoint, setReorderPoint] = useState('');
	const [reorderQty, setReorderQty] = useState('');
	const [maximumStockLevel, setMaximumStockLevel] = useState('');
	const [customItemFields,setCustomItemFields]	  = useState(false);
	const [customItemInputList, setCustomItemInputList]	  = useState([]);
	//const [saveDraftInvoice, setSaveDraftInvoice] = useState('');
	const [draftInvoiceMsgShow,setDraftInvoiceMsgShow]	  = useState(false);
	
	const [submitType, setSubmitType]    	  	  = useState('0');
	const [actionType, setActionType]    	  	  = useState('copy');
	const [eid, setEid]    	  	  				  = useState('');
	const [docNum, setDocNum]    	  	  	      = useState(false);
	const [draftDocNo,setDraftDocNo]   			  = useState('');

	// Advance Adjust
	const [advanceAdjustModalShow, setAdvanceAdjustModalShow] = useState(false);
	const [advanceBalance,setAdvanceBalance]  	 	   		 = useState(0);
	const [adjustedAmount,setAdjustedAmount]  	 	     	 = useState('');
	const [advanceAdjustedRemarks,setAdvanceAdjustedRemarks] = useState('');
	const [advanceAdjust,setAdvanceAdjust] 					 = useState([]);
	const [totalAdjustedAmount,setTotalAdvanceAdjust]  		 = useState('');
	const [itemDocument,setItemDocument]                             = useState('');
	const [paymentCheckShow, setPaymentCheckShow] 			 = useState(false);
	//common Model	
	const [commonModel, setCommonModel]    	  		 = useState(false);
	const [commonModelHeading,setCommonModelHeading] = useState('');
	const [commonModelBody,setCommonModelBody] 		 = useState('');
	const [commonModelFooter,setCommonModelFooter] 	 = useState('');
	const [expiry_date,setExpiryDate] = useState('');

	const [salePurchasedividerDisplay, setSalePurchaseDividerDisplay] = useState(true);
	const [salePurchaseAdditionalItemDiv, setSalePurchaseAdditionalItemDiv] = useState(true);
    const [itemAdditional,setItemAdditional] = useState(true);
	const [itemBarcode, setItemBarcode] = useState('normal');
	const [itemCustomData, setItemCustomData] = useState([]);
	const [itemCustomDataModel, setItemCustomDataModel] = useState(false);
	const [customItemFieldIndex, setCustomItemFieldIndex] = useState(0);

	const paginationComponentOptions = {
		noRowsPerPage: true
	};

	// Bypass client-side filtering by returning `true`. Results are already
	const filterBy = () => true;

	React.useEffect(() => {
		if((state !== null)){
			setActionType(state.action);
		}
		let tabTitle = (actionType === 'copy')?'Add Po Purchase':'Edit Draft';
		document.title = tabTitle+' | '+props.site_name;
		if( userPerm && Object.keys(userPerm).length > 0 ){
			// Write permission
			setexpensePay_w((userPerm[3]['write'] !== undefined && userPerm[3]['write'] === '0') ? false : true);
		}

		Common.axios({
			method: 'post',
			url:props.url+'user/profileDetails',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
		}).then(function (response) {
			if(response.data.success ){
				if( response.data.data !== null ){
					setProfileDetails(response.data.data);
					setProfileGSTIN(response.data.data.gstin)
					if(response.data.data.gstin !== '' && response.data.data.gstin !== 'URP' ) {
						setSellerPOS([{'value':'','label':'','digits':response.data.data.gstin.slice(0,2)}]);
					}
				}
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));

		// State Master
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_state_master',
		}).then(function (response) {
			if( response.data.success ){
				setStateMaster(response.data.data);
			}
		}).catch(error => console.log(error));

		// Expense Types Master
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_expense_types',
		}).then(function (response) {
			if( response.data.success ){
				// setExpenseTypeSel([response.data.data[0]])
				// setExpenseType(response.data.data[0].value)
				setExpenseTypeMaster(response.data.data);
			}
		}).catch(error => console.log(error));
		//get invoice type
		Common.axios({
			method: 'get',
			url:props.url+'sales/getInvoiceType',
		}).then(function (response) {
			if(response.data.success){
				//setInvoiceTypeData(response.data.data);
			}
		}).catch(error => console.log(error));

		//get allocation centre
		Common.axios({
			method: 'get',
			url:props.url+'sales/getAllocationCentre/'+localStorage.getItem('fo_profileid'),
		}).then(function (response) {
			if(response.data.success){
				setAllocationCentreMaster(response.data.data);
			}
		}).catch(error => console.log(error));
		//get unit
        let url = props.url+'ajax/getUnits';
        Common.axios({
            method: 'get',
            url:url,
        }).then(function (response) {
            if(response.data.success){
				setUnits(response.data.data);
            }
        }).catch(error => console.log(error));

		// get tax rates
		Common.axios({
			method: 'get',
			url:props.url+'ajax/getTaxes',
		}).then(function (response) {
			if(response.data.success){
				setTaxes(response.data.data);
			}
		}).catch(error => console.log(error));
	}, []);

	//Barcode scanner
	let audio = new Audio(beepSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	React.useEffect(() => {
		if( document.getElementById('barCartReader') !== null ){
			const html5QrCode = new Html5Qrcode("barCartReader");
			const startScanner = async () => {
				await html5QrCode.start(
					{ facingMode: 'environment' },
					{
						fps: 10,
						qrbox: { width: 250, height: 180 }
					},
					(decodedText, decodedResult) => {
						if(decodedText !== ''){
							playBeep();
							setItemBarcode('normal');
							setBarcode(decodedText);
						}
					},
					(errorMessage) => {
						// parse error, ignore it.
					}
				).catch((err) => {
					Common.toast.error(err);
					setItemBarcode('normal');
				});
			};
			const stopScanner = async () => {
				if (html5QrCode.isScanning) {
					await html5QrCode.stop();
					html5QrCode.clear();
				}
			};
			if( itemBarcode === 'barcode' ){
				startScanner();
			}
			return () => {
				stopScanner();
			};
		}
	}, [itemBarcode]);

	if( !expensePay_w ){
		navigate("/not-authorized");
	}
	const getExpenseTypes = () => {
		Common.axios({
			method: 'get',
			url: props.url+'ajax/fetch_expense_types',
		}).then(function (response) {
			if( response.data.success ){
				setExpenseTypeSel([response.data.data[0]])
				setExpenseType(response.data.data[0].value)
				setExpenseTypeMaster(response.data.data);
				setExpenseTypeSelect([response.data.data[0]])
			}
		}).catch(error => console.log(error));
	}
	const handleGstinData =(param,e) => {
		if (e.key === 'Enter') {
			Common.showLoader(true);
			let gstin= e.target.value
			let url = props.url+'ajax/fetch_gstin_details';
			Common.axios({
				method: 'post',
				url:url,
				data: {gstin:gstin},
			}).then(function (response) {
				Common.showLoader(false);
				if(response.data.status){
					if(response.data.data.status_cd === "0"){
						Common.toast.dismiss();
						Common.toast.error(response.data.data.error.message);
						return false;
					}
					if( param === 'seller' ){
						setSellerLegalName(response.data.data.lgnm);
						setSellerTradeName(response.data.data.tradeNam);
						if( response.data.data.pradr.addr.pncd !== undefined ){
							populatePinData(param,response.data.data.pradr.addr.pncd);
						}
						if( response.data.data.pradr.addr.loc !== undefined ){
							setSellerAddr(response.data.data.pradr.addr.loc);
						}
						if( response.data.data.gstin !== undefined ){
							setSellerPOS([{'value':'','label':'','digits':response.data.data.gstin.slice(0,2)}]);
						}
					}
					return false;
				}
				Common.toast.dismiss();
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}
	const payeeSearch = (sid) => {
		if (sid !== '') {
			fetch(props.url+`po_purchase/bank-accounts/`+localStorage.getItem('fo_profileid')+`/${sid}`)
				.then((resp) => resp.json())
				.then(({ items }: Response) => {
				setPayeeMaster(items);
			});
		}
	};
	const setPayeeDetails = (e) => {
		// setDefaultPayee('');
		if(e[0] === undefined) {
			setPayeeName('');
			setAccountNo('');
			setIfscCode('');
			setBankName('');
			setPaymentTerms('');
			setPaymentInstructions('');
			setDefaultPayee([])
			return;
		}

		setPayeeName((e[0].payee_name) ? e[0].payee_name : '');
		setAccountNo((e[0].account_no) ? e[0].account_no : '');
		setIfscCode((e[0].ifsc_code) ? e[0].ifsc_code : '');
		setBankName((e[0].bank_name) ? e[0].bank_name : '');
		setPaymentTerms((e[0].payment_terms) ? e[0].payment_terms : '');
		setPaymentInstructions((e[0].payment_instructions) ? e[0].payment_instructions : '');
		payees.map((payee,i) => {
			if (payee.payee_name === e[0].payee_name) {
				setDefaultPayee([e[0]]);
			}
		});
	}
	const setCustomItemQty = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setSafetyStock(newArr);
	}
	const setReorderPointValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setReorderPoint(newArr);
	}
	const setReorderQtyValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setReorderQty(newArr);
	}
	const setMaxStockLevelValue = (val,cnt) => {
		let newArr = '';
		newArr = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
		setMaximumStockLevel(newArr);
	}
	const handleCustomItemInvFields=()=>{
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		if(allWithClass.length <= 9){
        	setCustomItemInputList(customItemInputList.concat(<ItemCustomFields key={customItemInputList.length} len={customItemInputList.length}/>));
		}
		else{
			Common.toast.error('Max Custom Fields Reached');
		}
    }
    const toggeCustomInputInvFields = () => {
		setCustomItemInputList((customItemFields) ? false : true);
		if(!customItemFields){
			handleCustomItemInvFields();
		}else{
			setCustomItemFields([]);
		}
	}
	const showAdditionalItemFields = () => {
		setAdditionalItemDiv( (additionalItemDiv) ? false : true );
	}
	const showSalePurchaseAdditionalItemFields = () => {
		setSalePurchaseAdditionalItemDiv( (salePurchaseAdditionalItemDiv) ? false : true );
	}
	const addAnotherItem = () => {
		setSaleItemsLoop([...sale_items_loop,sale_items_loop.length]);
	}
	const removeItem = (cnt,div) => {
		let allItems = input_items[cnt];
		if(allItems){
			let gstRate = input_items[cnt].gst_rt;
			docGstRate.map( (values,index2)=>{
				if(values.hasOwnProperty(gstRate)){
					let all_gst_amt = values[gstRate];
					let gst_amount = {};
					gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) - Number(allItems.igst_amt)).toFixed(2);
					gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) - Number(allItems.sgst_amt)).toFixed(2);
					gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) - Number(allItems.cgst_amt)).toFixed(2);
					if(gst_amount['igst_amt'] == 0.00 && gst_amount['sgst_amt'] == 0 && gst_amount['cgst_amt'] == 0){
						let newDocGstRate = docGstRate.filter((itms,indexes) => {
							return indexes !== index2;
						});
						setDocGstRate(newDocGstRate);
					}else{
						docGstRate[index2][gstRate] = gst_amount;
						setDocGstRate(docGstRate);
					}
				}
			})
		}
		let new_input_items = input_items.filter((itm,index) => {
			return index !== cnt;
		});
		let newItemCustomData = itemCustomData.filter((itemData,indexs) => {
			return indexs !== cnt;
		})
		setItemCustomData(newItemCustomData);
		let igst = 0,cgst = 0,sgst = 0;
		
		new_input_items.map( (val,index)=>{
			new_input_items[index]['igst_amt'] = Number(val.gst_amt * val.item_qty).toFixed(2);
			new_input_items[index]['cgst_amt'] = '0.00';
			new_input_items[index]['cgst_amt'] = '0.00';
			if( profile_details !==null && (profile_details.gstin !== '') && (profile_details.gstin !== 'URP') && (seller_pos[0] !== undefined) && (seller_pos[0]['digits'] === profile_details.gstin.slice(0,2)) ){
				new_input_items[index]['igst_amt'] = '0.00';
				new_input_items[index]['cgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
				new_input_items[index]['sgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
			}
			igst += Number(new_input_items[index]['igst_amt']);
			cgst += Number(new_input_items[index]['cgst_amt']);
			sgst += Number(new_input_items[index]['sgst_amt']);
		})
		setDocIgst(igst.toFixed(2));
		setDocCgst(cgst.toFixed(2));
		setDocSgst(sgst.toFixed(2));
		setInputItems(new_input_items);
		if( div ){
			setSaleItemsLoop(sale_items_loop.slice(0,-1));
		}
		let amt = new_input_items.reduce(function(prev, current) {
			return Number(prev) + Number(current.item_tot_price)
		}, 0);

		let taxamt = new_input_items.reduce(function(prev, current) {
			return Number(prev) + Number((current.price * current.item_qty) - current.item_discount)
		}, 0);

		
		let totalAmt = (Number(amt) + Number(docOtherCharge) - Number(docDiscount)) + (Number(docRoundoff));
		setTotalAmt(totalAmt.toFixed(2));
		setDocTotalAmt(totalAmt.toFixed(2));

		let total_gst_amt = Number(igst) + Number(cgst) + Number(sgst);
		setDocGstAmt(total_gst_amt.toFixed(2));
		// let doc_taxable_amt = Number(amt) - Number(total_gst_amt);
		let doc_taxable_amt = Number(taxamt);
		setDoctaxableAmt(doc_taxable_amt.toFixed(2));
	}
	const finalDocTotalCal = (otherCharge,roundoff,discount) => {
		if(otherCharge == '.' || roundoff == '.' || discount == '.'){
			return false;
		}
		let total_amount = (Number(doc_gst_amt) + Number(doc_taxable_amt) + Number(otherCharge) - Number(discount)) + (Number(roundoff));
		setTotalAmt(total_amount.toFixed(2));
		setDocTotalAmt(total_amount.toFixed(2));		
	}
	const handleDocRoundoff = (roundoff) => {
		if((Number(0.99) < Number(roundoff)) || (Number(-0.99) > Number(roundoff))){
			Common.toast.dismiss();
			Common.toast.error('Round off amount should be between -0.99 to 0.99');
			return false;
		}
		setDocRoundoff(roundoff);
		finalDocTotalCal(docOtherCharge,roundoff,docDiscount);
	}
	const removePayment = (index) => {
		let price = payments[index].received;
		let ded_amt = payments[index].deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);

		let total_ded = Number(total_deduction) - Number(ded_amt);
		setTotalDeduction(total_ded);//mainCalculation('deduction',total_ded);

		setPayments(payments.filter((item,key) => key !== index));
		let total_pay = Number(total_payment) - Number(price);
		setTotalPayment(total_pay);//mainCalculation('received',total_pay);
	}
	const addPayment = async () => {
		if( received === undefined || Number(received) === 0 ){
			Common.toast.dismiss();
			Common.toast.error('Received amount should be greater than zero.');
			return false;
		}
		if( payment_mode === '' ){
			Common.toast.error('Payment Mode is required');
			return false;
		}
		if( payment_mode !== 'Cash' && (bankAccount === undefined || bankAccount === '') ){
			Common.toast.error('Bank Account is required.');
			return false;
		}
		let payment_date_check = 0;
		if(payment_date){
			await Common.axios({
				method: 'post',
				url:props.url+'payable/getPayableDate',
				data:{pid:localStorage.getItem('fo_profileid'),bankAccount:bankAccount,payment_date:payment_date},
			}).then(function (response) {
				if(response.data.success === 0){
					payment_date_check = 1;
					Common.toast.error(response.data.message);
					return false;
				}
			}).catch(error => console.log(error));
		}
		if(payment_date_check === 1){
			return false;
		}
		let pay = {};
		pay['received'] = received;
		pay['payment_date'] = payment_date;
		pay['payment_mode'] = payment_mode;
		pay['payment_remarks'] = payment_remarks;
		pay['trans_ref'] = trans_ref;
		pay['deductions'] = deductions;
		pay['bankAccount'] = bankAccount;
		let total_pay = Number(total_payment) + Number(received);

		setPayments([...payments, pay]);
		paymentModal('hide');
		setTotalPayment(total_pay);//mainCalculation('received',total_pay);
				
		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);
		setTotalDeduction(Number(total_deduction) + Number(tot_ded));//mainCalculation('deduction',Number(total_deduction) + Number(tot_ded));
	}
	const removeDeduction = (index) => {
		//let price = deductions[index].deduction_amt;
		setDeductions(deductions.filter((item,key) => key !== index));
	}
	const addAdvanceAdjust = () => {
		if( adjustedAmount === undefined || Number(adjustedAmount) === 0 ){
			Common.toast.error('Received amount should be greater than zero.');
			return false;
		}
		if( Number(advanceBalance) < Number(adjustedAmount) ){
			Common.toast.error('Invoice amount is greated than advance balance amount.');
			return false;
		}
		let advance = {};
		advance['adjustedAmount'] = adjustedAmount;
		advance['advanceAdjustedRemarks'] = advanceAdjustedRemarks;
		let total_advance = Number(totalAdjustedAmount) + Number(adjustedAmount);

		setAdvanceAdjust([...advanceAdjust, advance]);
		advanceModal('hide');
		setTotalAdvanceAdjust(total_advance);
		setAdvanceBalance(Number(advanceBalance) - Number(adjustedAmount));
		let total_pay = Number(total_payment) + Number(adjustedAmount);
		setTotalPayment(total_pay);
	}
	const removeAdvanceAdjust = (index) => {
		let price = advanceAdjust[index].adjustedAmount;

		setAdvanceAdjust(advanceAdjust.filter((item,key) => key !== index));
		let total_advance = Number(totalAdjustedAmount) - Number(price);
		setTotalAdvanceAdjust(total_advance);
		setAdvanceBalance(Number(advanceBalance) + Number(price));
		let total_pay = Number(total_payment) - Number(price);
		setTotalPayment(total_pay);
	}

	const calcDeduction = (param,val) => {
		let percent = 0,amount = 0;
		let tot_cal_amt = (doc_taxable_amt > 0)?doc_taxable_amt:total_amt;
		if( param === 'per' ){
			percent = val;
			amount = (tot_cal_amt * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/tot_cal_amt * 100).toFixed(2);
		}
		setDeductionPer(percent);
		setDeductionAmt(amount);
	}
	const addDeduction = () => {
		if( deduction_per === undefined || deduction_per === '' || deduction_per > 100 ){
			Common.toast.dismiss();
			Common.toast.error('Deduction percent is invalid.');
			return false;
		}
		if( deduction_amt === undefined || deduction_amt === '' || Number(deduction_amt) === 0 ){
			Common.toast.dismiss();
			Common.toast.error('Deduction amount should be greater than zero.');
			return false;
		}

		if( deduction_type === undefined || deduction_type === '' ){
			Common.toast.dismiss();
			Common.toast.error('Deduction type is required.');
			return false;
		}
		let tot_ded = deductions.reduce(function(prev, current) {
			return Number(prev) + Number(current.deduction_amt)
		}, 0);
		let tot_cal_amt = (doc_taxable_amt > 0)?doc_taxable_amt:total_amt;
		if( Number(total_deduction) + Number(tot_ded) + Number(deduction_amt) > Number(tot_cal_amt) ){
			Common.toast.dismiss();
			Common.toast.error('Total deduction amount cannot be greater than total taxable value.');
			return false;
		}
		let ded = {};
		ded['deduction_per'] = deduction_per;
		setDeductionPer('');
		ded['deduction_amt'] = deduction_amt;
		setDeductionAmt('');
		ded['deduction_type'] = deduction_type;
		frmPurDedType.current.clear();
		setDeductionType('');
		ded['deduction_remarks'] = deduction_remarks;
		setDeductionRemarks('');

		setDeductions([...deductions, ded]);
	}
	const paymentModal = (param) => {
		if( param === 'show' ){
			setPayModalShow(true);
		}
		if( param === 'hide' ){
			setPayModalShow(false );
			setReceived('');
			setPaymentDate(new Date());
			setPaymentMode('UPI/QR');
			setPaymentRemarks('');
			setTransRef('');
			setDeductionDiv(false);
			setDeductions([]);
		}
	}
	const deductionModal = (param) => {
		setDedModalShow(true);
		setTempDeduction(param);
	}
	const advanceModal = (param) => {
		if( param === 'show' ){
			setAdvanceAdjustModalShow(true);
		}
		if( param === 'hide' ){
			setAdvanceAdjustModalShow(false );
			setAdjustedAmount('');
			setAdvanceAdjustedRemarks('');
		}
	}
	const setSellerDetails = (e) => {
		if(e[0] === undefined) {
			// setCustSel([]);
			setSellerName([]);
			setSellerPhone('');
			setSellerPhoneDisabled(false);
			setSid(0);
			setSellerGstin('');
			setSellerLegalName('');
			setSellerTradeName('');
			setSellerPincode('');
			setSellerAddr('');
			setSellerDistrict('');
			setSellerState([]);
			setSupplyType([{'value':'B2C','label':'','digits':''}]);
			setPayeeMaster([]);
			setSellerPOS([]);
			return;
		}
		// setCustSel(e);
		// setSellerName((e[0]) ? e[0].party_name : '');
		setSellerName(e);
		let phone = (e[0].mobile) ? e[0].mobile : '';
		setSellerPhone(phone);
		let sid = e[0].id.startsWith('new') ? '':e[0].id;
		setSid(sid);
		payeeSearch(sid);
		let gstin = (e[0]) ? e[0].gstin : '';
		if( gstin !== undefined ){
			setSellerGstin(gstin);
			if( gstin !== '' && gstin !== 'URP' ){
				setSellerPOS([{'value':'','label':'','digits':gstin.slice(0,2)}]);
				setSupplyType([{'value':'B2B','label':'','digits':''}]);
			}else{
				setSupplyType([{'value':'B2C','label':'','digits':''}]);
				setSellerPOS([{'value':'','label':'','digits':profileGstin.slice(0,2)}]);
			}
		} else {
			if (profileGstin !== "" && profileGstin !== 'URP') {
				setSellerPOS([{'value':'','label':'','digits':profileGstin.slice(0,2)}]);
			}else{
				setSellerPOS([]);
			}
		}
		// getAdvanceBalance(sid);
		setSellerLegalName(e[0]?.legal_name);
		setSellerTradeName(e[0]?.trade_name);
		setSellerPincode(e[0]?.pincode);
		setSellerAddr(e[0]?.addr);
		setSellerDistrict(e[0]?.district);
		let custState = e[0]?.state;
		if( custState !== undefined && custState !== '' ){
			setSellerState([{'value':custState,'label':'','digits':''}]);
		}
		setSellerPhoneDisabled(true);
		getExpenseTypes()
	}
	const calculateTotalAmt = (item_service='') => {
		let igst = 0, cgst = 0, sgst = 0;
		input_items.map( (val,index)=>{
			if(item_service === ''){
				input_items[index]['item_discount'] = Number(val.discount * val.item_qty).toFixed(2);
				input_items[index]['item_tot_price'] = Number(val.total_price * val.item_qty).toFixed(2);
			}
			if(item_service === 2){
				if (val.price !== '' && val.item_qty !== '') {
					let price = parseFloat(val.price)
					let itemQty = parseInt(val.item_qty)
					if (price > 0 && itemQty > 0) {
						input_items[index]['item_discount_per'] = Number(((val.discount * val.item_qty)/(val.price * val.item_qty)) * 100).toFixed(2);
					}
				}
			}
			input_items[index]['igst_amt'] = Number(val.gst_amt * val.item_qty).toFixed(2);
			input_items[index]['cgst_amt'] = '0.00';
			input_items[index]['cgst_amt'] = '0.00';
			if( profile_details !==null && (profile_details.gstin !== '') && (profile_details.gstin !== 'URP') && (seller_pos[0] !== undefined) && (seller_pos[0]['digits'] === profile_details.gstin.slice(0,2)) ){
				input_items[index]['igst_amt'] = '0.00';
				input_items[index]['cgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
				input_items[index]['sgst_amt'] = Number(val.gst_amt/2 * val.item_qty).toFixed(2);
			}
			igst += Number(input_items[index]['igst_amt']);
			cgst += Number(input_items[index]['cgst_amt']);
			sgst += Number(input_items[index]['sgst_amt']);
		})		
		setDocIgst(igst.toFixed(2));
		setDocCgst(cgst.toFixed(2));
		setDocSgst(sgst.toFixed(2));
		let amt = input_items.reduce(function(prev, current) {
			return Number(prev) + Number(current.item_tot_price)
		}, 0);
		let taxamt = input_items.reduce(function(prev, current) {
			return Number(prev) + Number((current.price * current.item_qty) - current.item_discount)
		}, 0);


		let totalAmt = (Number(amt) + Number(docOtherCharge) - Number(docDiscount)) + (Number(docRoundoff));
		setDocTotalAmt(totalAmt.toFixed(2));
		setTotalAmt(totalAmt.toFixed(2));

		let total_gst_amt = Number(igst) + Number(cgst) + Number(sgst);
		setDocGstAmt(total_gst_amt.toFixed(2));
		// let doc_taxable_amt = Number(amt) - Number(total_gst_amt);
		let doc_taxable_amt = Number(taxamt);
		setDoctaxableAmt(doc_taxable_amt.toFixed(2));
	}
	const setItemQty = (val,cnt,docGstRate) => {
		let newArr = [...input_items];

		newArr[cnt]['item_qty'] = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;

		setInputItems(newArr);
		let gst_rate_data = calculateGstRate(cnt,'sub',docGstRate);
		calculateTotalAmt();
		calculateGstRate(cnt,'add',gst_rate_data);
	}
	const handleItemPrice = (val,cnt,type,docGstRates,gst_rate_id='') => {
		let newArr = [...input_items];
		let gst_rate_data = calculateGstRate(cnt,'sub',docGstRates);
		let itemQty = newArr[cnt]['item_qty'];
		if(type === 'item_price'){
			let itmPrice = Number(val) - Number(input_items[cnt].discount);
			let gstAmt = Number((itmPrice * newArr[cnt]['gst_rt']) / 100);
			let totalPrice = ((Number(val) + Number(gstAmt)) - Number(input_items[cnt].discount));
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['price'] = parseFloat(val) > 0 ? val.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : val;
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		if(type === 'item_discount'){
			let discount_per = Number((val / (newArr[cnt]['price'] * itemQty)) * 100).toFixed(2);
			let itmPrice = Number(newArr[cnt]['price']) - Number(val / itemQty);
			let gstAmt = Number((itmPrice * newArr[cnt]['gst_rt']) / 100);
			let totalPrice = ((Number(newArr[cnt]['price']) + Number(gstAmt)) - Number(val / itemQty));
			newArr[cnt]['discount'] = Number(val / itemQty).toFixed(2);
			newArr[cnt]['item_discount'] = val;
			newArr[cnt]['item_discount_per'] = discount_per;
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		if(type === 'item_discount_per'){
			let discount_per = val;
			val = Number(newArr[cnt]['price'] * itemQty * val/100).toFixed(2);
			let itmPrice = Number(newArr[cnt]['price']) - Number(val / itemQty);
			let gstAmt = Number((itmPrice * newArr[cnt]['gst_rt']) / 100);
			let totalPrice = ((Number(newArr[cnt]['price']) + Number(gstAmt)) - Number(val / itemQty));
			newArr[cnt]['discount'] = Number(val / itemQty).toFixed(2);
			newArr[cnt]['item_discount'] = val;
			newArr[cnt]['item_discount_per'] = discount_per;
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			setInputItems(newArr);
			calculateTotalAmt(1);
		}
		if(type === 'item_tot_price'){
			let finalamt = Number(input_items[cnt].item_discount) + (Number(val) / ((Number(newArr[cnt]['gst_rt'])/100)+1));
			let taxableamt = Number(finalamt) - Number(input_items[cnt].item_discount);
			let gstAmt = Number((taxableamt * newArr[cnt]['gst_rt']) / 100);
			newArr[cnt]['price'] = Number(finalamt / itemQty).toFixed(2);
			newArr[cnt]['gst_amt'] = Number(gstAmt / itemQty);
			// newArr[cnt]['total_price'] = Number(val / itemQty).toFixed(2);
			newArr[cnt]['total_price'] = Number(val / itemQty);
			newArr[cnt]['item_tot_price'] = val;
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		if(type === 'gst_rate'){
			val = val?val:0;
			let itmPrice = Number(newArr[cnt]['price']) - Number(input_items[cnt].discount);
			let gstAmt = Number((itmPrice * val) / 100);
			let totalPrice = ((Number(newArr[cnt]['price']) + Number(gstAmt)) - Number(input_items[cnt].discount));
			// newArr[cnt]['total_price'] = Number(totalPrice).toFixed(2);
			newArr[cnt]['total_price'] = Number(totalPrice);
			newArr[cnt]['gst_amt'] = Number(gstAmt);
			newArr[cnt]['item_tot_price'] = Number(totalPrice * itemQty).toFixed(2);
			newArr[cnt]['gst_rt'] = val;
			newArr[cnt]['item_gst_rate'] = val;
			newArr[cnt]['gst_rate_id'] = gst_rate_id;
			setInputItems(newArr);
			calculateTotalAmt(2);
		}
		calculateGstRate(cnt,'add',gst_rate_data);
	}
	const calculateGstRate = (cnt,action,docGstRate) => {
		let allItems = input_items[cnt];
		let gstRate = input_items[cnt].gst_rt;
		let gst_rate_data = '';
		if(action === 'sub'){
			if( docGstRate.length > 0 ){
				docGstRate.map( (val1,index1)=>{
					if(val1.hasOwnProperty(gstRate)){
						let all_gst_amt = val1[gstRate];
						let gst_amount = {};
						gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) - Number(allItems.igst_amt)).toFixed(2);
						gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) - Number(allItems.sgst_amt)).toFixed(2);
						gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) - Number(allItems.cgst_amt)).toFixed(2);
						if(gst_amount['igst_amt'] == '0.00' && gst_amount['sgst_amt'] == '0.00' && gst_amount['cgst_amt'] == '0.00'){
							let newDocGstRate = docGstRate.filter((itms,indexes) => {
								return indexes !== index1;
							});
							setDocGstRate(newDocGstRate);
							gst_rate_data = newDocGstRate;
						}else{
							docGstRate[index1][gstRate] = gst_amount;
							setDocGstRate(docGstRate);
							gst_rate_data = docGstRate;
						}
					}
				})
			}
		}else{
			if(docGstRate.length > 0 ){
				var rate_exist = docGstRate.some(el => el.hasOwnProperty(gstRate));
				if(rate_exist){
					docGstRate.map( (val3,index3)=>{
						if(val3.hasOwnProperty(gstRate)){
							let all_gst_amt = val3[gstRate];
							let gst_amount = {};
							gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) + Number(allItems.igst_amt)).toFixed(2);
							gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) + Number(allItems.sgst_amt)).toFixed(2);
							gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) + Number(allItems.cgst_amt)).toFixed(2);
							docGstRate[index3][gstRate] = gst_amount;
							setDocGstRate(docGstRate);
						}
					})
				}
				else{
					let gst_rate_arr = {};
					let gst_amount = {};
					gst_amount['igst_amt'] = Number(allItems.igst_amt).toFixed(2);
					gst_amount['cgst_amt'] = Number(allItems.cgst_amt).toFixed(2);
					gst_amount['sgst_amt'] = Number(allItems.sgst_amt).toFixed(2);
					gst_rate_arr[gstRate] = gst_amount;
					setDocGstRate([...docGstRate,gst_rate_arr]);
				}
			}else{
				let gst_rate_arr = {};
				let gst_amount = {};
				gst_amount['igst_amt'] = Number(allItems.igst_amt).toFixed(2);
				gst_amount['cgst_amt'] = Number(allItems.cgst_amt).toFixed(2);
				gst_amount['sgst_amt'] = Number(allItems.sgst_amt).toFixed(2);
				gst_rate_arr[gstRate] = gst_amount;
				setDocGstRate([...docGstRate,gst_rate_arr]);
			}
		}
		return gst_rate_data;
	}
	const setItemDesc = (cnt,val) => {
		let newArr = [...input_items];
		newArr[cnt]['description'] = val;
		setInputItems(newArr);
	}
	const setItemDetails = (e,cnt) => {
		if(e.length > 0 && (e[0] !== undefined) ){
			e[0]['item_tot_price'] = e[0]?.total_price;
			e[0]['item_qty'] = 1;
			e[0]['item_discount'] = e[0]?.discount;
			if ((e[0]?.price !== null) && (e[0]?.discount !== null ) && (e[0]['item_qty'] !== undefined)) {
				e[0]['item_discount_per'] = ( ((e[0]?.discount * e[0]['item_qty']) / (e[0]?.price * e[0]['item_qty'])) * 100).toFixed(2);
			} else {
				e[0]['item_discount_per'] = '0.00'
			}
			e[0]['item_gst_rate'] = e[0]?.gst_rt;
			e[0]['item_igst_rate'] = e[0]?.gst_rt;
			e[0]['item_sgst_rate'] = '0.00';
			e[0]['item_cgst_rate'] = '0.00';
			e[0]['igst_amt'] = e[0]?.gst_amt;
			e[0]['cgst_amt'] = '0.00';
			e[0]['sgst_amt'] = '0.00';
			e[0]['allow_neg_stock'] === '1' ? setIsNegativeBal(true):setIsNegativeBal(false)
			if( profile_details !==null && (profile_details.gstin !== '') && (profile_details.gstin !== 'URP') && (seller_pos[0] !== undefined) && (seller_pos[0]['digits'] === profile_details.gstin.slice(0,2)) ){
				e[0]['igst_amt'] = '0.00';
				e[0]['cgst_amt'] = (Number(e[0]?.gst_amt)/2).toFixed(2);
				e[0]['sgst_amt'] = (Number(e[0]?.gst_amt)/2).toFixed(2);
			}
			if(docGstRate.length > 0 && (e[0] !== undefined) ){
				var rate_exist = docGstRate.some(el => el.hasOwnProperty(e[0]['item_igst_rate']));
				if(rate_exist){
					docGstRate.map( (val,index)=>{
						if(val.hasOwnProperty(e[0]['item_igst_rate'])){
							let all_gst_amt = val[e[0]['item_igst_rate']];
							let gst_rate_arr = {};
							let gst_amount = {};
							gst_amount['igst_amt'] = (Number(all_gst_amt['igst_amt']) + Number(e[0]['igst_amt'])).toFixed(2);
							gst_amount['sgst_amt'] = (Number(all_gst_amt['sgst_amt']) + Number(e[0]['sgst_amt'])).toFixed(2);
							gst_amount['cgst_amt'] = (Number(all_gst_amt['cgst_amt']) + Number(e[0]['cgst_amt'])).toFixed(2);
							docGstRate[index][e[0]['item_igst_rate']] = gst_amount;
							setDocGstRate(docGstRate);
						}
					})
				}
				else{
					let gst_rate_arr = {};
					let gst_amount = {};
					gst_amount['igst_amt'] = Number(e[0]['igst_amt']).toFixed(2);
					gst_amount['cgst_amt'] = Number(e[0]['cgst_amt']).toFixed(2);
					gst_amount['sgst_amt'] = Number(e[0]['sgst_amt']).toFixed(2);
					gst_rate_arr[e[0]['item_gst_rate']] = gst_amount;
					setDocGstRate([...docGstRate,gst_rate_arr]);
				}
			}else{
				let gst_rate_arr = {};
				let gst_amount = {};
				gst_amount['igst_amt'] = Number(e[0]['igst_amt']).toFixed(2);
				gst_amount['cgst_amt'] = Number(e[0]['cgst_amt']).toFixed(2);
				gst_amount['sgst_amt'] = Number(e[0]['sgst_amt']).toFixed(2);
				gst_rate_arr[e[0]['item_gst_rate']] = gst_amount;
				setDocGstRate([...docGstRate,gst_rate_arr]);
			}
			setDocIgst((Number(docIgst) + Number(e[0]['igst_amt'])).toFixed(2));
			setDocCgst((Number(docCgst) + Number(e[0]['cgst_amt'])).toFixed(2));
			setDocSgst((Number(docSgst) + Number(e[0]['sgst_amt'])).toFixed(2));
			e[0]['disabled'] = true;

			setInputItems([...input_items,e[0]]);
			let totalAmt = Number(total_amt) + Number(e[0]?.total_price);

			// let total_amount = (Number(totalAmt) + Number(docOtherCharge) - Number(docDiscount)) + (Number(docRoundoff));
			let total_amount = (Number(totalAmt));
			setTotalAmt(total_amount.toFixed(2));
			setDocTotalAmt(total_amount.toFixed(2));

			let total_gst_amt = (Number(docIgst) + Number(e[0]['igst_amt'])) + (Number(docCgst) + Number(e[0]['cgst_amt'])) + (Number(docSgst) + Number(e[0]['sgst_amt']));
			setDocGstAmt(total_gst_amt.toFixed(2));
			let doc_tax_amt = Number(doc_taxable_amt) + Number((e[0]['price'] * e[0]['item_qty']) - e[0]['item_discount']);
			// let doc_taxable_amt = Number(totalAmt) - Number(total_gst_amt);
			setDoctaxableAmt(doc_tax_amt.toFixed(2));
			setTotalAmtDisabled(true);
			return false;
		}
		if(cnt > -1) {
			alert(input_items[cnt]['total_price']);
			let total = Number(total_amt) - Number(input_items[cnt]['total_price']);
			if( total === 0 ){
				setTotalAmtDisabled(false);
			}
			setTotalAmt(total);
			setInputItems((input_items) => input_items.filter((_, index) => index !== cnt));
		}
	}
	const checkPayment = () => {
		if( received === undefined || Number(received) === 0 ){
			Common.toast.dismiss();
			Common.toast.error('Enter the payment before adding deduction.');
			paymentReceivedRef.current.focus();
			return false;
		}
	}
	const setAllocationCentreSelect = (e) => {
		let value = (e[0]) ? e[0].name : '';
		setAllocationCentre(value);
		setAcentreSel();
	}
	const setDocTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setDocType(e);
		// setCDNoteFields( value === 'CRN' || value === 'DBN' );
	}
	const setPaymentModeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setPaymentMode(value);
		if(value === 'Cash'){
			Common.axios({
				method: 'get',
				url:props.url+'bank-accounts/bankExist/'+localStorage.getItem('fo_profileid')+'/'+value,
			}).then(function (response) {
				if(response.data.success){
					if(response.data.data !== ''){
						setBankCashAccount(1);
						setDefaultBank(response.data.data);
						setBankAccount(response.data.data[0].value);
					}else{
						setBankCashAccount(0);
						setBankAccount(0);
						setDefaultBank([]);
					}
				}
			}).catch(error => console.log(error));
		}else{
			setBankCashAccount(1);
			setDefaultBank([]);
		}
	}
	const setBankAccountSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setBankAccount(value);
		setDefaultBank('');
	}
	const setExpenseTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setExpenseType(value);
		// setExpenseTypeSel('');
		if (value === '') {
			setExpenseTypeSel([]);
		} else {
			setExpenseTypeSel(e);
		}
		e.length === 0 ? setIsAccountHeadSelected(false) : setIsAccountHeadSelected(true);
		// Income subTypes Master
		if (value !== '') {
			Common.axios({
				method: 'post',
				url: props.url+'ajax/fetch_expense_subtypes',
				data: {
					'pid': localStorage.getItem('fo_profileid'),
					'expenses_type_master_id':e[0].value
				}
			}).then(function (response) {
				if( response.data.success ){
					setExpenseSubTypeMaster(response.data.data);
				}
			}).catch(error => console.log(error));
		} else {
			setExpenseSubType(value);
			setExpenseSubTypeSel([])
		}
	}
	const setExpenseSubTypeSelect = (e) => {
		// let value = (e[0]) ? e[0].label : '';
		// setExpenseSubType(value)
		// setExpenseSubTypeSel('');
		let value = '';
		if (e.length > 0 && e[0].id === undefined) {
			value = e[0].value;
		} else if (e.length > 0 && e[0].id !== undefined) {
			value = e[0].label;
		}
		setExpenseSubType(value)
		if (value === '') {
			setExpenseSubTypeSel([])
		} else {
			setExpenseSubTypeSel(e)
		}
	}
	const setDeductionTypeSelect = (e) => {
		let value = (e[0]) ? e[0].value : '';
		setDeductionType(value);
	}
	const setSellerGstinSelect = (e) => {
		setSellerGstin(e);
		if( e !== '' && e !== 'URP' ){
			setSupplyType([{'value':'B2B','label':'','digits':''}]);
		}else{
			setSupplyType([{'value':'B2C','label':'','digits':''}]);
		}
	}
	const handleSubmit =(e) => {
		e.preventDefault();
		/* if(draftDocNo !== '' && actionType === 'edit' && submitType === '1' && saveDraftInvoice === '0'){
			setDraftInvoiceMsgShow(true);
			return false
		} */
		Common.showLoader(true);
		let errors = [];
		let formData = new FormData(e.target);
		let pid = localStorage.getItem('fo_profileid');
		formData.append('pid', pid);
		formData.append('sid', sid);
		formData.append('seller_name', seller_name[0].party_name);
		formData.append('seller_state', ((seller_state[0]) ? seller_state[0].value : ''));
		formData.append('doc_type', ((doc_type[0]) ? doc_type[0].value : 'PO'));
		formData.append('supply_type', ((supply_type[0]) ? supply_type[0].value : 'B2C'));
		formData.append('expense_type', expense_type);
		formData.append('expense_subtype', expense_subtype);
		formData.append('update_seller', update_seller);
		formData.append('total_deduction', total_deduction);
		formData.append('allocation_centre', allocation_centre);
		formData.append('etin', etin);
		formData.append('doc_no', doc_no);
		formData.append('rev_chrg', rev_chrg);
		formData.append('total_payment', total_payment);
		formData.append('payee_name', (sellerBankDiv) ? payee_name : '');
		formData.append('pos', (seller_pos[0]!== undefined ? JSON.stringify(seller_pos[0]) : ''));
		formData.append('invoice_type', ((invoice_type[0]) ? invoice_type[0].value : ''));
		formData.append('total_taxable_amt', doc_taxable_amt);
		formData.append('other_charges', docOtherCharge);
		formData.append('round_off', docRoundoff);
		formData.append('total_doc_discount', docDiscount);
		formData.append('submitType', submitType);
		formData.append('eid', eid);
		formData.append('actionType', actionType);
		//formData.append('saveDraftInvoice', saveDraftInvoice);

		if( input_items.length > 0 ){
			input_items.map((val,index) => {
				if(Math.sign(val.price) === -1){
					errors.push('Item '+(index+1)+': Item price should not be negative value.');
					return false;
				}
				if(Math.sign(val.item_tot_price) === -1){
					errors.push('Item '+(index+1)+': Item total price should not be negative value.');
					return false;
				}
				if(!val.gst_rate_id){
					errors.push('Item '+(index+1)+': Item GST Rate is required.');
					return false;
				}
			});
			formData.append('items', JSON.stringify(input_items));
		}
		if( docGstRate.length > 0 ){
			formData.append('docGstRate', JSON.stringify(docGstRate));
		}
		// convert the key/value pairs
		let data = {};
		for (var [key, value] of formData.entries()) {
		   data[key] = value;
		}
		// document upload
		let fileData = new FormData();
		fileData.append('files', itemDocument);

		if( data.seller_name === '' ){
			errors.push('Seller Details: Seller name cannot be blank.');
		}
		if( (data.seller_gstin !== '' && data.seller_gstin !== undefined) && !Common.validGstin(data.seller_gstin)){
			errors.push('Seller Details: GSTIN is invalid.');
		}
		/*if( data.doc_no === '' && saveDraftInvoice === '1'){
			errors.push('Invoice Data: Document number cannot be blank.');
		}*/
		if( data.doc_date === '' ){
			errors.push('Invoice Data: Document date cannot be blank.');
		}
		if( data.doc_type === '' ){
			errors.push('Invoice Data : Document Type cannot be blank<br/>');
		}
		if (expense_type === '' || expense_type === undefined) {
			errors.push('Expense Type cannot be blank<br/>');
		}
		if( impDetDiv ){
			if( data.tradeName === '' ){
				errors.push('Import Details: Trade name cannot be blank.');
			}
			if( data.portCode === '' ){
				errors.push('Import Details: Port Code cannot be blank.');
			}
			if( data.ref_date === '' ){
				errors.push('Import Details: Reference Date cannot be blank.');
			}
			if( data.invoice_type === '' ){
				errors.push('Import Details: Invoice Type cannot be blank.');
			}
		}
		if( sellerBankDiv ){
			if( data.payee_name === '' ){
				errors.push('Seller Bank Details: Payee Name cannot be blank.');
			}
			if( data.account_no === '' ){
				errors.push('Seller Bank Details: Account Number cannot be blank.');
			}
			if( data.ifsc_code === '' ){
				errors.push('Seller Bank Details: IFSC Code cannot be blank.');
			}
			if( data.bank_name === '' ){
				errors.push('Seller Bank Details: Bank Name cannot be blank.');
			}
		}
		if( errors.length > 0 ){
			Common.showLoader(false);
			let err_list = '';
			errors.forEach((val, index) => {
				err_list += '<li>'+val+'</li>';
			})
			const err_html = () => (
				<ul><strong>List of Errors:</strong>{Common.parse(err_list)}</ul>
			);
			Common.toast.dismiss();
			Common.toast.error(err_html);
			return false;
		}
		let url = props.url+'po_purchase/add';
		Common.axios({
			method: 'post',
			url: url,
			data: data
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				if (itemDocument) {
					fileData.append('po_purchase_id', response.data.purid);
					fileData.append('pid', response.data.pid);
					fileData.append('from_datatable', '0');
					Common.axios.post(props.url+'po_purchase/upload_file', fileData, {
						mode: 'cors',
						headers: { 'Content-Type':  'multipart/form-data' }
					}).then(function (response) {
						if(response.data.success){
							navigate("/po-listing");
							return false;
						}
					}).catch(error => console.log(error));
				}
				navigate("/po-listing");
				return false;
			}
			Common.toast.dismiss();
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const sellerSearch = (query: String) => {
		setIsSellerLoading(true);

		fetch(props.url+`vendor/search/`+localStorage.getItem('fo_profileid')+`/${query}`)
			.then((resp) => resp.json())
			.then(({ items }: Response) => {
			setSellers(items);
			setIsSellerLoading(false);
		});
	};
	const itemSearch = (query: string,count) => {
		setIsItemLoading(true);
		fetch(props.url+`items/search/`+localStorage.getItem('fo_profileid')+`/${query}`+`/P`)
			.then((resp) => resp.json())
			.then(({ items,total_count }: Response) => {
			setTotalItemCount([{'name':count,'value':total_count}]);
			setItems(items);
			setItemsName(query);
			setIsItemLoading(false);
		})
	};
	/* const getAdvanceBalance = (cid: string) => {
		Common.axios({
			method: 'get',
			url: props.url+'payable/getAdvanceDetails/'+localStorage.getItem('fo_profileid')+'/'+cid,
		}).then(function (response) {
			if( response.data.success ){
				setAdvanceBalance((response.data.data !== null)?response.data.data.closing_balance:0);
			}
		}).catch(error => console.log(error));
	}; */


	const showSellerAdditional = () => {
		setSellerAddiDiv( (sellerAddiDiv) ? false : true );
	}
	const showDocTaxAdditional = () => {
		setDocTaxAddiDiv( (docTaxAddiDiv) ? false : true );
	}
	const toggleItemDiv = () => {
		setItemDiv((itemDiv) ? false : true);
		if( !itemDiv ){
			setTotalAmt('');
			setDocOtherCharge('');
			setDocRoundoff('');
			setDocDiscount('');
			calculateTotalAmt();
		}
		if(itemDiv){
			setInputItems([]);
			setTotalAmtDisabled(false);
			setDocGstRate([]);
		}
	}
	const toggeimpDetDiv = () => {
		setimpDetDiv((impDetDiv) ? false : true);
	}
	const toggeSellerBankDiv = () => {
		setSellerBankDiv((sellerBankDiv) ? false : true);
	}
	const populatePinData = (param,pincode) => {
		switch(param) {
			case 'seller':
				setSellerPincode(pincode);
				if( pincode.length=== 6 ){
					Common.axios({
						method: 'post',
						url:props.url+'ajax/getDataByPin',
						data: {pincode:pincode},
					}).then(function (response) {
						if(response.data.success){
							if( response !== undefined && response.data.stateid !== undefined && response.data.stateid !== '' ){
								setSellerState([{'value':response.data.stateid,'label':'','digits':''}]);
							}
							setSellerDistrict(response.data.district);
							return false;
						}
						Common.toast.dismiss();
						Common.toast.error(response.data.message);
					}).catch(error => console.log(error));
				}
				break;
			default:
				break;
		}
	}

	const quickItemCalculation = (param,val) => {
		if( param === 'item_price' ){
			setItemPrice(val);
			setGrossAmt((Number(val) - Number(discount_amt)).toFixed(2));
			let gstAmt = (((Number(val) - Number(discount_amt)) * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(val) - Number(discount_amt)) + Number(gstAmt)).toFixed(2));
			if( Number(val) > 0 ){
				setDisPerDisabled(false);
				setDisAmtDisabled(false);
			}
			if( Number(val) === 0 ){
				setDisPerDisabled(true);
				setDisAmtDisabled(true);
			}
			 // || Number(item_price) === 0
			if( Number(val) === 0){
				setGrossAmt('');
				setItemTotal('');
				setGstAmt('');
			}
		}
		if( param === 'discount_amt' ){
			setGrossAmt((Number(item_price) - Number(val)).toFixed(2));
			let gstAmt = (((Number(item_price) - Number(val)) * gst_rate)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(item_price) - Number(val)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'gst_rate' ){
			setGrossAmt((Number(item_price) - Number(discount_amt)).toFixed(2));
			setGstRate(val);
			let gstAmt = ((gross_amt * val)/100).toFixed(2);
			setGstAmt(gstAmt);
			setItemTotal(((Number(item_price) - Number(discount_amt)) + Number(gstAmt)).toFixed(2));
		}
	}
	const calcPurchaseDiscount = (param,val) => {
		let percent = 0,amount = 0, price = purchase_item_price;
		if( param === 'per' ){
			percent = val;
			amount = (price * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/price * 100).toFixed(2);
		}
		setPurchaseDiscountPer(percent);
		setPurchaseDiscountAmt(amount);
		purchaseItemCalculation('discount_amt',amount);
	}
	const purchaseItemCalculation = (param,val) => {
		if( param === 'item_price' ){
			setPurchaseItemPrice(val);
			setPurchaseGrossAmt((Number(val) - Number(purchase_discount_amt)).toFixed(2));
			let gstAmt = (((Number(val) - Number(purchase_discount_amt)) * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(val) - Number(purchase_discount_amt)) + Number(gstAmt)).toFixed(2));
			if( Number(val) > 0 ){
				setDisPurchasePerDisabled(false);
				setDisPurchaseAmtDisabled(false);
			}
			if( Number(val) === 0 ){
				setDisPurchasePerDisabled(true);
				setDisPurchaseAmtDisabled(true);
			}
			// || Number(purchase_item_price) === 0
			if( Number(val) === 0 ){
				setPurchaseGrossAmt('');
				setPurchaseItemTotal('');
				setPurchaseGstAmt('');
			}
		}
		if( param === 'discount_amt' ){
			setPurchaseGrossAmt((Number(purchase_item_price) - Number(val)).toFixed(2));			
			let gstAmt = (((Number(purchase_item_price) - Number(val)) * gst_rate)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(purchase_item_price) - Number(val)) + Number(gstAmt)).toFixed(2));
		}
		if( param === 'gst_rate' ){
			setPurchaseGrossAmt((Number(purchase_item_price) - Number(purchase_discount_amt)).toFixed(2));
			let gstAmt = ((purchase_gross_amt * val)/100).toFixed(2);
			setPurchaseGstAmt(gstAmt);
			setPurchaseItemTotal(((Number(purchase_item_price) - Number(purchase_discount_amt)) + Number(gstAmt)).toFixed(2));
		}
	}

	// Quick Add Item
	const quickAddItem = () => {
		setItemPrice('');
		setOpeningStock('');
		setMinQty('1');
		setOpeningStockDt(new Date());
		setDiscountAmt('');
		setDiscountPer('');
		setItemHSN('');
		setGrossAmt('');
		setGstRate('');
		setGstAmt('');
		setItemTotal('');
		setquickItemModalShow(true);
	}
	const handleAddiFields=(val)=>{
		// if (val === '') {
		// 	setDividerDisplay(false)
		// 	setSalePurchaseDividerDisplay(false)
		// }
		setItemsName(val);
		if( dupTimeout ){
			clearTimeout(dupTimeout);
			setDupTimeout(null);
		}
		if( val !== '' && val.length >= 3 ){
			setItemAdditional(true);
			setDupTimeout(setTimeout(() => {
				Common.axios({
					method: 'post',
					url:props.url+'items/duplicate',
					data: {'name':val,'pid':localStorage.getItem('fo_profileid')}
				}).then(function (response) {
					if(!response.data.success){
						resetForm();
						Common.toast.dismiss();
						Common.toast.error(response.data.message);
					}
				}).catch(error => console.log(error));
			}, 2000));
			return false;
		}
		setItemAdditional(true);
		setItemServiceType('');
		return false;
    }
	const handleItem = (e)=>{
        e.preventDefault();
        let formData = new FormData(e.target);
        if(itemName===""){
			Common.toast.dismiss();
            Common.toast.error("Item Name is Required.");
            return false;
        }
		if(itemName.length < 3){
			Common.toast.dismiss();
            Common.toast.error("Item Name should have minimum 3 characters.");
            return false;
        }
		if (itemServiceType !== '1') {
			if (isNegativeBal === false && opening_stock < 0) {
				Common.toast.dismiss();
				Common.toast.error("Opening Stock cannot be Negative.");
				return false;
			}
		}

        let data = {};

        // convert the key/value pairs
		for (var [key, value] of formData.entries()) {
			data[key] = value;
		}

		data['item_unit'] = (itemServiceType === '1')?29:item_unit;
		data['discount'] = discount_amt;
		data['allow_neg_stock'] = allowNegStock;
		data['purchase_discount'] = purchase_discount_amt;
		data['gst_rt'] = gst_rate;
		data['gst_rate_id'] = gst_rate_id;
		data['item_service'] = itemServiceType;
		data['opening_stock'] = (itemServiceType === '1')?0:opening_stock;
		data['min_qty'] = (itemServiceType === '1')?0:min_qty;
		// data['item_hsn'] = (itemServiceType === '1')?'99'+itemHSN:itemHSN;
		if (itemServiceType === '1') {
			if (itemHSN !== '') {
				data['item_hsn'] = '99'+itemHSN;
			} else {
				data['item_hsn'] = '';
			}
		} else {
			data['item_hsn'] = itemHSN;
		}
		data['pid'] = localStorage.getItem('fo_profileid');

		Common.axios({
            method: 'post',
            url:props.url+'items/addRecord',
            data: data,
        }).then(function (response) {
			Common.toast.dismiss();
			if(response.data.success){
				// navigate("/item-listing");
				Common.toast.success(response.data.message);
				setItemsName(''); setItemUnit('28'); setItemPrice('');
				resetForm();
				itemSearch(itemName);
				setquickItemModalShow(false);
				setItemStockChecked(false);
			}else{
				Common.toast.error(response.data.message);
			}
		});
    }
	const calcDiscount = (param,val) => {
		let percent = 0,amount = 0, price = item_price;
		if( param === 'per' ){
			percent = val;
			amount = (price * percent/100).toFixed(2);
		}
		if( param === 'amt' ){
			amount = val;
			percent = (val/price * 100).toFixed(2);
		}
		setDiscountPer(percent);
		setDiscountAmt(amount);
		quickItemCalculation('discount_amt',amount);
	}
	const resetForm = () => {
		handleAddiFields('');
		setItemUnit('28');
		setItemPrice('');
		setOpeningStock('');
		setMinQty('1');
		setOpeningStockDt(new Date());
		setDiscountAmt('');
		setDiscountPer('');
		setItemHSN('');
		setGrossAmt('');
		setGstRate('');
		setGstAmt('');
		setItemTotal('');
		setItemServiceType('');
		setPurchaseItemPrice('');
		setPurchaseGrossAmt('');
		setPurchaseItemTotal('');
		setPurchaseDiscountAmt('');
		setPurchaseDiscountPer('');
		setDisPurchasePerDisabled(true);
		setDisPurchaseAmtDisabled(true);
		setPurchaseGstAmt('');
		setItemStockMovement(0);
		setSkuCode('');
		setBarcode('');
		setReorderQty('');
		setReorderPoint('');
		setSafetyStock('');
		setMaximumStockLevel('');
		// setDividerDisplay(false);
		setAdditionalItemDiv(true);
		setSalePurchaseAdditionalItemDiv(true);
		// setSalePurchaseDividerDisplay(false);
		setItemAdditional(true);
	}
	const customerListModal = (param) => {
		if( param === 'show' ){
			setCustListModalShow(true);
			loadCustTableModal(1,filterText);
		}
		if( param === 'hide' ){
			setCustListModalShow(false );
			setCustomersListModal([]);
		}
	}
	const handlePageChange = page => {
		loadCustTableModal(page,filterText);
	};
	const handleSort = (column, sortDirection) => {
		loadCustTableModal(1,filterText,btoa(column.sortField+'####'+sortDirection));
	};
	const loadCustTableModal = (page,filterText,sort='') => {
		Common.axios({
			method: 'post',
			url: props.url+'vendor/listingInVendor/',
			data: {'pid':localStorage.getItem('fo_profileid'),'page':page,'sort':sort,'search':filterText}
		}).then(function (response) {
			if(response.data.success){
				setCustomersListModal(response.data.data);
				setTotalRows(response.data.total);
				setTotalDataCount(response.data.total);
				setDTPending(false);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));
	}
	const columns = [
        {
            name: 'Party Name',
            selector: row => row.party_name,
            sortable: true,
			sortField: 'party_name',
			cell: (row) => (
				<Button variant="" className="text-break p-0 text-decoration-underline" onClick={e=>{setSellerDetails([row]);setCustListModalShow(false);}}>{row.party_name.substring(0,15)}</Button>
			)
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
			sortField: 'mobile'
        },
        {
            name: 'Gstin',
            selector: row => row.gstin,
            sortable: true,
			sortField: 'gstin'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
			sortField: 'email'
        }
    ];
	const getItemServiceType = (actype) => {
		if(itemName === ''){
			Common.toast.dismiss();
			Common.toast.error('Item Name is Required.');
			return false;
		}
		if(itemName.length < 3){
			Common.toast.dismiss();
            Common.toast.error("Item Name should have minimum 3 characters.");
            return false;
        }
		setItemServiceType(actype);
		setDividerDisplay(true);
		setSalePurchaseDividerDisplay(true);
	}
	const toggeCustomInvFields = () => {
		setCustomInvFields((customInvFields) ? false : true);
		if(!customInvFields){
			handleCustomInvFields();
		}else{
			setCustomInputList([]);
		}
	}
	const handleCustomInvFields=()=>{
		const allWithClass = Array.from(
			document.getElementsByClassName('custom_fields')
		);
		if(allWithClass.length <= 9){
        	setCustomInputList(customInputList.concat(<CustomInvFields key={customInputList.length} len={customInputList.length}/>));
		}
		else{
			Common.toast.error('Max Custom Fields Reached');
		}
    }
	const getItemStockMovement = () => {
		setItemStockMovement((itemStockMovement === '1') ? '0' : '1');
		setItemStockChecked((itemStockMovement === '1') ? false : true);
	}
	const getInvNo = () => {
		/* if(docNum){
			setDocNo('');
			setDocNum(false);
			return false;
		} */
		// Common.showLoader(true);
		Common.axios({
			method: 'post',
			url:props.url+'po_purchase/genInvNo',
			data: {pid:Common.AES.encrypt(localStorage.getItem('fo_profileid'), props.site_key).toString()}
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setDocNo(response.data.data);
				setDocNum(true);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error))
	}
	// Fetch invoice No
	getInvNo();
	// const inv_interval = setInterval(() => {getInvNo()},10000);
	if( profile_details !==null && (profile_details.gstin === '' || profile_details.gstin === 'URP') ){
		p1 = true;
	}
	const handleDocument = (e) => {
        const fileTypes = ['application/pdf', 'image/jpeg', 'image/jpg','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
		if (e.target.files[0] !== undefined) {
			if (!fileTypes.includes(e.target.files[0].type)) {
				Common.toast.error('File format not supported');
				e.target.value = null;
				return false;
			} else {
				if (e.target.files[0].size > (5 * 1048576)) {
					Common.toast.error('File size should be less than 5MB');
					e.target.value = null;
					return false;
				} else {
					setItemDocument(e.target.files[0]);
				}
			}
		}
        Common.toast.dismiss();
    };
	const getItemCustomData = (id,count)=>{
		setCustomItemFieldIndex(count);
		if(itemCustomData[count]){
			setItemCustomDataModel(true);
			return false;
		}else{
			Common.axios({
				method: 'get',
				url:props.url+'items/getItemCustomFields/'+localStorage.getItem('fo_profileid')+'/'+id,
			}).then(function (response) {
				if(response.data.success){
					let response_data = response.data.data;
					if(response_data.length > 0){
						let custom_data = [];
						response_data.forEach( (val,index)=>{
							let custom_name = {};
							custom_name['count'] = count;
							custom_name['item_master_id'] = val.item_master_id;
							custom_name['item_field_name'] = val.item_field_name;
							custom_name['item_field_value'] = '';
							custom_data[index] = custom_name;
						})
						itemCustomData[count] = custom_data;
						setItemCustomData(itemCustomData);
						setItemCustomDataModel(true);
					}else{
						Common.toast.error('Custom field not availabe for this item');
					}
					
					return false;
				}
				Common.toast.error(response.data.message);
			}).catch(error => console.log(error));
		}
	}
	const handleCustomInputFeild = (field_val,count,index) => {
		let newArr = [...itemCustomData];
		newArr[count][index]['item_field_value'] = field_val;
		setItemCustomData(newArr);
	}
	const handleCustomData = (cnt) => {
		let newArr = [...input_items];
		newArr[cnt]['itemCustomData'] = itemCustomData[cnt];
		setInputItems(newArr);
		setItemCustomDataModel(false);
	}
    return(
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Layout site_logo={props.site_logo} label="add-po" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar site={props.site_name} url={props.url}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3"><span className="text-muted fw-light">Expenses & Payments /</span> {(actionType === 'copy')?'Add PO Contract':'Edit Draft'}</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
										<form id="frmPurs" onSubmit={handleSubmit} autoComplete="off"  encType='multipart/form-data'>
												<div className="row" id="purchasedAddDiv">
													<div className="col">
														<div className="accordion" id="purchaseAccordion">
															<div className="card accordion-item active">
																<h2 className="accordion-header" id="headingOne">
																	<button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionMyDetails" aria-expanded="false" aria-controls="accordionMyDetails">
																		{profile_details.name}
																	</button>
																</h2>
																<div id="accordionMyDetails" className="accordion-collapse collapse" data-bs-parent="#purchaseAccordion" >
																	<div className="accordion-body py-3">
																		<div className="row">
																			{!p1 && <>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurGstin" placeholder="Enter GSTIN" value={profile_details.gstin} disabled/>
																					<label htmlFor="frmPurGstin">GSTIN</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurLegalNm" placeholder="Enter Legal Name" value={profile_details.legal_name} disabled/>
																					<label htmlFor="frmPurLegalNm">Legal Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurTradeNm" placeholder="Enter Trade Name" value={profile_details.trade_name} disabled/>
																					<label htmlFor="frmPurTradeNm">Trade Name</label>
																				</div>
																			</div>
																			</>}
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurAddr" placeholder="Enter Address" value={profile_details.addr} disabled/>
																					<label htmlFor="frmPurAddr">Address</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurLoc" placeholder="Enter Location" value={profile_details.district} disabled/>
																					<label htmlFor="frmPurLoc">Location</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Select classNamePrefix="react-select" className="form-control" id="frmPurState" placeholder="Select State" name='state' value={state_master[Common.stateIndex(state_master,profile_details.state)]} options={state_master} isDisabled={true} disabled/>
																					<label htmlFor="frmPurState">State</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurPincode" placeholder="Enter Pincode" value={profile_details.pincode} disabled/>
																					<label htmlFor="frmPurPincode">Pincode</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingTwo">
																	<button type="button" className="accordion-button" data-bs-target="#accordionSeller" aria-expanded="false" aria-controls="accordionSeller" disabled>
																		Seller Details
																	</button>
																</h2>
																<div id="accordionSeller" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		<div className="row mb-2">
																			<Button className="text-end" variant="" size="sm" onClick={()=>customerListModal('show')}><Common.OverlayTrigger overlay={Common.popoverContent('Seller List','Click to search seller by Name, GSTIN, Phone, Email.')}><i className='bx bxs-right-top-arrow-circle'></i></Common.OverlayTrigger></Button>
																			<div className="col-md-6 col-sm-6">
																				<div className="form-floating">
																					<AsyncTypeahead selected={seller_name} clearButton allowNew newSelectionPrefix="Add: " filterBy={filterBy} id="purchase-seller" className="floatingTypeahead" isLoading={isCustLoading} labelKey="party_name" minLength={3} onSearch={sellerSearch} options={sellers} placeholder="Search (min 3 chars)" onChange={(e) => setSellerDetails(e)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.party_name}
																								</Highlighter>
																								<div>
																									{(option.gstin !== 'URP' && option.gstin !== '') && <><small>Gstin: {option.gstin}</small>, </>}
																									<small>Mobile: {option.mobile}</small>
																								</div>
																							</div>
																						)}
																					/>
																					<label htmlFor="frmPurSName">Seller Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6">
																				<div className="form-floating">
																					<input name="seller_phone" type="text" maxLength="10" className="form-control" id="frmPurCPhone" placeholder="Enter Phone" value={seller_phone} onChange={(e) => setSellerPhone(Common.acceptOnlyNumbers(e.target.value))} disabled={seller_phone_disabled}/>
																					{seller_phone_disabled && <span id="custPhoneEdit"
																						onClick = {function(e){
																							setSellerPhoneDisabled(false);
																							setUpdateSeller(1);
																						}}><Common.OverlayTrigger overlay={Common.popoverContent('Click to Edit Phone','On submit, phone number will be updated for the customer.')}><i className="bx bx-edit"></i></Common.OverlayTrigger></span>}
																					<label htmlFor="frmPurCPhone">Phone</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (sellerAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showSellerAdditional}>{sellerAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (sellerAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{sellerAddiDiv && <>
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_gstin" type="text" className="form-control" id="frmPurCGstin" placeholder="Enter Gstin" value={seller_gstin} maxLength="15" onKeyDown={(e)=>handleGstinData('seller',e)} onChange={(e) => setSellerGstinSelect(Common.acceptOnlyCharsNumbers(e.target.value.toUpperCase()))}/>
																					<label htmlFor="frmPurCGstin">Gstin</label>
																					<div className="form-text">Press Enter To auto fetch GSTIN Data</div>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_legal_name" type="text" className="form-control" id="frmPurCPhone" placeholder="Enter Legal Name" value={seller_legal_name} maxLength="100" onChange={(e) => setSellerLegalName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPurCPhone">Legal Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_trade_name" type="text" className="form-control" id="frmPurCPhone" placeholder="Enter Trade Name" value={seller_trade_name} maxLength="100" onChange={(e) => setSellerTradeName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPurCPhone">Trade Name</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_pincode" type="text" className="form-control" id="frmPurCPincode" placeholder="Enter Pin Code" value={seller_pincode} maxLength="6" onChange={(e) => populatePinData('customer',Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmPurCPincode">Pin Code</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_addr" type="text" className="form-control" id="frmPurCAddr" placeholder="Enter Address" value={seller_addr} maxLength="100" onChange={(e) => setSellerAddr(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmPurCAddr">Address</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="seller_district" type="text" className="form-control" id="frmPurCDist" placeholder="Enter District" value={seller_district} maxLength="100" onChange={(e) => setSellerDistrict(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPurCDist">District</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmPurCState" labelKey="label" key="value" className="floatingTypeahead" placeholder="Select State"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={seller_state.length > 0 ? [state_master.find(obj=>{return obj.value == seller_state[0].value})] : []}
																						onChange={(e)=>{setSellerState(e)}}
																					/>
																					<label htmlFor="frmPurCState">State</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmPurPOS" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setSellerPOS(e)} placeholder="Select POS"
																						options={state_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={seller_pos.length > 0 ? [state_master.find(obj=>{return obj.digits == seller_pos[0].digits})] : []}
																					/>
																					<label htmlFor="frmPurPOS">POS</label>
																				</div>
																			</div>
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingThree">
																	<button type="button" className="accordion-button" data-bs-target="#accordionInvoice" aria-expanded="false" aria-controls="accordionInvoice" disabled>
																		Invoice Data
																	</button>
																</h2>
																<div id="accordionInvoice" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-3 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="doc_no" type="text" className="form-control" maxLength="16" id="frmPurInvNo" placeholder="Enter Doc No." value={doc_no} readOnly/>
																					<label htmlFor="frmPurInvNo">Document No<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="doc_date" data-testid="frmPurDocDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setDocDate} value={doc_date} />
																					<label htmlFor="frmPurDocDate">Document Date<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmPurDocType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDocTypeSelect(e)} placeholder="Choose ..."
																						options={po_doc_types}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={doc_type.length > 0 ? [po_doc_types.find(obj=>{return obj.value == doc_type[0].value})] : []}
																					/>
																					<label htmlFor="frmPurDocType">Document Type <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																		{/* <div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="po_no" type="text" className="form-control" maxLength="16" id="frmPurPONo" placeholder="Enter PO No." value={po_no} onChange={(e) => setPoNo(e.target.value)}/>
																					<label htmlFor="frmPurPONo">PO/Contract Number</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="po_date" data-testid="frmPurPODt" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setPoDate} value={po_date} />
																					<label htmlFor="frmPurPODt">PO/Contract Date</label>
																				</div>
																			</div>
																		</div> */}
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (invAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={()=>setInvAddiDiv( (invAddiDiv) ? false : true )}>{invAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (invAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{invAddiDiv && <>
																		<div className="row">
																			<div className="col-md-6 col-sm-4 mb-3">
																				<div className="form-floating">
																				<input type="text" className="form-control" id="etin" placeholder="ECOM GSTIN" value={etin} maxLength={15} onChange={(e)=>setEtin(e.target.value)}/>
																					<label htmlFor="Etin">ECOM GSTIN</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton  id="frmPurSupplyType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setSupplyType(e)} options={supplyTypes} placeholder="Choose supply type"
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={supply_type.length > 0 ? [supplyTypes.find(obj=>{return obj.value == supply_type[0].value})] : []}
																					/>
																					<label htmlFor="frmPurSupplyType">Supply Type</label>
																				</div>
																			</div>
																			
																		</div>
																		<div className="row">
																			<div className="col-md-3 col-sm-6 mb-3">
																				<label htmlFor="frmPurRevChrg">Reverse Charge</label>
																				<div className="col">
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="rev_chrg" id="revchrgYes" value="Y" onChange={(e) => setRevChrg(e.target.value)} checked={rev_chrg === "Y"}/>
																						<label className="form-check-label" htmlFor="revchrgYes">Yes</label>
																					</div>
																					<div className="form-check form-check-inline">
																						<input className="form-check-input" type="radio" name="rev_chrg" id="revchrgNo" value="N" onChange={(e) => setRevChrg(e.target.value)} checked={rev_chrg === "N"}/>
																						<label className="form-check-label" htmlFor="revchrgNo">No</label>
																					</div>
																				</div>
																			</div>
																			{cdnoteFields && <>
																			<div className="col-md-5 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" placeholder="Reference Invoice No." name="cdn_ref_inv_no" id="frmPurRefInvNo" value={ref_inv_no} onChange={(e)=>setRefInvNo(e.target.value)}/>
																					<label htmlFor="frmPurRefInvNo">Reference Invoice No.</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="cdn_ref_inv_dt" data-testid="frmPurRefInvDt" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setRefInvDt} value={ref_inv_dt} />
																					<label htmlFor="frmPurRefInvDt">Reference Invoice Date</label>
																				</div>
																			</div>
																			</>}
																			<div className={cdnoteFields ? "col-md-6 col-sm-6 mb-3" : "col-md-5 col-sm-6 mb-3"}>
																				<div className="form-floating">
																					<input name="irn_no" type="text" className="form-control" placeholder="IRN Number" id="frmPurIrnNo" value={irn_no} onChange={(e)=>setIrnNo(e.target.value)}/>
																					<label htmlFor="frmPurIrnNo">IRN Number</label>
																				</div>
																			</div>
																			<div className={cdnoteFields ? "col-md-6 col-sm-6 mb-3" : "col-md-4 col-sm-6 mb-3"}>
																				<div className="form-floating">
																					<DatePicker name="irn_date" data-testid="frmPurIrnDt" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setIrnDt} value={irn_dt} />
																					<label htmlFor="frmPurRefInvDt">IRN Generated Date</label>
																				</div>
																			</div>
																			
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															{itemDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingFour">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionItem" aria-expanded="false" aria-controls="accordionItem">
																		Item Data
																	</button>
																</h2>
																<div id="accordionItem" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		{ sale_items_loop.map((count) => (
																		<div key={'item_'+count} id={'item_'+count} className="row">
																			<div className="col-6 my-1">
																			 <div className="row">
																			  <div className="mb-3 col">
																					<strong>Item: {count+1}</strong> {input_items.length > 1 && <button onClick={() => {removeItem(count,true);itemRefs.current[count]?.clear();}} className="btn btn-sm btn-danger" type="button"><i className='bx bx-trash'></i></button>} 
																			  </div>
																			  <div className="mb-3 col text-right">
																				{totalItemCount[0] && totalItemCount[0].name == count && totalItemCount[0].value == 0 && <span><i className='bx bxs-bolt'></i><span className='text-info cursor-pointer' onClick={() => {quickAddItem()}}>Quick Add</span></span>}
																			  </div>
																			 </div>
																			</div>
																			<div className="col-6 my-1"></div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<AsyncTypeahead clearButton useCache={false} ref={el => (itemRefs.current[count] = el)} disabled={input_items[count] && input_items[count]['disabled']} filterBy={filterBy} id={'frmPurItmName_'+count} className="floatingTypeahead" isLoading={isItemLoading} labelKey="name" onSearch={term => {itemSearch(term,count);}} options={items} placeholder="Search (min 3 chars)" onChange={(e) => setItemDetails(e,count)}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.name}
																								</Highlighter>
																								<div>
																									<small>Price: {Common.displayPrice((option.total_price)?option.total_price:0)}</small>
																									{option.item_service === '0' && option.stock_movement === '1' && <>
																									, <small>Available: {option.current_stock === '0.00' ? 'Out of Stock' : Number(option.current_stock)}</small>
																									</>}
																								</div>
																							</div>
																						)}
																						selected={(input_items[count] ? [input_items[count]] : [])}
																						emptyLabel = "Add as new in Inventory mgmt."
																					/>
																					{input_items[count] && input_items[count]['disabled'] && <span id="itemDelete"
																						onClick = {function(e){
																							removeItem(count);
																							itemRefs.current[count]?.clear();
																						}}><i className="bx bx-x"></i></span>}
																					<label htmlFor={"frmPurItmName_"+count}>Item Name</label>
																				</div>
																				{input_items[count] && input_items[count]['current_stock'] !== undefined && input_items[count]['item_service'] === '0' && input_items[count]['stock_movement'] === '1' && <>
																				<div className="form-text">
																					Current Stock: {input_items[count]['current_stock'] === '0.00' ? 'Out of Stock' : Number(input_items[count]['current_stock'])}
																				</div>
																				</>}
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmPurItmDesc_"+count} placeholder="Enter Description" disabled={input_items[count]? false : true} value={(input_items[count] && input_items[count]['description']) ? input_items[count]['description'] : ''} onChange={(e)=>setItemDesc(count,e.target.value)}/>
																					<label htmlFor={"frmPurItmDesc_"+count}>Item Description</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" step=".001" min="0" className="form-control" id={'frmPurItmQty_'+count} placeholder="Enter quantity" value={(input_items[count] && input_items[count]['item_qty']) ? input_items[count]['item_qty'] : ''} onChange={(e) => setItemQty(Common.acceptOnlyDecimal(e.target.value),count,docGstRate)} disabled={input_items[count]? false : true} readOnly={ (input_items[count] && input_items[count]['item_service'] === '1')?true:false }/>
																					<label htmlFor={'frmPurItmQty_'+count}>Quantity</label>
																				</div>
																				{input_items[count] && input_items[count]['min_qty'] > 0 && <>
																				<div className="form-text">
																					Min Order Qty: {Common.displayPercent(input_items[count]['min_qty']) || ''}
																				</div>
																				</>}
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmPurItmUnit_"+count} placeholder="Enter Unit" value={(input_items[count] && input_items[count]['unit_name']) ? input_items[count]['unit_name'] : ''} readOnly/>
																					<label htmlFor={"frmPurItmUnit_"+count}>Unit</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmPurItmHsn_"+count} placeholder="Enter HSN" value={(input_items[count] && input_items[count]['hsn']) ? input_items[count]['hsn'] : ''} readOnly/>
																					<label htmlFor={"frmPurItmHsn_"+count}>HSN</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmPurItmPrice_"+count} placeholder="Enter Price" value={(input_items[count] && input_items[count]['price']) ? input_items[count]['price'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(e.target.value,count,'item_price', docGstRate)}/>
																					<label htmlFor={"frmPurItmPrice_"+count}>Price/Unit</label>
																				</div>
																			</div>
																			{/* <div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id={"frmPurItmDiscount_"+count} placeholder="Enter Discount" value={(input_items[count] && input_items[count]['item_discount']) ? input_items[count]['item_discount'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(e.target.value,count,'item_discount')}/>
																					<label htmlFor={"frmPurItmDiscount_"+count}>Discount</label>
																				</div>
																			</div> */}
																			<div className="col-md-2 col-sm-3 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmDiscount_"+count} placeholder="Enter Discount" value={(input_items[count] && input_items[count]['item_discount']) ? input_items[count]['item_discount'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(e.target.value,count,'item_discount', docGstRate)} />
																					<label htmlFor={"frmSaleItmDiscount_"+count}>Disc in ₹</label>
																				</div>
																			</div>
																			<div className="col-md-2 col-sm-3 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id={"frmSaleItmDiscountPer_"+count} placeholder="Enter Discount %" value={(input_items[count] && input_items[count]['item_discount_per']) ? input_items[count]['item_discount_per'] : ''} readOnly={ (input_items[count])?false:true } onChange={(e) => handleItemPrice(e.target.value,count,'item_discount_per', docGstRate)} />
																					<label htmlFor={"frmSaleItmDiscountPer_"+count}>Disc in %</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead disabled={ (input_items[count])?false:true } clearButton id="gst_rate" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => {handleItemPrice((e[0] ? e[0].val : ''),count,'gst_rate',docGstRate,e[0] ? e[0].id : '');}} placeholder="Select GST"
																						options={taxes}
																						labelKey="rate"
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.rate}
																							</Highlighter>
																						)}
																						selected={input_items[count] && input_items[count]['gst_rate_id'] ? [taxes.find(obj=>{return obj.id == input_items[count]['gst_rate_id']})] : []}
																					/>
																					<label htmlFor={"frmPurItmGstRate_"+count}>GST Rate</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id="frmPurItmIgst_1" placeholder="Enter IGST" value={(input_items[count] && input_items[count]['igst_amt']) ? input_items[count]['igst_amt'] : ''} readOnly/>
																					<label htmlFor="frmPurItmIgst_1">IGST Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id={'frmPurItmCgst_'+count} placeholder="Enter CGST" value={(input_items[count] && input_items[count]['cgst_amt']) ? input_items[count]['cgst_amt'] : ''} readOnly/>
																					<label htmlFor={'frmPurItmCgst_'+count}>CGST Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id="frmPurItmSgst_1" placeholder="Enter SGST" value={(input_items[count] && input_items[count]['sgst_amt']) ? input_items[count]['sgst_amt'] : ''} readOnly/>
																					<label htmlFor="frmPurItmSgst_1">SGST Amount</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmPurItmTotPrice_1" placeholder="Enter Total" value={(input_items[count] && input_items[count]['item_tot_price']) ? input_items[count]['item_tot_price'] : ''} readOnly={ (input_items[count])?false:true }  onChange={(e) => handleItemPrice(e.target.value,count,'item_tot_price', docGstRate)}/>
																					<label htmlFor="frmPurItmTotPrice_1">Total Price</label>
																				</div>
																			</div>
																			{(input_items[count] && input_items[count]['id']) && <>
																			<div className="col-md-4 col-sm-6 py-3">
																				<span className='text-info cursor-pointer' onClick={() => {getItemCustomData(input_items[count]['id'],count)}}>Custom Fields</span>
																			</div>
																			</>}
																			{input_items[count] && input_items[count]['itemCustomData'] && <>
																				<div className='col-md-12 col-sm-6 mb-3'>
																					<small><b>Custom Fields</b></small>
																					<div className='row'>
																						{input_items[count]['itemCustomData'].map((vals,inds) => (<>
																							{vals.item_field_value && <>
																								<div className='col-md-3 col-sm-3'>
																									<small>{vals.item_field_name} : {vals.item_field_value}</small>
																								</div>
																							</>}
																						</>))}
																					</div>
																				</div>
																			</>}
																		</div>
																		))}
																		<div className="text-center"><button type="button" onClick={addAnotherItem} className="pull-right btn btn-sm btn-primary"><i className='bx bx-plus'></i> Add Item</button></div>
																	</div>
																</div>
															</div>
															</>}
															{impDetDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSix">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionImpDet" aria-expanded="false" aria-controls="accordionImpDet">
																		Import Details
																	</button>
																</h2>
																<div id="accordionImpDet" className="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="tradeName" type="text" maxLength="100" className="form-control" id="frmPurTradeName" placeholder="Trade name of supplier" value={tradeName} onChange={(e)=>setTradeName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPurTradeName">Trade name of supplier<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="portCode" type="text" maxLength="10" className="form-control" id="frmPurPortCode" placeholder="Enter Port Code" value={portCode} onChange={(e)=>setPortCode(Common.acceptOnlyCharsNumbers(e.target.value))}/>
																					<label htmlFor="frmPurPortCode">Port Code<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<DatePicker name="ref_date" data-testid="frmPurRefDt" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setRefDate} value={ref_date} />
																					<label htmlFor="frmPurRefDt">Reference Date<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead clearButton id="frmPurInvType" labelKey="label" key="value" className="floatingTypeahead" placeholder="Select State"
																						options={invoiceType}
																						renderMenuItemChildren={(option, { text }) => (
																							<Highlighter search={text}>
																								{option.label}
																							</Highlighter>
																						)}
																						selected={invoice_type.length > 0 ? [invoiceType.find(obj=>{return obj.value == invoice_type[0].value})] : []}
																						
																						onChange={(e)=>{setInvoiceType(e)}}
																					/>
																					<label htmlFor="frmPurInvType">Invoice Type <span className="text-danger">*</span></label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															{sellerBankDiv && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSeven">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#accordionSellerBank" aria-expanded="false" aria-controls="accordionSellerBank">
																		Seller Bank Details
																	</button>
																</h2>
																<div id="accordionSellerBank" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={defaultPayee} clearButton allowNew newSelectionPrefix="Add: " id="purchase-payee" labelKey="payee_name" key="id" className="floatingTypeahead moreData" placeholder="Payee Name"
																						options={payees}
																						renderMenuItemChildren={(option: Item,props) => (
																							<div>
																								<Highlighter search={props.text}>
																									{option.payee_name}
																								</Highlighter>
																								<div>
																									<small>Bank Name: {option.bank_name}</small>, <small>Acc No.: {option.account_no}</small>
																								</div>
																							</div>
																						)}
																						onChange={(e)=>{setPayeeDetails(e)}}
																					/>
																					<label htmlFor="purchase-payee">Payee Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="account_no" type="text" maxLength="20" className="form-control" id="frmPurBankAccNo" placeholder="Max 20 chars" value={account_no} onChange={(e)=>setAccountNo(Common.acceptOnlyNumbers(e.target.value))}/>
																					<label htmlFor="frmPurBankAccNo">Account Number <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="ifsc_code" type="text" maxLength="11" className="form-control" id="frmPurIfscCode" placeholder="Max 11 chars" value={ifsc_code} onChange={(e)=>setIfscCode(Common.acceptOnlyCharsNumbers(e.target.value.toUpperCase()))}/>
																					<label htmlFor="frmPurIfscCode">IFSC Code <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input name="bank_name" type="text" maxLength="100" className="form-control" id="frmPurBankName" placeholder="Enter (3-100) chars" value={bank_name} onChange={(e)=>setBankName(Common.acceptOnlyChars(e.target.value))}/>
																					<label htmlFor="frmPurBankName">Bank Name <span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					  <textarea style={{height:'100px'}} id="payment_terms" name="payment_terms" className="form-control" placeholder="Payment Terms" value={payment_terms} onChange={(e) => setPaymentTerms(e.target.value)}></textarea>
																					  <label htmlFor="payment_terms">Payment Terms</label>
																				</div>
																			</div>
																			<div className="col-md-6 col-sm-6 mb-3">
																				<div className="form-floating">
																					  <textarea style={{height:'100px'}} id="payment_instructions" name="payment_instructions" className="form-control" placeholder="Payment Instructions" value={payment_instructions} onChange={(e) => setPaymentInstructions(e.target.value)}></textarea>
																					  <label htmlFor="payment_instructions">Payment Instruction</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															{customInvFields && <>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingSeven">
																	<button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#customInvFields" aria-expanded="false" aria-controls="accordioncustomInvFields">
																		Custom Invoice Fields
																	</button>
																</h2>
																<div id="customInvFields" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			{customInputList}
																			<div className="mb-3 text-center">
																				<button type="button" className="btn btn-sm btn-primary me-2" onClick={handleCustomInvFields}><i className='bx bx-plus'></i> Add Fields</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															</>}
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingThreeA">
																	<button type="button" className="accordion-button" data-bs-target="#accordionInvoiceVal" aria-expanded="false" aria-controls="accordionInvoice" disabled>
																		Document Value
																	</button>
																</h2>
																<div id="accordionInvoiceVal" className="accordion-collapse collapse show" aria-labelledby="headingThreeA" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocTaxableAmount" value={doc_taxable_amt} readOnly/>
																					<label htmlFor="frmSaleDocTaxableAmount">Total Base Value</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocGstAmount" value={doc_gst_amt} readOnly/>
																					<label htmlFor="frmSaleDocGstAmount">GST Value</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocTotalAmount" value={doc_total_amt} readOnly/>
																					<label htmlFor="frmSaleDocTotalAmount">Total Value</label>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col">
																				<div className="divider">
																					<div className="divider-text"><i className={'bx bx-chevrons-'+ (docTaxAddiDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showDocTaxAdditional}>{docTaxAddiDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (docTaxAddiDiv ? "up":"down")+' bx-fade-up'}></i></div>
																				</div>
																			</div>
																		</div>
																		{docTaxAddiDiv && <>
																		{docGstRate.map((vals,inds) => (
																			<div key={'main_gst'+inds}>
																			{Object.keys(vals).map((key5, index5) => {
																				return (
																					<div className="row" key={'gst'+inds}>
																						<div className="col-md-4 col-sm-6 mb-3">
																							<div className="row">
																								<div className="col-md-8 col-sm-6 mb-3">
																									<div className="form-floating">
																										<input type="text" className="form-control" id="frmSaleDocIgst" value={vals[key5].igst_amt} readOnly/>
																										<label htmlFor="frmSaleDocIgst">IGST</label>
																									</div>
																								</div>
																								<div className="col-md-4 col-sm-6 mb-3 ms-auto mt-auto mb-0 text-end ps-0">
																									<span>@{(key5!='0.00')?key5:0}%</span>
																								</div>
																							</div>
																						</div>

																						<div className="col-md-4 col-sm-6 mb-3">
																							<div className="row">
																								<div className="col-md-8 col-sm-6 mb-3">
																									<div className="form-floating">
																										<input type="text" className="form-control" id="frmSaleDocCgst" value={vals[key5].cgst_amt} readOnly/>
																										<label htmlFor="frmSaleDocCgst">CGST</label>
																									</div>
																								</div>
																								<div className="col-md-4 col-sm-6 mb-3 ms-auto mt-auto mb-0 text-end ps-0">
																									<span>@{(key5!='0.00')?(key5 / 2).toFixed(2):0 }%</span>
																								</div>
																							</div>
																						</div>


																						<div className="col-md-4 col-sm-6 mb-3">
																							<div className="row">
																								<div className="col-md-8 col-sm-6 mb-3">
																									<div className="form-floating">
																										<input type="text" className="form-control" id="frmSaleDocSgst" value={vals[key5].sgst_amt} readOnly/>
																										<label htmlFor="frmSaleDocSgst">SGST</label>
																									</div>
																								</div>
																								<div className="col-md-4 col-sm-6 mb-3 ms-auto mt-auto mb-0 text-end ps-0">
																									<span>@{(key5!='0.00')?(key5 / 2).toFixed(2):0 }%</span>
																								</div>
																							</div>
																						</div>
																					</div>
																				);
																			})}
																			</div>
																		))}
																		<div className="row">
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocDiscount" value={docDiscount} placeholder="0.00" onChange={(e) => { setDocDiscount(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(docOtherCharge,docRoundoff,Common.acceptOnlyDecimal(e.target.value)); }} />
																					<label htmlFor="frmSaleDocDiscount">Addl Discount after GST</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="text" className="form-control" id="frmSaleDocOtherCharge" value={docOtherCharge} placeholder="0.00" onChange={(e) => { setDocOtherCharge(Common.acceptOnlyDecimal(e.target.value));finalDocTotalCal(Common.acceptOnlyDecimal(e.target.value),docRoundoff,docDiscount); }} />
																					<label htmlFor="frmSaleDocOtherCharge">Other Charge</label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-6 mb-3">
																				<div className="form-floating">
																					<input type="number" className="form-control" id="frmSaleDocRoundoff" value={docRoundoff} placeholder="0.00" onChange={(e) => { handleDocRoundoff(e.target.value); }} />
																					<label htmlFor="frmSaleDocRoundoff">Round off</label>
																				</div>
																			</div>
																		</div>
																		</>}
																	</div>
																</div>
															</div>
															<div className="card accordion-item">
																<h2 className="accordion-header" id="headingThreeA">
																	<button type="button" className="accordion-button" data-bs-target="#accordionInvoiceVal" aria-expanded="false" aria-controls="accordionInvoice" disabled>
																		Others
																	</button>
																</h2>
																<div id="accordionInvoiceVal" className="accordion-collapse collapse show" aria-labelledby="headingThreeA" data-bs-parent="#purchaseAccordion">
																	<div className="accordion-body py-3">
																		<div className="row">
																		<div className="col-md-4 col-sm-4 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={acentreSel} clearButton allowNew newSelectionPrefix="Add: " id="frmPurAllocCentre" labelKey="name" key="id" className="floatingTypeahead" placeholder="Select Allocation Centre"
																						options={allocation_centre_master}
																						renderMenuItemChildren={(option: Item,props) => (
																							<Highlighter search={props.text}>
																								{option.name}
																							</Highlighter>
																						)}
																						onChange={(e)=>{setAllocationCentreSelect(e)}}
																					/>
																					<label htmlFor="frmPurAllocCentre">Allocation Centre</label>
																					<div className="form-text" style={{'fontSize':'60%'}}>Use this field to maintain custom cost/profit centers</div>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-4 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={expenseTypeSel} clearButton id="frmPurExpenseType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setExpenseTypeSelect(e)} options={expense_type_master} placeholder="Choose account head"
																						renderMenuItemChildren={(option: Item,props) => (
																							<>
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																							<div>
																								<small>{option.description}</small>
																							</div>
																							</>
																						)}
																					/>
																					<label htmlFor="frmPurExpenseType">Expense Type<span className="text-danger">*</span></label>
																				</div>
																			</div>
																			<div className="col-md-4 col-sm-4 mb-3">
																				<div className="form-floating">
																					<Typeahead selected={expenseSubTypeSel} clearButton allowNew newSelectionPrefix="Add: " disabled={isAccountHeadSelected === false ? true : false} id="frmSaleExpenseType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setExpenseSubTypeSelect(e)} options={expense_subtype_master} placeholder="Choose Expense SubType"
																						renderMenuItemChildren={(option: Item,props) => (
																							<>
																							<Highlighter search={props.text}>
																								{option.label}
																							</Highlighter>
																							<div>
																								<small>{option.label}</small>
																							</div>
																							</>
																						)}
																					/>
																					<label htmlFor="frmSaleExpenseType">Expense Sub Type</label>
																				</div>
																			</div>
																		</div>																		
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-3">
														<input className="form-control form-control-sm mb-2" id="formFileSm" name="formFileSm" type="file" onChange={handleDocument} />
														<div className="list-group">
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={itemDiv === true} onChange={toggleItemDiv}/>
																Item Data
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={impDetDiv === true} onChange={toggeimpDetDiv}/>
																Import Details
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={sellerBankDiv === true} onChange={toggeSellerBankDiv}/>
																Seller Bank Details
															</label>
															<label className="list-group-item">
																<input className="form-check-input me-1" type="checkbox" checked={customInvFields === true} onChange={toggeCustomInvFields}/>
																Custom Invoice Fields
															</label>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<div className="divider">
															<div className="divider-text">Invoice Summary</div>
														</div>
													</div>
												</div>
												<div id="summaryDiv" className="row justify-content-md-center">
												{/* <div className="col-md-3"></div> */}
													<div className="col-md-5" id="summaryDivR">
														<div className="card">
															<div className="row">
																<label className="col-4 col-form-label" htmlFor="frmPurTotal">Total <span className="text-danger">*</span></label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<span className={"input-group-text "+(total_amt_disabled?'disabled-bg':'')}><i className='bx bx-rupee'></i></span>
																		<input type="text" name="total_amt" className="form-control" id="frmPurTotal" placeholder="0.00" value={total_amt} onChange={(e) => { setTotalAmt(Common.acceptOnlyDecimal(e.target.value)) }} readOnly={total_amt_disabled} required/>
																	</div>
																</div>
																<div className="col-12">
																	{ total_amt > 0 && <>
																	<div className="text-right mt-1">
																	{ advanceBalance > 0 && <>
																		<Button variant="info" size="sm" onClick={()=>{advanceModal('show');}}><i className='bx bx-plus' ></i> Advance adjust</Button>&nbsp;&nbsp;
																	</>}
																		{/* <button type="button" role="button" onClick={()=>{paymentModal('show');if(due < 0) {setReceived('0.00');} else {setReceived(due);}}} className="btn btn-sm btn-success"><i className='bx bx-plus' ></i> Payment</button> */}
																	</div>
																	</>}
																</div>
															</div>
															<div className="row mt-2">
																<label className="col-4 col-form-label" htmlFor="frmExpiryDate">Expiry Date <span className="text-danger">*</span></label>
																<div className="col-8">
																	<div className="input-group input-group-merge">
																		<DatePicker name="expiry_date" data-testid="frmExpiryDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" className="form-control" onChange={setExpiryDate} value={expiry_date} />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row mt-5">
													<div className="text-center">
													{/* { submitType === '0' && <> */}
														<a href="/po-listing" role="button" className="me-3 btn btn-sm btn-info"><i className='bx bx-arrow-back'></i> Back</a>
														{/* <button type="button" onClick={(e)=>setSubmitType('1')} className="btn btn-sm btn-secondary me-3" ><i className='bx bx-copy-alt'></i>&nbsp;Save Draft</button> */}
														<button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
													{/* </>} */}
													{/* { submitType === '1' && <>
														<button type="button" className="me-3 btn btn-sm btn-info" disabled><i className='bx bx-arrow-back' disabled></i> Back</button>
														<button type="button" onClick={(e)=>setSubmitType('0')} className="btn btn-sm btn-danger me-3" ><i className='bx bx-message-square-x'></i>&nbsp;Save Draft</button>
														<button type="submit" onClick={(e)=>setSubmitType('0')} className="btn btn-sm btn-primary" disabled><span className="tf-icons bx bx-save"></span>&nbsp;Save Invoice 1</button>
														<div className="mt-3">
															<button type="submit" className='btn btn-sm btn-success' onClick={(e)=>setSaveDraftInvoice('1')}>Save with Invoice Number</button>&nbsp;&nbsp;
															<button type="submit" className='btn btn-sm btn-primary' onClick={(e)=>setSaveDraftInvoice('0')}>Save without Invoice Number</button>
														</div>
													</>} */}
													</div>
												</div>
											</form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Common.FYNTicket url={props.url}/>
                </div>
            </div>
        </div>
		<Modal size={ deduction_div ? 'xl':'md'} show={payModalShow} onHide={()=>paymentModal('hide')} aria-labelledby="payment-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4 modal-mobile-view">
					<Modal.Title id="payment-modal">Payment {payments.length + 1}</Modal.Title>
				</div>
				<div className="col text-right">
					<span id="payModalInfo" className="badge bg-warning">
						Total Invoice Value: {Common.displayPrice(total_amt)}
						{ deduction_div && <>
						, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
						</>}
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className={deduction_div ? 'col-sm-6':''}>
						<div className="row">
							<label className="col-4 col-form-label" htmlFor="frmPurTotal">Amount <span className="text-danger">*</span></label>
							<div className="col-8">
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className='bx bx-rupee'></i></span>
									<input ref={paymentReceivedRef} type="text" className="form-control" id="frmPurTotal" placeholder="0.00" value={received}
										onChange={(e) => {
											setReceived(Common.acceptOnlyDecimal(e.target.value));
											setDeductionPer('');
											setDeductionAmt('');
										}}
									autoComplete="off"/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmPurPayDate">Payment Date</label>
							<div className="col-8">
								<DatePicker data-testid="frmPurPayDate" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setPaymentDate} value={payment_date} />
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmPurPayMode">Payment Mode</label>
							<div className="col-8">
								<Typeahead clearButton defaultSelected={paymentModes.slice(1, 2)} id="frmPurPayMode" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setPaymentModeSelect(e)} placeholder="Choose Payment Mode"
									options={paymentModes}
									renderMenuItemChildren={(option: Item,props) => (
										<Highlighter search={props.text}>
											{option.label}
										</Highlighter>
									)}
								/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmBankAccount">Bank Account <span className="text-danger">*</span></label>
							<div className="col-8">
								<Typeahead disabled={payment_mode === 'Cash'?true:false} selected={defaultBank} clearButton id="frmBankAccount" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setBankAccountSelect(e)} placeholder="Choose Bank Account"
									options={[]}
									renderMenuItemChildren={(option: Item,props) => (
										<Highlighter search={props.text}>
											{option.label}
										</Highlighter>
									)}
								/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmPurPayRemarks">Payment Remarks</label>
							<div className="col-8">
								<input type="text" className="form-control" id="frmPurPayRemarks" placeholder="Payment Remarks" value={payment_remarks} onChange={(e) => setPaymentRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
							</div>
						</div>
						<div className="row mt-3">
							<label className="col-4 col-form-label" htmlFor="frmPurTransRef">Transaction Ref. No.</label>
							<div className="col-8">
								<input type="text" maxLength="100" className="form-control" id="frmPurTransRef" placeholder="Transaction Ref." value={trans_ref} onChange={(e) => setTransRef(e.target.value)} autoComplete="off"/>
							</div>
						</div>
					</div>
					<div className={deduction_div ? 'col-sm-6':'d-none'}>
						{/*<div className="form-text text-right text-info">
							Total Invoice Value: {Common.displayPrice(total_amt)}, Total Deduction added: {Common.displayPrice(Number(total_deduction))}
						</div>*/}
						<div className="card">
							<h6 className="card-header">Deductions</h6>
							{deductions.length === 0 && <div className="text-center py-1">No deduction</div>}
							{deductions.length > 0 && <>
							<div className="table-responsive text-nowrap">
								<table className="table table-sm table-bordered">
									<thead className="table-light">
										<tr>
											<th align="center">Action</th>
											<th>Sl.</th>
											<th align="right">Deduction (%)</th>
											<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
											<th>Type</th>
											<th>Remarks</th>
										</tr>
									</thead>
									<tbody className="table-border-bottom-0">
										{deductions.map((val,index) => (
											<tr key={'ded'+index}>
												<td align="center">
													<button type="button" className="btn btn-sm" onClick={() => removeDeduction(index)}><i className='bx bx-trash'></i></button>
												</td>
												<td>{index+1}</td>
												<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
												<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
												<td>{val.deduction_type}</td>
												<td>{val.deduction_remarks}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							</>}
						</div>
						<div className="alert alert-secondary mt-3">
							<div className="row">
								<h4>Add Deduction</h4>
							</div>
							<div className="row">
								<div className="col-6">
									<div className="row">
										<label className="col-sm-5 col-form-label" htmlFor="frmPurDedPer">Percent <span className="text-danger">*</span></label>
										<div className="col-sm-7">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bxs-offer'></i></span>
												<input type="text" className="form-control" id="frmPurDedPer" placeholder="0.00" value={deduction_per} onFocus={checkPayment} onChange={(e) => calcDeduction('per',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
									<div className="row text-right">
										<small>(Deduction at source and on base value)</small>
									</div>
								</div>
								<div className="col-6">
									<div className="row">
										<label className="col-sm-4 col-form-label" htmlFor="frmPurDedAmt">Amount <span className="text-danger">*</span></label>
										<div className="col-sm-8">
											<div className="input-group input-group-merge">
												<span className="input-group-text"><i className='bx bx-rupee'></i></span>
												<input type="text" className="form-control" id="frmPurDedAmt" placeholder="0.00" value={deduction_amt} onFocus={checkPayment} onChange={(e) => calcDeduction('amt',Common.acceptOnlyDecimal(e.target.value))} autoComplete="off"/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmPurDedType">Deduction Type <span className="text-danger">*</span></label>
								<div className="col-8">
									<Typeahead clearButton ref={frmPurDedType} id="frmPurDedType" labelKey="label" key="value" className="floatingTypeahead" onChange={(e) => setDeductionTypeSelect(e)} placeholder="Choose Deduction Type"
										options={deductionList}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.label}
											</Highlighter>
										)}
									/>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-4 col-form-label" htmlFor="frmPurDedRemarks">Deduction Remarks</label>
								<div className="col-8">
									<input type="text" className="form-control" id="frmPurDedRemarks" placeholder="Deduction Remarks" value={deduction_remarks} onChange={(e) => setDeductionRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
								</div>
							</div>
							<div className="text-center mt-3">
								<Button variant="primary" size="sm" className="ms-1" onClick={addDeduction}><span className="tf-icons bx bx-save"></span>&nbsp;Add</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="text-center mt-3">
					<button type="button" role="button" onClick={()=>{setDeductionDiv(!deduction_div);setDeductionAmt('');setDeductionPer('');setDeductionRemarks('');frmPurDedType.current.clear();}} className={"btn btn-sm btn-"+(deduction_div ? 'danger':'success')}>{deduction_div ? <><i className='bx bx-minus'></i></> : <><i className='bx bx-plus'></i></>} Deduction</button>
					{bank_cash_account === 1 && (<>
						<button role="button" className="btn btn-sm btn-primary ms-1" onClick={addPayment}><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
					</>)}
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={dedModalShow} onHide={()=>setDedModalShow(false)} aria-labelledby="deduction-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="deduction-modal">Deductions</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{temp_deduction !== undefined && temp_deduction.length === 0 && <div className="text-center py-1">No deduction</div>}
					{temp_deduction !== undefined && temp_deduction.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<table className="table table-sm table-bordered">
							<thead className="table-light">
								<tr>
									<th>Sl.</th>
									<th align="right">Deduction (%)</th>
									<th align="right">Deduction (<i className='bx bx-rupee' ></i>)</th>
									<th>Type</th>
									<th>Remarks</th>
								</tr>
							</thead>
							<tbody className="table-border-bottom-0">
								{temp_deduction.map((val,index) => (
									<tr key={'ded1'+index}>
										<td>{index+1}</td>
										<td align="right">{Common.displayPercent(val.deduction_per)}%</td>
										<td align="right">{Common.displayPrice(val.deduction_amt)}</td>
										<td>{val.deduction_type}</td>
										<td>{val.deduction_remarks}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
		<Modal size='xl' show={quickItemModalShow} onHide={()=>setquickItemModalShow(false)} aria-labelledby="quick-item-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<Modal.Title id="quick-item-modal">Item</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form id="addItmFrm" onSubmit={handleItem} autoComplete="off">
					<div className="row">
						<div className="col-sm-6">
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="item_name" className="form-label">Item Name <span className="text-danger">*</span></label>
									<input autoFocus type="text" className="form-control" id="item_name" name="item_name" placeholder="E.g., Mobile" value={itemName} onChange={(e) => handleAddiFields(e.target.value)} required/>
									<div className="form-text">
										Required: Minimum 3 characters
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="row">
								<div className="mb-3 col">
									<label className="form-label">Please Select One <span className="text-danger">*</span></label><br></br>
									<div className="form-check form-check-inline">
										<input id="product_type" name="service_type" className="form-check-input" type="radio" value="0"  required checked={itemServiceType === '0'} onChange={(e) => getItemServiceType(e.target.value)} />
										<label className="form-check-label" htmlFor="product_type"> Product </label>
									</div>
									<div className="form-check form-check-inline">
										<input id="service_type" name="service_type" className="form-check-input" type="radio" value="1" required  checked={itemServiceType === '1'} onChange={(e) => getItemServiceType(e.target.value)} />
										<label className="form-check-label" htmlFor="service_type"> Service </label>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6">
						{itemAdditional && itemServiceType !== "" && <>
							<div className="row">
								<div className="mb-3 col">
								{itemServiceType === "0" && <>
									<label htmlFor="item_unit" className="form-label">Item Unit <span className="text-danger">*</span></label>
									<Typeahead clearButton id="item_unit" key="id" className="floatingTypeahead" onChange={(e) => setItemUnit(e[0] ? e[0].id : '')} placeholder="Select Unit"
										options={units}
										labelKey={option => `${option.description} ( ${option.code} )`}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.description +' ( '+ option.code+' )'}
											</Highlighter>
										)}
										defaultSelected={[units.find(obj=>{return obj.code === 'NOS'})]}
									/>
								</>}
								{itemServiceType === "1" && <>
									<label htmlFor="item_unit" className="form-label">Item Unit <span className="text-danger">*</span></label>
									<Typeahead disabled id="item_unit" key="id" className="floatingTypeahead"
										options={units}
										labelKey={option => `${option.description} ( ${option.code} )`}
										renderMenuItemChildren={(option: Item,props) => (
											<Highlighter search={props.text}>
												{option.description +' ( '+ option.code+' )'}
											</Highlighter>
										)}
										defaultSelected={[units.find(obj=>{return obj.code === 'OTH'})]}
									/>
								</>}
								</div>
								<div className="mb-3 col">
									<label htmlFor="item_hsn" className="form-label">HSN Code</label>
									<div className="input-group input-group-merge">
										{itemServiceType === "1" && <>
											<div className="input-group-prepend">
												<div className="input-group-text border-start rounded-start">99</div>
											</div>
										</>}
										<input type="text" className="form-control" id="item_hsn" name="item_hsn" placeholder={(itemServiceType === '1') ? "E.g., 99094112" : "E.g., 99882112"} value={itemHSN} maxLength={(itemServiceType === '1') ? '6' : '8'} onChange={(e) => setItemHSN(e.target.value)} />
									</div>
								</div>
							</div>
							{itemServiceType !== "1" && <>
							<div className="row">
								{/* <div className="mb-3 col">
									<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
									<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
									<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
								</div> */}
								<div className="mb-3 col">
									<label className="pt-4">
										<input className="form-check-input me-1" type="checkbox" value={itemStockMovement} onChange={getItemStockMovement} name='item_stock_movement' checked={itemStockChecked}/> Track Item stock movement
									</label>
								</div>
								{itemStockChecked === true && <>
									<div className="mb-3 col">
										<label className="pt-4">Allow negative balance</label>
										<label className="">
											<input name="default-radio-1" className="form-check-input" type="radio" value={isNegativeBal} id="defaultRadio2" onClick={(e) => {setIsNegativeBal(true); setAllowNegStock(1);} } /> Yes 
											<input name="default-radio-1" className="form-check-input" type="radio" value={isNegativeBal} id="defaultRadio2" style={{'marginLeft': '1rem'}} onClick={(e) => {setIsNegativeBal(false); setAllowNegStock(0);}} defaultChecked /> No
										</label>
									</div>
								</>}
							</div>
							</>}
							{/* {itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
									<div className="input-group input-group-merge">
										<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} disabled = {(itemStockMovement === '1') ? false : true} />
									</div>
								</div>
								<div className="mb-3 col">
									<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
									<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
								</div>
							</div>
							</>} */}
							</>}
						</div>
						<div className="col-sm-6">
						{itemAdditional && itemServiceType !== "" && <>
							<div className="row">
								<div className="mb-3 col-md-6">
									<label className="form-label" htmlFor="gst_rate">GST</label>
									<div className="input-group input-group-merge">
										<span className={"input-group-text"}><i className='bx bxs-offer'></i></span>
										<Typeahead clearButton id="gst_rate" key="id" className="floatingTypeahead border-end rounded-end" onChange={(e) => {quickItemCalculation('gst_rate',(e[0] ? e[0].val : ''));setGstRateId(e[0] ? e[0].id : '');purchaseItemCalculation('gst_rate',(e[0] ? e[0].val : ''));} } placeholder="Select GST"
											options={taxes}
											labelKey="rate"
											renderMenuItemChildren={(option: Item,props) => (
												<Highlighter search={props.text}>
													{option.rate}
												</Highlighter>
											)}
											defaultSelected={taxes.slice(0,1)}
										/>
									</div>
								</div>
								{itemServiceType !== "1" && <>
									<div className="mb-3 col-md-6">
										<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
										<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
										<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
									</div>
								</>}
							</div>
							{itemStockChecked === true && itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
									<div className="input-group input-group-merge">
										<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} disabled = {(itemStockMovement === '1') ? false : true} />
									</div>
								</div>
								<div className="mb-3 col">
									<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
									<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
								</div>
							</div>
							</>}
							{/* {itemStockChecked === true && itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="opening_stock" className="form-label">Opening Stock Qty</label>
									<div className="input-group input-group-merge">
										<input type="number" className="form-control" id="opening_stock" name="opening_stock" placeholder="" value={opening_stock} onChange={(e) => setOpeningStock(e.target.value)} disabled = {(itemStockMovement === '1') ? false : true} />
									</div>
								</div>
								<div className="mb-3 col">
									<label htmlFor="opening_stock_date" className="form-label">Opening Stock Qty as on Date</label>
									<DatePicker name="opening_stock_date" data-testid="opening_stock_date" dayPlaceholder="dd" monthPlaceholder="mm" yearPlaceholder="yyyy" format="dd-MM-y" maxDate={new Date()} className="form-control" onChange={setOpeningStockDt} value={opening_stock_date} disabled = {(itemStockMovement === '1') ? false : true} />
								</div>
							</div>
							</>} */}
							{/* {itemServiceType !== "1" && <>
							<div className="row">
								<div className="mb-3 col">
									<label htmlFor="min_qty" className="form-label">Min Order Qty <span className="text-danger">*</span></label>&nbsp;&nbsp;
									<Common.OverlayTrigger overlay={Common.popoverContent('','The minimum quantity to be ordered')}><i className='bx bxs-info-circle bx-tada'></i></Common.OverlayTrigger>
									<input type="text" className="form-control" id="min_qty" name="min_qty" placeholder="" value={min_qty} onChange={(e) => setMinQty(Common.acceptOnlyDecimal(e.target.value))} required/>
								</div>
								<div className="mb-3 col">
									<label className="pt-4">
										<input className="form-check-input me-1" type="checkbox" value={itemStockMovement} onChange={getItemStockMovement} name='item_stock_movement' checked={itemStockChecked}/> Track Item stock movement
									</label>
								</div>
							</div>
							</>} */}
							</>}
						</div>
						<div className={"row " + (salePurchasedividerDisplay ? 'd-block':'d-none')}>
							<div className="col">
								<div className="divider">
									<div className="divider-text"><i className={'bx bx-chevrons-'+ (salePurchaseAdditionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showSalePurchaseAdditionalItemFields}>{salePurchaseAdditionalItemDiv ? 'Hide':'Show'} Sale & Purchase Price fields</Common.Link><i className={'bx bx-chevrons-'+ (salePurchaseAdditionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
								</div>
							</div>
						</div>
						{salePurchaseAdditionalItemDiv && itemAdditional && <>
						<div className="col-sm-6 border-end">
							<h6 className='text-center mt-3'>Sale</h6>
							<div className="mb-3 col">
								<label htmlFor="item_price" className="form-label">Item Price / Unit <span className="text-danger">*</span></label>
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className="bx bx-rupee"></i></span>
									<input type="text" className="form-control" id="item_price" name="item_price" placeholder="0.00"  value={item_price} onChange={(e) => {quickItemCalculation('item_price',Common.acceptOnlyDecimal(e.target.value));setDiscountAmt('');setDiscountPer('');}}  />
								</div>
							</div>
							<div className="mb-3 col">
								<label className="form-label" htmlFor="discount_per">Discount</label>
								<div className="input-group input-group-merge">
									<span className={"input-group-text "+(disPerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
									<input type="text" className="form-control" id="discount_per" placeholder="0.00" value={discount_per} onChange={(e) => calcDiscount('per',Common.acceptOnlyDecimal(e.target.value))} disabled={disPerDisabled} />
									<span className={"input-group-text "+(disAmtDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
									<input type="text" className="form-control" id="discount_amt" placeholder="0.00" value={discount_amt} onChange={(e) => calcDiscount('amt',Common.acceptOnlyDecimal(e.target.value))} disabled={disAmtDisabled} />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="gross_amt" className="form-label">Gross Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="gross_amt" placeholder="0.00"  min="0" value={gross_amt} readOnly />
								</div>
								<div className="form-text">
									Calculation: Price/Unit - Discount
								</div>
							</div>
							<div className="mb-3 col">
								<label className="form-label" htmlFor="gst_rate">GST Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
									<input type="text" className="form-control" name="gst_amt" placeholder="0.00" value={gst_amt} readOnly />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="item_total" className="form-label">Item Total</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="item_total" name="total_price" placeholder="0.00"  min="0" value={item_total} readOnly/>
								</div>
								<div className="form-text">
									Calculation: Gross Amount + GST Amount
								</div>
							</div>
						</div>
						</>}
						{salePurchaseAdditionalItemDiv && itemAdditional && <>
						<div className="col-sm-6">
							<h6 className='text-center mt-3'>Purchase</h6>
							<div className="mb-3 col">
								<label htmlFor="purchase_item_price" className="form-label">Item Price / Unit <span className="text-danger">*</span></label>
								<div className="input-group input-group-merge">
									<span className="input-group-text"><i className="bx bx-rupee"></i></span>
									<input type="text" className="form-control" id="purchase_item_price" name="purchase_item_price" placeholder="0.00"  min="0" value={purchase_item_price} onChange={(e) => {purchaseItemCalculation('item_price',Common.acceptOnlyDecimal(e.target.value));setPurchaseDiscountAmt('');setPurchaseDiscountPer('');}}  />
								</div>
							</div>
							<div className="mb-3 col">
								<label className="form-label" htmlFor="purchase_discount_per">Discount</label>
								<div className="input-group input-group-merge">
									<span className={"input-group-text "+(disPurchasePerDisabled ? "disabled-bg" : "")}><i className='bx bxs-offer'></i></span>
									<input type="text" className="form-control" id="purchase_discount_per" placeholder="0.00" value={purchase_discount_per} onChange={(e) => calcPurchaseDiscount('per',Common.acceptOnlyDecimal(e.target.value))} disabled={disPurchasePerDisabled} />
									<span className={"input-group-text "+(disPurchaseAmtDisabled ? "disabled-bg" : "")}><i className='bx bx-rupee'></i></span>
									<input type="text" className="form-control" id="purchase_discount_amt" placeholder="0.00" value={purchase_discount_amt} onChange={(e) => calcPurchaseDiscount('amt',Common.acceptOnlyDecimal(e.target.value))} disabled={disPurchaseAmtDisabled} />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="purchase_gross_amt" className="form-label">Gross Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="purchase_gross_amt" placeholder="0.00"  min="0" value={purchase_gross_amt} readOnly />
								</div>
								<div className="form-text">
									Calculation: Price/Unit - Discount
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="purchase_gst_amt" className="form-label">GST Amount</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="text" className="form-control" id="purchase_gst_amt" name="purchase_gst_amt" placeholder="0.00"  min="0" value={purchase_gst_amt} readOnly />
								</div>
							</div>
							<div className="mb-3 col">
								<label htmlFor="purchase_item_total" className="form-label">Item Total</label>
								<div className="input-group input-group-merge">
									<span className="input-group-text disabled-bg"><i className="bx bx-rupee"></i></span>
									<input type="number" className="form-control" id="purchase_item_total" name="purchase_item_total" placeholder="0.00"  min="0" value={purchase_item_total} readOnly/>
								</div>
								<div className="form-text">
									Calculation: Gross Amount + GST Amount
								</div>
							</div>
						</div>
						</>}
					</div>
					<div className={"row " + (dividerDisplay ? 'd-block':'d-none')}>
						<div className="col">
							<div className="divider">
								<div className="divider-text"><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i><Common.Link to="#" onClick={showAdditionalItemFields}>{additionalItemDiv ? 'Hide':'Show'} additional fields</Common.Link><i className={'bx bx-chevrons-'+ (additionalItemDiv ? "up":"down")+' bx-fade-up'}></i></div>
							</div>
						</div>
					</div>
					{additionalItemDiv && 
					<>
						<div className='row'>
							<div className="col-md-12" id="collection_tab">
								<div className="nav-align-top mb-4">
									<ul className="nav nav-tabs nav-fill" role="tablist">
										<li className="nav-item">
											<button type="button" className="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="true">
												Item Details
											</button>
										</li>
										<li className="nav-item">
											<button type="button" className="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-profile" aria-controls="navs-justified-profile" aria-selected="false" onClick={toggeCustomInputInvFields}>
												Custom Fields
											</button>
										</li>
									</ul>
									<div className="tab-content">
										<div className="tab-pane fade show active" id="navs-justified-home" role="tabpanel">
											<div className='row'>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="sku_code" className="form-label">SKU Code </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="sku_code" name="sku_code" value={skuCode} onChange={(e)=> {setSkuCode(e.target.value);}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="reorder_qty" className="form-label">Reorder Quantity </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control"  id="reorder_qty" name="reorder_qty" placeholder="0.000" step=".001" min="0" value={reorderQty} onChange={(e)=> {setReorderQtyValue(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="safety_stock" className="form-label">Safety Stock </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="safety_stock" name="safety_stock" placeholder="0.000" step=".001" min="0" value={safetyStock} onChange={(e)=> {setCustomItemQty(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="reorder_point" className="form-label">Reorder Point </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="reorder_point" name="reorder_point" placeholder="0.000" step=".001" min="0" value={reorderPoint} onChange={(e)=> {setReorderPointValue(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="barcode" className="form-label">Barcode </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="barcode" name="barcode" value={barcode} onChange={(e)=> {setBarcode(e.target.value);}} />
															<span className="input-group-text py-0 pe-1">
																<Common.OverlayTrigger placement={'bottom'} overlay={Common.tooltipContent('Scan Barcode')}>
																	<Button onClick={()=>setItemBarcode( (itemBarcode==='barcode') ? 'normal':'barcode')} variant="outer-primary" className={"p-0 "+(itemBarcode==='barcode' ? "text-success" : "") }><i className={"bx bx-md bx-barcode-reader"+(itemBarcode==='barcode' ? " bx-burst":"")}></i></Button>
																</Common.OverlayTrigger>
															</span>
														</div>
														<div className={"row pb-2 text-center "+ (itemBarcode === 'normal' ? "d-none":"")}>
															<div className={"mx-auto "+(itemBarcode === 'normal' ? "d-none":"")} style={{'width':'320px','height':'auto'}}>
																<h5>Scan Barcode</h5>
																<div id="barCartReader" className="d-block"></div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-sm-4">
													<div className="mb-3 col">
														<label htmlFor="max_stock_level" className="form-label">Maximum Stock Level </label>
														<div className="input-group input-group-merge">
															<input type="text" className="form-control" id="max_stock_level" name="max_stock_level" placeholder="0.000" step=".001" min="0" value={maximumStockLevel} onChange={(e)=> {setMaxStockLevelValue(Common.acceptOnlyDecimal(e.target.value));}} />
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="tab-pane fade" id="navs-justified-profile" role="tabpanel">
											<div className='row'>
												<div className='col-md-1'></div>
												<div className='col-md-10'>
													{/* <div className="card accordion-item"> */}
														<div id="customInvFields" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#salesAccordion">
															{customItemInputList}
															<div className="mb-3 text-center">
																<button type="button" className="btn btn-sm btn-primary me-2" onClick={handleCustomItemInvFields}><i className='bx bx-plus'></i> Add Fields</button>
															</div>
														</div>
													{/* </div> */}
												</div>
												<div className='col-md-1'></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</>}
					<div className="row ">
						<div className="col-sm-10">
							<button type='submit' className="btn btn-sm btn-primary" ><span className="tf-icons bx bx-save"></span>&nbsp;Save</button>
							&nbsp;&nbsp;
							<button type='submit' onClick={resetForm} className="btn btn-sm btn-secondary" ><span className="tf-icons bx bx-reset"></span>&nbsp;Reset</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
		<Modal show={custListModalShow} size="lg" onHide={()=>{setCustListModalShow(false);setFilterText('')}} aria-labelledby="customer-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className="py-0">
				<div className="row">
					<div className="text-end">
					<Common.OverlayTrigger overlay={Common.popoverContent('Search By:','Party Name, Mobile, Gstin, Email')}><i className='bx bxs-info-circle bx-tada me-1'></i></Common.OverlayTrigger>
					<DTE.FilterComponent placeholder="Search" onFilter={e => {setFilterText(e.target.value);loadCustTableModal(1,e.target.value);}} onClear={e=>{setFilterText('');loadCustTableModal(1,'');}} filterText={filterText} />
					</div>
				</div>
				<div className="row">
					{customers_list_modal !== undefined && customers_list_modal.length === 0 && <div className="text-center py-1">No Seller</div>}
					{customers_list_modal !== undefined && customers_list_modal.length > 0 && <>
					<div className="table-responsive text-nowrap">
						<DTE.DataTable className="rdt_AdjDrp" title={"Seller ("+totalDataCnt+")"} columns={columns} data={customers_list_modal} progressPending={DTpending} pagination paginationServer paginationTotalRows={totalRows} paginationComponentOptions={paginationComponentOptions} onChangePage={handlePageChange} onSort={handleSort} sortServer/>
					</div>
					</>}
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={paymentCheckShow} size='sm' onHide={()=>setPaymentCheckShow(false)} aria-labelledby="Payment-check-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Payment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p className="mb-0"><b>Invoice having Payable or advance adjusted details cannot be saved as draft. Pl delete Payable info and retry</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setPaymentCheckShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal size='md' show={advanceAdjustModalShow} onHide={()=>advanceModal('hide')} aria-labelledby="advance-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-4 modal-mobile-view">
					<Modal.Title id="advance-modal">Advance adjust</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<label className="col-5 col-form-label" htmlFor="frmAdvanceBalance">Total Advance Balance</label>
					<div className="col-7">
						<div className="input-group input-group-merge">
							<span className="input-group-text disabled-bg"><i className='bx bx-rupee'></i></span>
							<input type="text" readOnly className="form-control" id="frmAdvanceBalance" placeholder="0.00" value={advanceBalance}
							autoComplete="off"/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<label className="col-5 col-form-label" htmlFor="frmAdjustedAmount">Adjusted amount <span className="text-danger">*</span></label>
					<div className="col-7">
						<div className="input-group input-group-merge">
							<span className="input-group-text"><i className='bx bx-rupee'></i></span>
							<input type="text" className="form-control" id="frmAdjustedAmount" placeholder="0.00" value={adjustedAmount}
								onChange={(e) => {
									setAdjustedAmount(Common.acceptOnlyDecimal(e.target.value));
								}}
							autoComplete="off"/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<label className="col-5 col-form-label" htmlFor="frmSalePayRemarks">Advance Adjusted Remarks</label>
					<div className="col-7">
						<input type="text" className="form-control" id="frmSalePayRemarks" placeholder="Remarks" value={advanceAdjustedRemarks} onChange={(e) => setAdvanceAdjustedRemarks(Common.acceptOnlyCharsNumbers(e.target.value))} autoComplete="off"/>
					</div>
				</div>
				<div className="text-center mt-3">
					<Button variant="primary" size="sm" className="ms-1" onClick={addAdvanceAdjust}><span className="tf-icons bx bx-save"></span>&nbsp;Save</Button>
				</div>
			</Modal.Body>
		</Modal>
		<Modal show={draftInvoiceMsgShow} size='sm' onHide={()=>setDraftInvoiceMsgShow(false)} aria-labelledby="Convert-Invoice_Msg-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
			<Modal.Title>Save Draft</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-sm-12">
						<p><b>This draft has already been saved earlier with Invoice number. Hence, saving this draft without invoice number now is not allowed.</b></p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="danger" onClick={()=>setDraftInvoiceMsgShow(false)}>
				Close
			</Button>
			</Modal.Footer>
		</Modal>
		<Modal show={commonModel} size='sm' aria-labelledby="Common-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header>
			<Modal.Title>{commonModelHeading}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{commonModelBody}
			</Modal.Body>
			<Modal.Footer>
				{commonModelFooter}
			</Modal.Footer>
		</Modal>
		<Modal size='md' show={itemCustomDataModel} onHide={()=>setItemCustomDataModel(false)} aria-labelledby="item-custom-modal" backdrop="static" keyboard={false} centered>
			<Modal.Header closeButton>
				<div className="col-6 modal-mobile-view">
					<Modal.Title id="advance-modal">Item Custom Fields</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
			{itemCustomData[customItemFieldIndex] && <>
			{itemCustomData[customItemFieldIndex].map((vals,inds) => (
				<div className="row" key={"iak_"+inds} id={"ia_"+inds}>
					<div className="mb-3 col-md-5">
						<div className="input-group input-group-merge">
							<input type="text" className="form-control custom_fields mt-2" id={`custom_item_field_name_${inds}`} name={`custom_item_field_name_${inds}`} value={vals.item_field_name} readOnly/>
						</div>
					</div>
					<div className="mb-3 col-md-5">
						<div className="input-group input-group-merge">
							<input type="text" className="form-control mt-2" id={`custom_item_field_value_${inds}`} name={`custom_item_field_value_${inds}`} value={vals.item_field_value}
							onChange={(e) => handleCustomInputFeild(e.target.value,vals.count,inds)} placeholder='Custom Field Value' />
						</div>
					</div>
				</div>
			))}
			</>}
				<div className="text-center mt-3">
					<Button variant="primary" size="sm" className="ms-1" onClick={()=>handleCustomData(customItemFieldIndex)}><span className="tf-icons bx bx-save"></span>&nbsp;Add</Button>
				</div>
			</Modal.Body>
		</Modal>
    </>
    );
}