import React  from "react";
import Layout from './Layout';
import NavBar from './NavBar';
import * as Common from '../common/ImportUserEssentials';
import Button from 'react-bootstrap/Button';
import {Html5Qrcode} from "html5-qrcode";
import beepSound from '../sounds/scanner-beep.mp3';
import '../styles/mart-user-home.css';
export default function Home(props){
	//const navigate = Common.useNavigate();
	const [stores,setStores] = React.useState([]);
	const [qrShow,setQRShow] = React.useState(false);
    React.useEffect(() => {
        document.title = 'Home | '+props.site_name;
		Common.axios({
			method: 'post',
			url:props.url+'martuser/stores',
			data: {'id':Common.AES.encrypt(Common.secureLocalStorage.getItem('fm_loggedin').toString(), props.site_key).toString()}
		}).then(function (response) {
			Common.showLoader(false);
			if(response.data.success){
				setStores(response.data.data);
				return false;
			}
			Common.toast.error(response.data.message);
		}).catch(error => console.log(error));	
	}, [props]);
	
	let audio = new Audio(beepSound);
	const playBeep = () => {
		if(audio.paused === true){
		  audio.play();
		}else{
		  audio.pause();
		  audio.currentTime = 0;
		  audio.play();
		}
	};
	React.useEffect(() => {
		let qrboxFunction = function(viewfinderWidth, viewfinderHeight) {
			let minEdgePercentage = 0.9; // 70%
			let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
			let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
			return {
				width: qrboxSize,
				height: qrboxSize
			};
		}
		const html5QrCode = new Html5Qrcode("barReader");
		const startScanner = async () => {
			await html5QrCode.start(
				{ facingMode: 'environment' },
				{
					fps: 10,
					//qrbox: { width: 200, height: 200 }
					qrbox: qrboxFunction
				},
				(decodedText, decodedResult) => {
					if(decodedText !== ''){
						playBeep();
						stopScanner();
						setQRShow(false);
						addStore(decodedText);
					}
				},
				(errorMessage) => {
					// parse error, ignore it.
				}
			);
		};
		const addStore = (qrcode) => {
			if( qrcode.startsWith(props.link+'approve/') ){
				Common.toast.success('Valid merchant QR code.');
				window.location.href = qrcode;
				return false;
			}
			Common.toast.error('Invalid merchant QR code. Please try again.');
		}
		const stopScanner = async () => {
			if (html5QrCode.isScanning) {
				await html5QrCode.stop();
				html5QrCode.clear();
			}
		};
		if( qrShow ){
			startScanner();
		}
		return () => {
			stopScanner();
		};
	}, [qrShow]);
	const htmlItem = (store,index) => {
		return (
		<div key={"StoreDiv"+index} className={"col-6 col-md-3 col-xl-2 card-group "+(store.pstatus === "0" ? "storeDisabled":"")}>
			<Common.Link to={store.pstatus === "1" ? "/store/"+store.uid : ""} className="card mb-4">
				<div className="card-body text-center p-0">
				{store.logo !== null ? 
				<img height="auto" width="70%" className="rounded-circle p-1" src={props.url+"public/assets/img/profile_logo/"+store.logo} alt={store.name+" Logo"}/>
				:
				<img height="auto" width="70%" className="p-1" src={props.link+"/assets/img/default_store.png"} alt={store.name+" Logo"}/>
				}
				</div>
				<div className="card-footer px-2 py-2">
					<div className="card-text">
						<div className="d-inline-block text-dark" style={{"verticalAlign":"middle","fontSize":"0.8rem"}}>{store.name}</div>
						<div className="storeDesc"><small>{store.parent +' - '+store.sub}</small></div>
					</div>
				</div>
			</Common.Link>
		</div>
		)
	}
    return (
	<>
        <div id="mu_home" className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
				<Layout site_logo={props.site_logo} label="home" site={props.site_name}/>
                <div className="layout-page">
                    <NavBar {...props}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-3">All Stores <span className="float-end"><Button variant={qrShow ? "danger":"success"} size="sm" onClick={()=>setQRShow(!qrShow)}><i className='bx bx-qr-scan'></i> {qrShow ? 'Hide':'Scan'} Merchant QR</Button></span></h4>
							<div className="row">
								<div className={qrShow ? "mx-auto col-sm-6" : "d-none"} style={{'maxWidth':'90%','height':'auto'}}>
									<div id="barReader" className="d-block"></div>
								</div>
								<div className={qrShow ? 'd-none':'row'}>
									{stores.length > 0 && stores.map((store,index) => {
										return (
											store.pstatus === "0" ? 
											<Common.OverlayTrigger key={"co"+index} overlay={Common.popoverContent('Offline','Store isn\'t online. Contact store directly.')}>{htmlItem(store,index)}</Common.OverlayTrigger>
											:
											htmlItem(store,index)
										)
									})}
								</div>
							</div>
						</div>
                    </div>
                    {/* <Common.FYNTicket url={props.url}/> */}
                </div>
            </div>
        </div>
	</>
    );
}